<template>

<div v-if="report_type_is_power_20" class="card border-0 shadow-md mb-4">
  <div class="card-body">
    <div class="row mx-auto text-center justify-content-center ">
        <h4 class="lh-2 fs-1 fw-bold">Recommended Next Steps </h4>
             
    </div>
    <div class="row mx-6 mt-3">
      <p class="fs-2 fw-extrabold edgility-blue">Understand and share results</p>
      <ul>
        <li class="ms-5 ps-2 fs-5">Share survey results with leadership and staff</li>
        <li class="ms-5 ps-2 fs-5">Request reactions from stakeholders. What is surprising? What remains unclear?</li>
        <li class="ms-5 ps-2 fs-5">Preview next steps</li>
      </ul>

      <p class="fs-2 fw-extrabold edgility-blue">Create an action plan</p>
      <ul>
        <li class="ms-5 ps-2 fs-5">Engage a cross section of staff in a task force to analyze results and weigh in on next steps</li>
        <li class="ms-5 ps-2 fs-5">Facilitate several work sessions to prioritize areas of focus</li>
        <li class="ms-5 ps-2 fs-5">Identify short and long-term goals</li>
        <li class="ms-5 ps-2 fs-5">Map out activities to reach them
        </li>
      </ul>

      <p class="fs-2 fw-extrabold edgility-blue">Measure progress</p>
      <ul>
        <li class="ms-5 ps-2 fs-5">Conduct pulse checks to assess the effectiveness of your plan</li>
        <li class="ms-5 ps-2 fs-5">Change course as needed</li>
        <li class="ms-5 ps-2 fs-5">Repeat the survey annually to ensure your organization is staying on track</li>
      </ul>
    </div>
  </div>
</div>

<div v-else class="card border-0 shadow-md mb-4">
  <div class="card-body">
    <div class="row mx-auto text-center justify-content-center ">
        <h4 class="lh-2 fs-1 fw-bold">Recommended Next Steps </h4>
             
    </div>
    <div class="row mx-6 mt-3">
      <p class="fs-2 fw-extrabold edgility-blue">Understand and share results</p>
      <ul>
        <li class="ms-5 ps-2 fs-5">Share survey results with leadership and staff</li>
        <li class="ms-5 ps-2 fs-5">Request reactions from stakeholders. What is surprising? What remains unclear?</li>
        <li class="ms-5 ps-2 fs-5">Preview next steps</li>
      </ul>

      <p class="fs-2 fw-extrabold edgility-blue">Create an action plan</p>
      <ul>
        <li class="ms-5 ps-2 fs-5">Engage a cross section of staff in a task force to analyze results and weigh in on next steps</li>
        <li class="ms-5 ps-2 fs-5">Facilitate several work sessions to prioritize areas of focus</li>
        <li class="ms-5 ps-2 fs-5">Identify short and long-term goals</li>
        <li class="ms-5 ps-2 fs-5">Map out activities to reach them
        </li>
      </ul>

      <p class="fs-2 fw-extrabold edgility-blue">Measure progress</p>
      <ul>
        <li class="ms-5 ps-2 fs-5">Conduct pulse checks to assess the effectiveness of your plan</li>
        <li class="ms-5 ps-2 fs-5">Change course as needed</li>
        <li class="ms-5 ps-2 fs-5">Repeat the survey annually to ensure your organization is staying on track</li>
      </ul>
    </div>
  </div>
</div>

<div class="card border-0 shadow-md mb-4 logo-edg-bg">
  <div class="card-body">
    <div class="row mx-8 my-6">
      <h1 class="fw-extrabold edgility-black mb-4">Put your values to work. <span class="edgility-blue">Act on Equity.</span></h1>
      <div class="row flex-grow-1 pe-md-12 ">
        <h3 class="fs-3 lh-lg fw-normal"><span class="fw-black edgility-red ">Equity is not a buzzword for us.</span> Edgility Consulting works
          to create meaningful, practical system and policy change so
          that leaders aren't relying on inherent bias to make decisions.
          We know those decisions impact people of color and women
          disproportionately, so it's imperative that we keep
          marginalized groups top of mind in every aspect of our
          design.</h3>

      </div>
      
    </div>
    </div>
</div>


</template>

<script>
    export default{
        name: "NextStep",
        // props: ['report_type_is_power_20']
        data(){
          return{
            report_type_is_power_20: this.$store.state.reportData.report_type_is_power_20
          }
        }
    }
</script>

