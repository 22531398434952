<template>
  <nav class="navbar navbar-dark px-4 col-12 d-lg-none">
    <a class="navbar-brand me-lg-5" >
      <img
        class="navbar-brand-dark"
        src="../assets/img/logo-kite.svg"
        alt="Kite logo"
      />
      <img
        class="navbar-brand-light"
        src="../assets/img/logo-kite.svg"
        alt="Kite logo"
      />
    </a>
    <div class="d-flex align-items-center">
      <button
        class="navbar-toggler d-lg-none collapsed bg-warning"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>

  <nav
    id="sidebarMenu"
    class="sidebar d-lg-block bg-gray-800 text-black collapse"
    data-simplebar
  >
    <div class="sidebar-inner px-4 pt-3">
      <div
        class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4"
      >
        <div class="d-flex align-items-center">
        </div>
        <div class="collapse-close d-md-none">
          <a
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <svg
              class="icon icon-xs"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <ul class="nav flex-column pt-3 pt-md-0">
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <img
                :src="`../assets/img/logo/${tenant}/logo.svg`"
                height="20"
                width="20"
                v-bind:alt="tenantTitle"
              />
            </span>
            <span class="mt-1 ms-1 sidebar-text" style="font-size: large;font-weight: bold ">{{ tenantTitle }}</span>
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m19.6,7.44c-.96-.81-1.94-1.62-2.91-2.42-.09-.08-.13-.16-.13-.28,0-.66,0-1.33,0-1.99,0-.35-.28-.62-.62-.62-.34,0-.61.27-.62.62,0,.31,0,.62,0,.94,0,.05-.01.09-.02.16-.07-.06-.12-.1-.17-.14-1.27-1.05-2.53-2.11-3.8-3.16-.87-.72-1.79-.72-2.65,0-2.32,1.93-4.64,3.87-6.96,5.8-.49.41-.98.81-1.47,1.23-.29.25-.33.63-.1.9.23.27.6.29.89.06.06-.04.11-.09.17-.14C3.97,6.09,6.74,3.78,9.5,1.48c.36-.3.63-.31.99,0,2.29,1.91,4.58,3.82,6.87,5.72.1.08.14.17.14.3,0,3.49,0,6.98,0,10.47,0,.49-.24.73-.73.73h-2.81c-.06,0-.13,0-.2,0v-6.77c0-1.17-.8-1.97-1.97-1.97h-3.54c-1.2,0-1.99.79-1.99,2v6.75h-3c-.52,0-.75-.23-.75-.75v-7.31c0-.41-.26-.7-.63-.69-.37,0-.62.28-.62.7,0,2.46,0,4.91,0,7.36,0,1.12.81,1.93,1.93,1.93,4.54,0,9.09,0,13.63,0,.18,0,.37-.02.54-.07.85-.23,1.39-.95,1.39-1.87v-9.63c.11.08.18.14.26.2.25.18.57.17.79-.04.08-.08.14-.2.21-.3v-.31c-.13-.16-.24-.33-.4-.47Zm-7.1,11.24h-5v-6.74c0-.52.23-.75.75-.75h3.53c.48,0,.72.24.72.72v6.77h0Z"
                />
                <path
                  class="cls-1"
                  d="m10,14.93c0,.35-.29.63-.63.63-.34,0-.62-.29-.62-.63,0-.34.28-.62.62-.62s.62.28.62.62h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Home</span>
          </a>
        </li> -->
        <li :class="'nav-item' + (active==='campaign' ? ' active' : '')" @click="$emit('sidebar-click', 'campaign')">
          <a
            target="_blank"
            class="nav-link d-flex justify-content-between"
          >
            <span>
              <span class="sidebar-icon">
                <svg
                  class="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    class="cls-1"
                    d="m17.87,16.81c-.24.37-.67.46-1,.24-.33-.23-.4-.64-.15-1.02.56-.86.62-1.76.19-2.69-.37-.8-.74-1.61-1.12-2.41-.79-1.69-2.59-2.33-4.28-1.54-.37.17-.75.35-1.15.44-.71.15-1.24-.16-1.56-.82-.42-.89-.83-1.79-1.25-2.68-.18-.39-.54-.56-.91-.44-.44.14-.64.61-.43,1.06.54,1.16,1.08,2.32,1.62,3.47.45.96.89,1.92,1.34,2.88.1.21.14.41.06.63-.14.38-.55.55-.96.38-.68-.27-1.35-.57-2.03-.84-.56-.22-1.13.11-1.2.69-.05.4.18.74.6.9,1.08.41,2.16.81,3.24,1.22.37.14.74.29,1.1.46.55.25.98.65,1.28,1.18.2.35.38.7.55,1.06.18.38.03.79-.32.96-.35.16-.74.01-.93-.36-.14-.27-.27-.55-.41-.82-.23-.45-.61-.74-1.07-.91-1.31-.49-2.62-.99-3.93-1.48-.77-.29-1.28-.82-1.46-1.63-.19-.84.05-1.58.69-2.15.64-.57,1.39-.72,2.21-.47.18.06.35.13.58.22-.35-.76-.68-1.46-1-2.16-.42-.89-.84-1.78-1.25-2.68-.59-1.32.16-2.75,1.58-3.01.9-.17,1.83.32,2.26,1.2.42.86.82,1.73,1.21,2.6.08.19.17.2.35.13.54-.22,1.08-.49,1.64-.63,1.98-.47,4.03.51,4.95,2.34.47.94.93,1.89,1.33,2.86.56,1.34.4,2.63-.39,3.84h0Z"
                  />
                  <path
                    class="cls-1"
                    d="m3.37,8.43c-.62.31-1.25.6-1.89.88-.31.13-.67-.04-.82-.35-.16-.31-.08-.72.23-.88.63-.32,1.28-.61,1.92-.91.06-.03.13-.02.16-.03.35,0,.61.19.7.49.09.3-.02.65-.3.79Z"
                  />
                  <path
                    class="cls-1"
                    d="m9.51.83c-.21.67-.45,1.33-.71,1.98-.13.33-.54.46-.87.33-.32-.13-.52-.5-.41-.84.22-.64.45-1.27.69-1.9C8.31.14,8.55.01,8.78,0c.5,0,.86.4.73.83Z"
                  />
                  <path
                    class="cls-1"
                    d="m3.14,5.38c-.13.34-.49.54-.85.42-.63-.21-1.25-.44-1.88-.68C.14,5.02.02,4.79,0,4.56c0-.53.44-.88.9-.73.62.21,1.24.44,1.86.67.36.14.52.53.39.88h0Z"
                  />
                  <path
                    class="cls-1"
                    d="m12.34,3.89c0,.2-.12.42-.37.54-.59.28-1.19.57-1.79.83-.35.15-.74-.02-.89-.36s-.02-.74.32-.91c.58-.28,1.16-.55,1.75-.82.48-.21.98.12.98.7h0Z"
                  />
                  <path
                    class="cls-1"
                    d="m5.34,3.84c-.34.16-.75.02-.92-.34-.28-.57-.55-1.15-.8-1.73-.21-.48.11-.98.66-.99.25,0,.47.13.59.39.28.58.55,1.16.82,1.75.16.36,0,.76-.34.92Z"
                  />
                </svg>
              </span>
              <span class="sidebar-text">Campaigns </span>
            </span>
          </a>
        </li>
        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='breakdown' ? ' active' : '')" @click="$emit('sidebar-click', 'breakdown')">
          <a class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m20,2.47v7.38c0,.13,0,.26-.04.38-.1.36-.45.58-.81.54-.36-.04-.65-.34-.68-.7,0-.08,0-.16,0-.24V2.47c0-.65-.28-.93-.94-.93H2.47c-.66,0-.93.28-.93.93v15.07c0,.63.29.92.93.92h7.4c.12,0,.24,0,.36.04.35.1.58.45.54.81-.04.36-.33.65-.7.68-.08,0-.16,0-.24,0H2.47c-1.49,0-2.47-.97-2.47-2.46V2.47C0,.97.97,0,2.46,0h15.07c1.49,0,2.46.97,2.46,2.47h0Z"
                />
                <path
                  class="cls-1"
                  d="m19.67,17.57c-.66-.53-1.32-1.06-1.99-1.59-.11-.09-.15-.15-.08-.29.28-.57.37-1.19.34-1.82-.1-2.24-2.28-3.97-4.48-3.56-2.05.39-3.36,2.13-3.17,4.21.16,1.82,1.75,3.33,3.57,3.42,1.01.05,1.91-.24,2.71-.88.07.05.13.1.19.15.66.52,1.31,1.05,1.97,1.57.47.37,1.12.16,1.25-.41.08-.33-.05-.6-.31-.8Zm-3.4-2.68c-.1.28-.26.54-.45.75-.03.03-.05.05-.08.08-.01.01-.03.03-.04.04-.03.03-.05.05-.08.08-.4.35-.92.56-1.5.56-.24,0-.48-.04-.69-.1-.07-.02-.14-.05-.21-.08-.28-.12-.52-.28-.73-.49-.03-.03-.05-.05-.08-.08-.12-.13-.23-.28-.32-.45h0c-.04-.07-.07-.13-.1-.2-.12-.28-.18-.58-.19-.89,0-.4.1-.77.28-1.1.07-.13.16-.25.25-.37.05-.06.1-.11.15-.16.42-.42.99-.68,1.62-.68.68,0,1.29.29,1.71.76.05.05.1.11.14.17.07.09.13.18.18.28.09.16.16.34.21.52,0,.04.02.07.03.11.03.15.05.31.05.46,0,.28-.05.55-.14.8h0Z"
                />
                <path
                  class="cls-1"
                  d="m15.34,6.63c-.11.03-.24.04-.36.04H5.01c-.49,0-.8-.21-.89-.6-.11-.46.21-.9.69-.93.09,0,.18,0,.26,0h4.95c1.67,0,3.35,0,5.02,0,.34,0,.61.12.77.43.22.42-.01.95-.48,1.07h0Z"
                />
                <path
                  class="cls-1"
                  d="m9.74,9.99c0,.44-.35.77-.83.77h-3.99c-.48,0-.83-.34-.83-.78,0-.44.35-.76.82-.76.67,0,1.34,0,2.02,0h1.97c.49,0,.84.32.84.76h0Z"
                />
                <path
                  class="cls-1"
                  d="m12.32,12.64c-.09.12-.18.24-.25.37.07-.13.16-.25.25-.37Z"
                />
                <path
                  class="cls-1"
                  d="m12.08,15.2c.09.16.2.31.32.45-.12-.13-.23-.28-.32-.45Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Demographic Breakdown</span>
          </a>
        </li>
        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='nps' ? ' active' : '')" @click="$emit('sidebar-click', 'nps')">
          <a class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m19.21,0c-2.63.01-5.26,0-7.88,0-.48,0-.7.22-.7.7v7.94c0,.51.21.73.72.73,2.62,0,5.24,0,7.86,0,.36,0,.63-.1.79-.43V.44c-.16-.34-.42-.44-.79-.44Zm-.4,8.2h-7V1.19h7v7.01Z"
                />
                <path
                  class="cls-1"
                  d="m19.21,10.62c-2.62.01-5.24,0-7.86,0-.51,0-.72.21-.72.72,0,2.62,0,5.24,0,7.86,0,.36.1.63.43.79h8.51c.19-.09.34-.24.43-.43v-8.51c-.16-.34-.42-.44-.79-.44h0Zm-.39,8.2h-7v-7.01h7v7.01Z"
                />
                <path
                  class="cls-1"
                  d="m9.33,14.63c-.41-2.62-2.83-4.36-5.45-3.92C1.78,11.05.19,12.82.01,15c-.17,2.05,1.16,4.07,3.12,4.73.37.12.75.18,1.13.28h.86s.09-.03.13-.03c2.6-.3,4.48-2.76,4.08-5.34Zm-4.65,4.2c-1.93,0-3.51-1.59-3.5-3.52,0-1.93,1.59-3.51,3.52-3.5,1.93,0,3.51,1.59,3.5,3.52,0,1.93-1.59,3.51-3.52,3.5h0Z"
                />
                <path
                  class="cls-1"
                  d="m19.57,20c.19-.09.34-.24.43-.43v.43h-.43Z"
                />
                <path
                  class="cls-1"
                  d="m4.7,0C2.12,0,.01,2.1,0,4.67c0,2.59,2.1,4.7,4.68,4.7,2.58,0,4.68-2.1,4.69-4.68C9.38,2.12,7.28.01,4.7,0Zm-.01,8.2c-1.94,0-3.51-1.58-3.51-3.52s1.58-3.51,3.51-3.51,3.51,1.58,3.51,3.51-1.58,3.51-3.51,3.51Z"
                />
                <path
                  class="cls-1"
                  d="m17.74,3.77c-.89.89-1.77,1.78-2.66,2.66-.3.3-.63.3-.93,0-.43-.43-.86-.85-1.28-1.28-.27-.27-.29-.62-.05-.87.24-.25.6-.24.88.04.3.3.61.61.94.94.08-.1.15-.18.23-.26.68-.69,1.37-1.37,2.06-2.06.27-.27.63-.29.87-.05.25.24.24.6-.04.88h0Z"
                />
                <path
                  class="cls-1"
                  d="m6.68,17.33c-.22.24-.58.25-.85.01-.71-.62-1.55-.62-2.27,0-.28.24-.63.24-.86,0-.24-.25-.21-.6.07-.87.5-.49,1.21-.77,2.07-.76.6.01,1.24.26,1.78.77.27.25.29.6.06.85h0Z"
                />
                <path
                  class="cls-1"
                  d="m3.81,13.94c0,.32-.25.59-.57.59-.32,0-.59-.26-.59-.58,0-.32.25-.59.57-.59.32,0,.59.25.59.58Z"
                />
                <path
                  class="cls-1"
                  d="m6.74,13.94c0,.32-.25.59-.57.59-.32,0-.59-.26-.59-.58,0-.32.25-.59.57-.59.32,0,.58.25.59.58h0Z"
                />
                <path
                  class="cls-1"
                  d="m6.62,6.39c-.54.51-1.18.76-1.92.77-.74,0-1.38-.26-1.92-.77-.28-.26-.3-.62-.07-.87.23-.24.58-.24.86,0,.7.62,1.56.62,2.27,0,.27-.24.63-.23.86.01.23.25.2.6-.07.85h0Z"
                />
                <path
                  class="cls-1"
                  d="m3.81,3.52c0,.32-.26.59-.58.59-.32,0-.58-.26-.59-.58s.26-.59.58-.59.58.26.59.58Z"
                />
                <path
                  class="cls-1"
                  d="m6.74,3.52c0,.32-.27.58-.58.58s-.58-.27-.58-.59c0-.32.27-.58.59-.58.32,0,.58.27.58.59h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Net Promoter Score</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='srf' ? ' active' : '')" @click="$emit('sidebar-click', 'srf')">
          <a class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m19.98,17.33c-.07.31-.27.52-.6.59-.12.02-.24.03-.36.03h-8.41c-2.83,0-5.66,0-8.48,0-1.17,0-2.13-.95-2.13-2.12C0,10.85,0,5.87,0,.89,0,.42.21.12.58.03c.47-.12.92.2.96.68,0,.09,0,.18,0,.27v14.48c0,.83.11.95.94.95h16.58c.23,0,.47,0,.65.18.23.2.33.45.26.75Z"
                />
                <path
                  class="cls-1"
                  d="m18.98,6.87c0,.39-.22.69-.55.78-.32.09-.67-.02-.84-.31-.08-.14-.12-.31-.13-.47-.02-.36-.01-.72-.01-1.08,0,0-.01-.01-.04-.04-.05.05-.12.11-.17.17-1.27,1.28-2.54,2.56-3.81,3.83-.56.57-1.23.74-1.98.45-.25-.1-.49-.28-.69-.47-.5-.47-.97-.96-1.45-1.44-.27-.27-.34-.27-.61,0-1.06,1.06-2.13,2.13-3.19,3.19-.19.19-.41.32-.68.29-.31-.03-.54-.2-.65-.49-.11-.3-.06-.58.17-.8,1.15-1.16,2.3-2.32,3.47-3.46.69-.67,1.76-.62,2.47.08.52.51,1.03,1.03,1.55,1.54.2.2.31.2.51.01,1.31-1.32,2.62-2.63,3.94-3.95.01-.02.03-.04.08-.1h-1.12c-.52,0-.85-.31-.85-.77,0-.45.34-.76.84-.76.96,0,1.92,0,2.88,0,.59,0,.89.31.89.89,0,.97,0,1.94,0,2.91h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Staff Retention Factors</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='saf' ? ' active' : '')" @click="$emit('sidebar-click', 'saf')">
          <a class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m19.98,17.33c-.07.32-.27.52-.6.59-.12.02-.24.03-.36.03h-8.41c-2.83,0-5.66,0-8.48,0-1.17,0-2.13-.95-2.13-2.12C0,10.85,0,5.87,0,.89,0,.42.21.12.58.03c.47-.12.92.2.96.68,0,.09,0,.18,0,.26v14.49c0,.83.11.95.94.95h16.58c.23,0,.47,0,.65.17.23.2.33.45.26.75Z"
                />
                <path
                  class="cls-1"
                  d="m18.97,11.03c0,.44-.32.73-.76.74-1.02.01-2.05.01-3.08,0-.45,0-.76-.33-.76-.76,0-.43.3-.75.76-.77.38-.01.77,0,1.15,0,.02-.03.03-.05.05-.08-.11-.09-.22-.18-.32-.28-1.22-1.22-2.43-2.44-3.65-3.66-.23-.23-.33-.23-.56,0-.49.49-.99.98-1.48,1.47-.81.8-1.87.8-2.68-.01-1.06-1.06-2.12-2.12-3.18-3.18-.38-.38-.41-.83-.07-1.17.33-.33.78-.29,1.17.09,1.04,1.04,2.08,2.08,3.13,3.13.28.28.34.28.62,0,.48-.48.95-.95,1.43-1.42.81-.81,1.88-.81,2.69,0,1.27,1.26,2.53,2.54,3.8,3.81.06.06.09.14.14.22.03-.02.06-.04.09-.07,0-.39,0-.78,0-1.17.01-.46.37-.77.83-.74.38.03.69.32.7.73.02,1.04.02,2.08,0,3.12h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Staff Attrition Factors</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='tvp' ? ' active' : '')" @click="$emit('sidebar-click', 'tvp')">
          <a class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m17.94,12.97c-.84,3.25-2.84,5.44-6,6.57-.92.33-1.88.47-2.62.46-4.66,0-8.44-3.18-9.19-7.52C-.68,7.74,2.29,3.2,6.95,2.08c.16-.04.35-.04.51,0,.29.07.47.37.47.66,0,.31-.22.56-.54.67-.46.15-.95.26-1.38.46-2.64,1.24-4.21,3.29-4.55,6.2-.47,3.95,2.21,7.6,6.09,8.38,4.04.82,7.98-1.66,9.01-5.66.01-.05.02-.1.04-.15.11-.4.47-.63.84-.54.39.1.62.46.51.87h0Z"
                />
                <path
                  class="cls-1"
                  d="m19.98,8.82c-.21-1.76-.82-3.36-1.88-4.78C16.39,1.76,14.1.42,11.27.02c-1.11-.16-1.95.58-1.95,1.69v3.63c0,1.21,0,2.46,0,3.69,0,.91.71,1.63,1.62,1.64,2.48,0,4.95,0,7.43,0,1,0,1.73-.85,1.61-1.85h0Zm-1.72.45c-2.41,0-4.82,0-7.23,0-.23,0-.31-.08-.31-.32V1.79c0-.36.07-.43.43-.37,2.63.4,4.67,1.7,6.13,3.92.71,1.08,1.12,2.28,1.29,3.56.04.29-.03.38-.32.38h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Total Value Proposition</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item multi-level' + (active==='tvp-highlights' ? ' active' : '')" @click="$emit('sidebar-click', 'tvp-highlights')">
          <a  class="nav-link">
            <span class="sidebar-text">TVP Highlights</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item multi-level' + (active==='tvp-lowlights' ? ' active' : '')" @click="$emit('sidebar-click', 'tvp-lowlights')">
          <a  class="nav-link">
            <span class="sidebar-text">TVP Lowlights</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item multi-level' + (active==='tvp-low-variance' ? ' active' : '')" @click="$emit('sidebar-click', 'tvp-low-variance')">
          <a  class="nav-link">
            <span class="sidebar-text">TVP Low Variance</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item multi-level' + (active==='tvp-high-variance' ? ' active' : '')" @click="$emit('sidebar-click', 'tvp-high-variance')">
          <a  class="nav-link">
            <span class="sidebar-text">TVP High Variance</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore && (tenantFeatures.tvpDisparities || userFeatures.tvpDisparities) && compareSurveys !== true" :class="'nav-item multi-level' + (active==='tvp-disparities' ? ' active' : '')" @click="$emit('sidebar-click', 'tvp-disparities')">
          <a  class="nav-link">
            <span class="sidebar-text">TVP Disparities Overview</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='pillars-equity' ? ' active' : '')" @click="$emit('sidebar-click', 'pillars-equity')">
          <a  class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m12.83,2.13c0-1.17-.95-2.13-2.12-2.13h-1.44c-1.16,0-2.1.96-2.1,2.12v15.76c0,1.16.95,2.12,2.1,2.12.49,0,.98,0,1.47,0,.63,0,1.15-.25,1.56-.72.42-.48.53-1.05.53-1.67,0-5.16,0-10.31,0-15.47h0Zm-1.55,15.37c0,.84-.11.96-.95.96-.31,0-.63,0-.94,0-.49-.01-.64-.16-.68-.64,0-.1,0-.19,0-.29V2.49c0-.83.12-.94.94-.94.33,0,.66,0,.98,0,.44.01.59.16.63.58.01.13.01.26.01.38v14.99h0Z"
                />
                <path
                  class="cls-1"
                  d="m18.06,5.14c-.54-.03-1.09-.02-1.63-.01-1.16.02-2.08.96-2.08,2.11,0,1.82,0,3.64,0,5.45s0,3.46,0,5.19c0,1.16.93,2.12,2.09,2.12.48,0,.96,0,1.44,0,1.18,0,2.12-.94,2.12-2.12V7.23c0-1.1-.84-2.02-1.93-2.1h0Zm.39,2.44v9.97c0,.8-.12.91-.92.91-.3,0-.61,0-.91,0-.56,0-.72-.16-.72-.71,0-1.73,0-3.46,0-5.19h0v-5.02c0-.75.12-.86.87-.86.37,0,.74,0,1.11,0,.39.01.55.17.58.56.01.11,0,.22,0,.33h0Z"
                />
                <path
                  class="cls-1"
                  d="m5.65,11.34c0-1.01-.69-1.91-1.69-2.06-.62-.09-1.26-.06-1.89-.05C.94,9.23,0,10.18,0,11.31,0,13.51,0,15.71,0,17.91c0,.99.71,1.88,1.68,2.02.61.09,1.24.06,1.87.06,1.16,0,2.1-.95,2.1-2.12,0-1.08,0-2.16,0-3.24s0-2.19,0-3.29Zm-1.55,3.26v2.95c0,.12,0,.24-.01.36-.03.33-.16.5-.48.52-.53.03-1.05.02-1.58,0-.3-.01-.41-.16-.46-.46-.02-.12-.02-.25-.02-.38v-5.95c0-.73.13-.87.87-.87.33,0,.66,0,.98,0,.53,0,.68.16.7.69,0,.03,0,.07,0,.1v3.05h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">{{ tenantTitle }} Pillars of Equity</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='equity-index' ? ' active' : '')" @click="$emit('sidebar-click', 'equity-index')">
          <a  class="nav-link">
            <span class="sidebar-icon">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-xs me-2"  fill="currentColor"  viewBox="0 0 16 16"><path fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z"/></svg>
            </span>
            <span class="sidebar-text">Equity Index</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='appendix' ? ' active' : '')" @click="$emit('sidebar-click', 'appendix')">
          <a  class="nav-link">
            <span class="sidebar-icon">
              <svg fill="none" class="icon icon-xs me-2" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"></path> </svg>
            </span>
            <span class="sidebar-text">Appendix</span>
          </a>
        </li>

        <li v-if="hasReportData && userPermissions.canExplore" :class="'nav-item' + (active==='next-steps' ? ' active' : '')" @click="$emit('sidebar-click', 'next-steps')">
          <a  class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m19.99.68c0-.3-.16-.51-.44-.62-.12-.05-.26-.05-.38-.05C17.43,0,15.68,0,13.94,0c-.69,0-.93.23-.93.92,0,2.39,0,4.78,0,7.17,0,.09,0,.18-.01.29-.42,0-.81-.01-1.2,0-.8.03-1.46.61-1.55,1.41-.04.37-.02.75-.03,1.13,0,.08,0,.15-.01.24h-.26c-1.18,0-2.35,0-3.53,0-.16,0-.32,0-.48.03-.78.17-1.29.81-1.3,1.64,0,.37,0,.74,0,1.11-.05,0-.08.01-.1.01-.93,0-1.86,0-2.79,0-1.08,0-1.75.67-1.75,1.76,0,.84,0,1.68,0,2.53,0,1.08.67,1.75,1.75,1.75,1.34,0,2.67,0,4.01,0h5.6c1.35,0,2.7,0,4.05,0,.16,0,.32,0,.48-.03.8-.17,1.3-.81,1.3-1.67,0-1.74,0-3.49,0-5.23,0-1.05.01-2.09,0-3.14-.02-.84-.7-1.51-1.54-1.54-.3-.01-.61,0-.92,0-.1,0-.21,0-.32,0v-3.26h.27c1.51,0,3.02,0,4.53,0,.45,0,.76-.27.76-.69,0-1.25,0-2.5,0-3.75h0ZM4.64,18.6h-2.03c-.3,0-.61,0-.91,0-.2,0-.3-.08-.3-.28,0-.89,0-1.79,0-2.68,0-.2.09-.29.29-.29.89,0,1.79,0,2.68,0,.15,0,.27.04.27.21,0,1,0,2,0,3.03h0Zm5.57-.01h-4.14c0-.08-.01-.15-.01-.22,0-1.8-.01-3.59-.02-5.39,0-.38.04-.42.43-.42h3.74v6.03h0Zm5.24-8.82c.32,0,.37.04.37.36,0,2.7,0,5.41,0,8.11,0,.31-.05.36-.35.36-1.2,0-2.4,0-3.6,0-.06,0-.13,0-.22-.01v-.31c0-1.77,0-3.53,0-5.3,0-.94-.01-1.89-.01-2.83,0-.33.05-.38.38-.38,1.15,0,2.3,0,3.44,0h0Zm3.14-6.07h-4.14V1.41h4.14v2.29h0Z"
                />
              </svg>
            </span>
            <span class="sidebar-text">Next Steps</span>
          </a>
        </li>

        <li v-if="userPermissions.canViewCustomReport && hasReportData" :class="'nav-item' + (active==='reports' ? ' active' : '')" @click="$emit('sidebar-click', 'reports')">
          <a  class="nav-link">
            <span class="sidebar-icon">
              <svg
                class="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  class="cls-1"
                  d="m12.65,19.99H2.82c-1.56,0-2.82-1.27-2.82-2.83V2.83C0,1.27,1.27,0,2.82,0h9.83c1.56,0,2.82,1.27,2.82,2.83v14.34c0,1.56-1.27,2.83-2.82,2.83h0ZM2.82,1.36c-.81,0-1.46.66-1.46,1.47v14.34c0,.81.66,1.47,1.46,1.47h9.83c.81,0,1.46-.66,1.46-1.47V2.83c0-.81-.66-1.47-1.46-1.47,0,0-9.83,0-9.83,0Z"
                />
                <g>
                  <polygon
                    class="cls-1"
                    points="3.97 15.01 3.42 14.61 5.9 11.23 8.92 13.54 11.56 11.25 12.01 11.76 8.95 14.41 6.04 12.19 3.97 15.01"
                  />
                  <circle class="cls-1" cx="3.69" cy="14.81" r=".94" />
                  <circle class="cls-1" cx="5.96" cy="11.73" r=".94" />
                  <circle class="cls-1" cx="8.9" cy="13.87" r=".94" />
                  <circle class="cls-1" cx="11.78" cy="11.5" r=".94" />
                </g>
                <rect
                  class="cls-1"
                  x="2.75"
                  y="3.14"
                  width="9.98"
                  height="2.12"
                />
              </svg>
            </span>
            <span class="sidebar-text">Reports</span>
          </a>
        </li>

        <li v-if="userPermissions.canManageClients || userPermissions.canManageUsers || userPermissions.canManageCampaignTemplates" class="nav-item"><span class="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-components"><span>
          <span class="sidebar-icon">
            <svg
              class="icon icon-xs me-2"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                class="cls-1"
                d="m14.84,12.14v-.89l-1.53-.75c-.08-.29-.12-.43-.19-.72l.96-1.41c-.21-.35-.24-.41-.45-.77l-1.69.13-.54-.54.12-1.7c-.35-.21-.41-.24-.77-.44l-1.41.95c-.3-.08-.43-.11-.72-.19l-.75-1.53h-.89l-.75,1.53c-.3.08-.43.12-.72.19l-1.41-.95c-.36.21-.42.24-.77.44l.12,1.7c-.21.22-.32.32-.53.54l-1.7-.13c-.21.36-.24.42-.44.77l.95,1.41c-.08.29-.12.43-.19.72l-1.53.75v.89l1.53.75c.08.29.12.43.19.72l-.95,1.41c.21.35.24.41.44.77l1.7-.12.53.53-.12,1.7c.35.21.41.24.77.44l1.41-.95c.29.08.43.12.72.19l.75,1.53h.89l.75-1.53c.29-.08.43-.12.72-.19l1.41.95c.35-.21.41-.24.77-.44l-.12-1.7c.22-.21.32-.32.54-.53l1.69.12c.21-.36.24-.42.45-.77l-.96-1.41c.08-.29.12-.43.19-.72,0,0,1.53-.75,1.53-.75Zm-7.42,4.51c-2.74,0-4.96-2.22-4.96-4.96s2.22-4.96,4.96-4.96,4.96,2.22,4.96,4.96-2.22,4.96-4.96,4.96Z"
              />
              <circle class="cls-1" cx="7.41" cy="11.69" r="1.54" />
              <g>
                <path
                  class="cls-1"
                  d="m17.88,6.13l1.12-.73c-.05-.32-.05-.37-.09-.69l-1.26-.42-.22-.55.6-1.19c-.19-.26-.22-.3-.42-.55l-1.31.26c-.19-.14-.28-.21-.47-.36l-.07-1.32-.64-.26-.99.87c-.24-.03-.35-.05-.58-.08l-.73-1.12c-.32.04-.37.05-.69.09l-.43,1.26c-.22.09-.32.14-.54.22l-1.19-.6c-.26.2-.3.22-.55.43l.26,1.31c-.14.18-.22.28-.36.47l-1.33.07-.18.43c.55.2.95.51,1.27.99.12.18.19.4.31.59h0c.05-.32.12-.64.25-.95.83-1.97,3.09-2.9,5.06-2.08s2.9,3.09,2.08,5.06c-.54,1.28-1.69,2.12-2.98,2.32-.03.07-.07.14-.11.21.02,0,.06,0,.08,0,.26.06.52.14.75.29.06.04.13.09.19.13h0c.22-.1.32-.14.55-.22l1.19.6c.26-.19.3-.22.55-.42l-.26-1.31c.14-.18.22-.28.36-.47l1.33-.07.26-.64-.88-1c.03-.23.05-.35.08-.58h-.02Z"
                />
                <circle class="cls-1" cx="13.21" cy="5.79" r="1.2" />
              </g>
            </svg>
          </span>
          <span class="sidebar-text">Settings</span> </span><span class="link-arrow"><svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg></span></span>
          <div v-if="userPermissions.canManageClients || userPermissions.canManageUsers || userPermissions.canManageCampaignTemplates" class="multi-level collapse" role="list" id="submenu-components" aria-expanded="false">
            <ul class="flex-column nav">
              <li v-if="userPermissions.canManageCampaignTemplates" :class="'nav-item' + (active==='manage-campaign-templates' ? ' active' : '')" @click="$emit('sidebar-click', 'manage-campaign-templates')">
                <a  class="nav-link">
                  <span class="sidebar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 16 16"><path d="m17.87,16.81c-.24.37-.67.46-1,.24-.33-.23-.4-.64-.15-1.02.56-.86.62-1.76.19-2.69-.37-.8-.74-1.61-1.12-2.41-.79-1.69-2.59-2.33-4.28-1.54-.37.17-.75.35-1.15.44-.71.15-1.24-.16-1.56-.82-.42-.89-.83-1.79-1.25-2.68-.18-.39-.54-.56-.91-.44-.44.14-.64.61-.43,1.06.54,1.16,1.08,2.32,1.62,3.47.45.96.89,1.92,1.34,2.88.1.21.14.41.06.63-.14.38-.55.55-.96.38-.68-.27-1.35-.57-2.03-.84-.56-.22-1.13.11-1.2.69-.05.4.18.74.6.9,1.08.41,2.16.81,3.24,1.22.37.14.74.29,1.1.46.55.25.98.65,1.28,1.18.2.35.38.7.55,1.06.18.38.03.79-.32.96-.35.16-.74.01-.93-.36-.14-.27-.27-.55-.41-.82-.23-.45-.61-.74-1.07-.91-1.31-.49-2.62-.99-3.93-1.48-.77-.29-1.28-.82-1.46-1.63-.19-.84.05-1.58.69-2.15.64-.57,1.39-.72,2.21-.47.18.06.35.13.58.22-.35-.76-.68-1.46-1-2.16-.42-.89-.84-1.78-1.25-2.68-.59-1.32.16-2.75,1.58-3.01.9-.17,1.83.32,2.26,1.2.42.86.82,1.73,1.21,2.6.08.19.17.2.35.13.54-.22,1.08-.49,1.64-.63,1.98-.47,4.03.51,4.95,2.34.47.94.93,1.89,1.33,2.86.56,1.34.4,2.63-.39,3.84h0Z"/></svg>
                  </span>
                  <span class="sidebar-text"> Manage Templates </span>
                </a>
              </li>

              <li v-if="userPermissions.canManageUsers" :class="'nav-item' + (active==='manage-users' ? ' active' : '')" @click="$emit('sidebar-click', 'manage-users')">
                <a  class="nav-link">
                  <span class="sidebar-icon">
                    <svg fill="none" class="icon icon-xs me-2" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"></path></svg>
                  </span>
                  <span class="sidebar-text"> Manage Users </span>
                </a>
              </li>

              <li v-if="userPermissions.canManageClients" :class="'nav-item' + (active==='manage-client' ? ' active' : '')" @click="$emit('sidebar-click', 'manage-client')">
                <a  class="nav-link">
                  <span class="sidebar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 16 16"><path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/></svg>
                  </span>
                  <span class="sidebar-text"> Manage Clients </span>
                </a>
              </li>

              <li v-if="userPermissions.isPlatformAdmin" :class="'nav-item' + (active==='manage-tenant' ? ' active' : '')" @click="$emit('sidebar-click', 'manage-tenant')">
                <a  class="nav-link">
                  <span class="sidebar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-back" viewBox="0 0 16 16"><path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/></svg>
                  </span>
                  <span class="sidebar-text"> Manage Tenants </span>
                </a>
              </li>
              
              <li v-if="userPermissions.isPlatformAdmin" :class="'nav-item' + (active==='manage-features' ? ' active' : '')" @click="$emit('sidebar-click', 'manage-features')">
                <a  class="nav-link">
                  <span class="sidebar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-nintendo-switch" viewBox="0 0 16 16"><path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.14 4.14 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979m4.675.269a1.62 1.62 0 0 0-1.113-1.034 1.61 1.61 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.63 1.63 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053M3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256"/><path d="M3.425.053a4.14 4.14 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.85 2.85 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z"/></svg>
                  </span>
                  <span class="sidebar-text"> Manage Features </span>
                </a>
              </li>

              <li v-if="userPermissions.isPlatformAdmin" :class="'nav-item' + (active==='manage-user-features' ? ' active' : '')" @click="$emit('sidebar-click', 'manage-user-features')">
                <a  class="nav-link">
                  <span class="sidebar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-fill-gear" viewBox="0 0 16 16"><path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-3.59 1.787A.5.5 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.5 4.5 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/><path d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/></svg>
                  </span>
                  <span class="sidebar-text"> Manage User Features </span>
                </a>
              </li>
              
            </ul>
          </div>
        </li>

        <li v-if="this.$store.state.isSwitched" class="nav-item">
          <a class="nav-link" @click="this.switchBack()">
            <span class="sidebar-icon">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/></svg>
            </span>
            Switch back
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" @click="logout();">
            <span class="sidebar-icon">
              <svg class="icon icon-xs me-2" fill="none" stroke="red" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
            </span>
            Logout
          </a>
        </li>

      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "NewSideBar",
  computed: {
    ...mapState(['active', 'hasReportData', 'tenantFeatures', 'compareSurveys', 'userFeatures']),
    userPermissions(){
      return this.$store.state.userPermissions
    },
    tenant(){
      return localStorage.getItem("subdomain")
    },
    tenantTitle(){
      return this.tenant.charAt(0).toUpperCase() + this.tenant.slice(1)
    }
  },
  mounted(){
    console.log(this.active)
  },

  methods:{
    logout() {
      this.$store.state.username = '';
      localStorage.setItem("token", "");
      this.$toast.success("Logged out successfully")
      this.$router.replace('/login')
    },

    switchBack(){
      this.$store.commit('switchBack')
      this.$toast.info("Switched Back")
    }
  }
}
</script>

<style>
.sidebar-text {
  color: gray;
  cursor: default;
  text-decoration: none;
}
</style>
