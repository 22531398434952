<template>
  <nav class="navbar navbar-expand-md bg-body-tertiary border-danger">
    <div class="container-fluid ps-4 pe-4" style="background-color: #e6ecff">
      <a class="navbar-brand font-monospace" href="#">
        <img
          src="../assets/logo-edgility.png"
          alt="Logo"
          width="30"
          height="30"
          class="d-inline-block align-text-center"
        />
        Edgility
      </a>
      <button
        class="navbar-toggler m-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul
          class="navbar-nav justify-content-end me-auto mb-2 mb-lg-0 text-uppercase text-info-emphasis"
        >
          <li class="nav-item">
            <a class="nav-link text-dark-emphasis hov shadow-sm" href="#"
              >Survey List</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark-emphasis hov shadow-sm" href="#"
              >Edgility View</a
            >
          </li>
        </ul>
        <button class="btn btn-outline-danger m-2">Logout</button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped>
.hov:hover {
  border-bottom: 1px solid gray;
}
</style>
