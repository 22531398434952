<template>

  <div class="is-loading-bar has-text-centered" v-bind:class="{'is-loading': $store.state.isLoading }">
    <div  v-bind:class="{'lds-dual-ring': $store.state.isLoading }"></div>
  </div>
  <section>
    <router-view />
  </section>
</template>

<script>
import axios from "axios";
import NavBar from "./components/NavBar.vue";
import Login from "./views/Login.vue";
import router from "@/router";

const url = "api";

export default {
  name: "App",
  components: {
    NavBar,
    Login,
  },
  data() {
    return {
      surveys: [

      ],
    };
  },
  beforeCreate() {
    this.$store.commit("initializeStore");

    const token = this.$store.state.token;

    if (token) {
      console.info("token is present");
      let parsed_token = JSON.parse(token)
      axios.defaults.headers.common['TenantID'] = parsed_token.tenantId
    } else {
      console.info("token is not present");
      axios.defaults.headers.common['TenantID'] = "";
      let url = window.location.href;
      if(!url.includes("/public/") && !url.includes("/forget")) {
        router.push('/login')
      }
    }
  },
  mounted() {
    // this.cart = this.$platform.state.cart
  },
  created() {},
};
</script>

<style>
@import "@/assets/css/volt.css";

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  left: 50%;
  top: 5%;
  position: absolute;
  z-index: 1000;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.is-loading-bar.is-loading {
  height: 80px;
}

</style>
