<template xmlns="http://www.w3.org/1999/html">
    <!-- START White div with shadow begins -->
 <!-- <div class="card border-0 shadow mb-4">
     <SectionSpecificFilter v-if="userPermissions.canDoSectionSpecificFilters" :section="this.section"/>
 
     <button
             v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
             type="button"
             class="btn btn-sm"
             title="Delete Page"
             @click="this.deletePage"
             style="position: absolute; top: 1.5em; right: 2.5em; "
         >
             <span class="fs-3 me-1 fw-bolder text-danger">X</span>
         </button>
 
     <button
         type="button"
         class="btn me-1"
         title="Set Demographics"
         data-bs-toggle="modal"
         :data-bs-target="`#modalDemographicCharts${section.order}`"
         @click="this.setDemographicTypes"
         style="position: absolute; top: 6em; right: 1.5em;"
     >
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"><path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/></svg>
     </button>
 
     <button
         v-if="userPermissions.canAddUpdateCustomReport"
         type="button"
         class="btn me-1"
         title="Duplicate this page"
         @click="this.duplicatePage"
         style="position: absolute; top: 10.5em; right: 1.5em;"
     >
       <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
     </button>
 
         <div
             class="modal fade"
             :id="`modalDemographicCharts${section.order}`"
             tabindex="-1"
             role="dialog"
             aria-hidden="true"
         >
         <div class="modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content">
             <div class="modal-header border-0">
                 <button
                     type="button"
                     class="btn-close"
                     data-bs-dismiss="modal"
                     aria-label="Close"
                     ref="closeModal"
                 ></button>
             </div>
             <div class="modal-body px-md-5">
                 <h2 class="h4 text-center">Select Demographics</h2>
                 <label class="typo__label">Select Demographics</label>
                 <multiselect ref="demographicChats" track-by="enumValue" label="displayName"
                              v-model="this.$store.state.sections[this.index].filters.display_filters.display_charts"
                              :options="this.displayFiltersDemographicTypeOptions" :multiple="true"
                              :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                              placeholder="Pick some" :preselect-first="false"
                              :disabled="applyingFilters"
                 ></multiselect>
 
                 <div
                     class="d-flex align-items-center justify-content-end gap-4 mt-4"
                 >
                 <button class="btn btn-outline-primary"
                         type="button"
                         data-bs-dismiss="modal"
                         aria-label="Close"
                         ref="closeModal"
                 > Cancel </button>
                 <button class="btn btn-primary" type="button" @click="applyFilter" :disabled="applyingFilters">
                   <div v-if="applyingFilters" class="d-flex align-items-center">
                     <span role="status" class="me-2">Applying Filters</span>
                     <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                   </div>
                   <span v-else>
                     Apply Filters
                   </span>
                 </button>
                 </div>
             </div>
             </div>
         </div>
         </div>
 
 
        </div>
        -->
        <br> <!-- gap -->

        <!--  overflow-scroll" style="height: 90vh;" -->

        <div class="card-body">
          <!-- <h2 class="text-center fw-normal mx-8" @blur="this.updateTitle" :contenteditable="section.duplicate === 1">{{ this.$store.state.sections[this.index].title }}</h2> -->
          <h2 class="text-center fw-normal mx-8">{{ this.section1.title }}</h2>
      
            <div class="mx-8">
              <!-- <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
                <hr>
                <span class="fw-bolder text-warning">Section Specific Filters:  </span>
                <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
                  <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
                  <span class="text-decoration-none">{{ value.join(', ') }}</span>
                </div>
                <br>
              </div> -->
              <div>
                <p class="edgility-gray lh-sm">Your Net Promoter score measures how loyal your staff are and how likely they are to serve as passionate advocates of your work and culture.</p>
              </div>
            </div>
            <br>
            <hr>

            <p class="fw-bolder fs-4" style="text-align: center;">
                {{ this.$store.state.comparativeSurveyTitles.title1 }}
            </p>
            <br>
      
            <div class="edgility-offwt-bg mx-8 p-2" style="display: flex; align-items: center;">
              <div style="font-size: 4em; font-weight: 800; padding: 0 1em; display: inline-block;" class="edgility-red">{{ nps1.nps }}</div>
              <div style="display: inline-block; width: 75%;"><p v-html="nps1.nps_text" class="lh-sm m-0"></p>
              </div>
            </div>

            <br>
            <p class="fw-bolder fs-4" style="text-align: center;">
                {{ this.$store.state.comparativeSurveyTitles.title2 }}
            </p>
      
            <div class="edgility-offwt-bg mx-8 p-2" style="display: flex; align-items: center;">
              <div style="font-size: 4em; font-weight: 800; padding: 0 1em; display: inline-block;" class="edgility-red">{{ nps2.nps }}</div>
              <div style="display: inline-block; width: 75%;"><p v-html="nps2.nps_text" class="lh-sm m-0"></p>
              </div>
            </div>
        </div>
        <br>
        <br>
        
 <div class="card border-0 shadow mb-4">
   <div class="card-body">
 
     <h2 class="text-center fw-normal">Net Promoter Score (NPS)</h2>
     <div class="mx-8">
       <p class="edgility-gray lh-sm">The following outlines the composition of respondents broken down by demographic group. Groups with fewer than 5 respondents are not represented to ensure anonymity.</p>
     </div>
 
       <div class="mx-8" style="margin: 0px 30px 0px 30px">
         <div class="new-row nps-labels-row lpadding">
             <span class="nps-labels">
                 <p style="padding: 0; margin: 0">Needs Improvement</p>
             </span>
             <span class="nps-labels">
                 <p style="padding: 0; margin: 0">Good</p>
             </span>
             <span class="nps-labels">
                 <p style="padding: 0; margin: 0">Great</p>
             </span>
             <span class="nps-labels">
                 <p style="padding: 0; margin: 0">Excellent</p>
             </span>
         </div>
        </div>

        <br>
        <br>
         <div class="chart-row nps-summary">
               <div v-for="(breakdown, index) in nps_break_down1">
                    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded" v-if="nps_break_down1[index] !== null || nps_break_down2[index] !== null">

                        <!-- Survey 1 -->
                        <p v-if="breakdown !== null" class="fw-bolder fs-2" style="text-align: center;">
                            {{ breakdown.title }}
                        </p>

                        <p v-else class="fw-bolder fs-2" style="text-align: center;">
                            {{ nps_break_down2[index].title }}
                        </p>

                        <hr>
                        <div class="chart-insert-nps">
                            <canvas v-if="nps_break_down1[index] !== null"
                            :id="`${nps_break_down1[index].id}${this.section1.order}1`"
                            style="width: 100%; height: 190px; max-width: 800px;"
                            />
                            <p v-else class="fw-bolder p-4 fs-5 border rounded border-danger border-opacity-25" style="width: 100% !important; text-align: center;">
                                {{ nps_break_down2[index].title }} for <span class="text-danger"> {{ this.$store.state.comparativeSurveyTitles.title1 }} </span> falls under n less than 5
                            </p>
                        </div>
                        
                        <!-- Survey 2 -->
                        
                        <div class="chart-insert-nps">
                            <canvas v-if="nps_break_down2[index] !== null"
                            :id="`${nps_break_down2[index].id}${this.section2.order}2`"
                            style="width: 100%; height: 190px; max-width: 800px;"
                            />
                            <p v-else class="fw-bolder p-4 fs-5 border rounded border-danger border-opacity-25" style="width: 100% !important; text-align: center;">
                               {{ nps_break_down1[index].title }} for <span class="text-danger"> {{ this.$store.state.comparativeSurveyTitles.title2 }} </span> falls under n less than 5
                            </p>
                        </div>
                        <br>
                    </div>
               </div>
         </div>
   </div>
 </div>
 <!-- END White div with shadow begins -->
 </template>
 
 <script>
   import { npsChartCreator } from '@/assets/js/npsChart';
   import axios from 'axios';
//    import SectionSpecificFilter from '../SectionSpecificFilter.vue';
   import { mapGetters, mapState } from 'vuex';
 
   export default {
     props: ['section1', 'section2'],
     name: "ComparativeNPSBreakdown",
     data(){
       return {
         demographicTypesOptions: [],
         nps1: this.section1.nps,
         nps2: this.section2.nps,
         allNLessThan5Groups1: this.section1.allNLessThan5Groups,
         allNLessThan5Groups1: this.section2.allNLessThan5Groups,
       }
     },
 
     computed: {
       ...mapState(['displayFiltersDemographicTypeOptions']),
       userPermissions(){
            return this.$store.state.userPermissions
       },
       nps_break_down1(){
            let breakdown1 = []
            for (var nps of this.section1.nps_break_down){
                breakdown1 = breakdown1.concat(nps.rows)
            }

            return breakdown1
       },

       nps_break_down2(){
            let breakdown2 = []
            for (var nps of this.section2.nps_break_down){
                breakdown2 = breakdown2.concat(nps.rows)
            }

            return breakdown2
       },

    //    index(){
    //      return this.$store.state.sections.indexOf(this.section)
    //    }
     },
 
    components: {
    // SectionSpecificFilter
    },
 
    mounted(){
        for (const breakdown of this.nps_break_down1) {
            if (breakdown !== null){
                npsChartCreator({
                    title: this.$store.state.comparativeSurveyTitles.title1,
                    data: breakdown.data
                }, `${breakdown.id}${this.section1.order}1`);
            }
        }

        for (const breakdown of this.nps_break_down2) {
            if (breakdown !== null){
                npsChartCreator({
                    title: this.$store.state.comparativeSurveyTitles.title2,
                    data: breakdown.data
                }, `${breakdown.id}${this.section2.order}2`);
            }
        }
     },
 
     errorCaptured(err, instance, info){
         this.$toast.error("Something Went Wrong! Please see log for more information.");
         console.error(err)
         this.$router.go(-1)
         return false
     },
 
     methods: {
    //    updateTitle(event){
    //      if (event.target.textContent == '' || event.target.textContent.trim() == ''){
    //          this.$toast.error(`Please Provide a Valid Name`);
    //          return
    //      }
    //      this.$store.state.sections[this.index].title = event.target.textContent
    //    },
    //    async setDemographicTypes(){
    //      console.log(this.index)
    //      if (this.$store.state.displayFiltersDemographicTypeOptions.length > 0){
    //      return
    //      }
    //      let url = `api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-types/`
 
    //      var token = localStorage.getItem("token");
    //      var token_map = JSON.parse(token);
    //      const config = {
    //          headers: {Authorization: `Bearer ${token_map.access}`},
    //      };
 
    //      await axios
    //        .get(url, config)
    //        .then((response) => {
    //        console.log(response.data)
    //        response.data.forEach((obj) => {
    //            this.$store.state.displayFiltersDemographicTypeOptions.push({
    //                displayName: obj['display_name'],
    //                enumValue: obj['enum_value'],
    //            })
    //        })
    //        console.log(this.displayFiltersDemographicTypeOptions)
    //        })
    //        .catch(err => {
    //          this.$toast.error(`Failed to fetch demographic values`);
    //          console.log(err);
    //        })
    //    },
 
    //    async applyFilter(){
    //      console.log(this.index)
    //      var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
    //      var token = localStorage.getItem("token");
    //      var token_map = JSON.parse(token);
    //      // this.$store.state.sections[this.index].filters.display_filters.display_charts = this.selectedValues
    //      const config = {
    //      headers: {Authorization: `Bearer ${token_map.access}`},
    //      params: this.$store.getters.params
    //      };
 
    //      this.applyingFilters = true
 
    //      await axios
    //      .get(url, config)
    //      .then((response) => {
    //          if (response.status === 200){
    //              console.log(response.data)
    //              this.$store.commit('setReportData', response.data)
    //              console.log(this.$store.state.reportData)
    //              this.$store.commit('applyFilters')
    //              this.$refs.closeModal.click()
    //              console.log(this.$store.state.sections)
    //              this.$toast.info(`Filters applied successfully`);
    //          }
 
    //          else{
    //              this.$toast.info("No Responses Found for this Filter")
    //          }
 
    //      })
    //      .catch((error) => {
    //          console.log(error);
    //          console.log("Error in Promise")
    //      })
    //      .finally(() => {this.applyingFilters = false})
    //    },
 
    //    duplicatePage(){
    //      this.$emit('duplicatePage', this.index)
    //    },
 
    //    deletePage(){
    //      this.$emit('deletePage', this.index)
    //    }
     }
   }
 </script> 