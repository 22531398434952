<template>
    <div class="card border-0 shadow mb-4">
        <div class="card-header border-bottom d-flex align-items-center justify-content-center">
            <h2 class="fs-5 fw-bolder mb-0 me-4"> Select Feature: </h2>
            <multiselect style="width: 300px;" v-model="selectedFeature" :options="featuresList" label="featureName" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Feature"></Multiselect>
        </div>
        <!-- <hr> -->

        <div v-if="selectedFeature !== null" class="card-body">
            <ul class="list-group list-group-flush list my--3">
                <li v-for="user in userFeatures[selectedFeature.index].users" class="list-group-item p-3 m-1 border d-flex align-items-center justify-content-between" :key="org">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <h2 class="h5 mb-0 bold">
                                <a href="#"> {{ user.username }} </a> <span class="text-success fw-bolder" v-if="user.username === this.$store.state.username"> (You) </span>
                            </h2>
                            <div v-if="user.enabled" class="d-flex align-items-center">
                                <div class="bg-success dot rounded-circle me-1"></div>
                                <small>Enabled</small>
                            </div>

                            <div v-else class="d-flex align-items-center">
                                <div class="bg-danger dot rounded-circle me-1"></div>
                                <small>Disabled</small>
                            </div>
                        </div>
                    </div>
                    <div v-if="updatingUser === user.username" class="d-flex align-items-center">
                        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                    </div>
            
                    <div v-else class="form-check form-switch">
                        <input class="form-check-input" v-model="user.enabled" type="checkbox" role="switch" @change="updateEnabled(user, userFeatures[selectedFeature.index].feature.name)" id="flexSwitchCheckDisabled" >
                    </div>
                </li>
            </ul>
        </div>
    </div>
  
</template>


<script>
import axios from 'axios';
export default {
    name: "ManageUserFeatures",

    data() {
        return {
            userFeatures: [],
            updatingUser: null,
            featuresList: [],
            selectedFeature: null
        }
    },

    mounted() {
        this.getUserFeaturesList()
    },

    methods: {
        async getUserFeaturesList(selectedFeature = null) {
            axios
                .get('api/user-features-list/')
                .then(resp => {
                    this.userFeatures = resp.data
                    let featuresList = []
                    resp.data.map((feature, index) => {
                        featuresList.push({
                            "index": index,
                            "featureName": feature.feature.display_name 
                        })
                    })
                    this.featuresList = featuresList
                    if (selectedFeature === null)
                        this.selectedFeature = this.featuresList[0]
                    console.log(this.featuresList)
                })
                .catch(err => console.log(err))

        },

        async updateEnabled(user, feature){
            this.updatingUser = user.username;

            axios
                .post(`api/user/${user.user_id}/switch-feature-enable/?feature=${feature}`)
                .then((resp) => {
                    if (resp.status === 200){
                        this.getUserFeaturesList(this.selectedFeature);
                    }

                    if (user.username === this.$store.state.username)
                        this.$store.commit("setUserFeatures")
                })
                .catch((error) => {
                    user.enabled = !user.enabled
                    this.$toast.error(error.data)
                })
                .finally(() => {this.updatingUser=null})
        },
    }
}
</script>