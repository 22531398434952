import { floatingTest, fixLabelByTrimming } from "./common";

export const npsChartCreator = (obj, chartId) => {
  const backgroundColorList = [
    "#ea4b4b",
    "#b8e1e9",
    "#ffcfcf",
    "#1882a0",
    "#A1D2CE",
    "#48A9A6",
    "#a25a77",
    "#999999",
    "#575A4B",
  ];
  let receivedDatasets = [];

  // obj: {
  //   title: "Manager"
  //   data: {
  //     label: 'YES',
  //     dataValue: 50 -- nps percent -- ,
  //   }
  // }

  obj.data.forEach((item, index) => {
    // if (item.label.toUpperCase() === "YES") {
    //   item.label = "Manager";
    // } else if (item.label.toUpperCase() === "NO") {
    //   item.label = "Non-Manager";
    // } else if (item.label.toUpperCase() === "N_LESS_THAN_5") {
    //   return "N<5";
    // }
    item.label = fixLabelByTrimming(item.label);
    receivedDatasets.push({
      label: item.label,
      backgroundColor: backgroundColorList[index],
      borderColor: "black",
      borderWidth: 1,
      data:
        typeof item.dataValue === "array"
          ? [...item.dataValue]
          : [item.dataValue],
      type: "scatter",
      pointStyle: "circle",
      pointRadius: 15,
      datalabels: floatingTest,
    });
  });

  const data = {
    labels: ["NPS"],
    datasets: [
      ...receivedDatasets,

      // --------------------------------------------------
      // This is bar chart
      {
        label: "Organization Needs Improvement",
        backgroundColor: "#1863df",
        borderColor: "#1863df",
        data: [-100],
        barThickness: 100,

        // datalabels: labelHoverAbove,
        datalabels: {
          display: false,
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            let label = context.dataset.label;
            label = fixLabelByTrimming(label);
            return label;
          },

          color: "white",
          // font: {
          //   family: "Montserrat",
          //   weight: "bold",
          //   size: 20,
          // },
        },
      },
      {
        label: "Good",
        data: [50],
        backgroundColor: "#9F1D51",
        barThickness: 100,
        // datalabels: labelHoverBelow,
        datalabels: {
          display: false,
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            return context.dataset.label;
          },
          color: "white",
          // font: {
          //   family: "Montserrat",
          //   weight: "bold",
          //   size: 20,
          // },
        },
      },
      {
        label: "Great",
        data: [70],
        backgroundColor: "#E99B23",
        barThickness: 100,
        // datalabels: labelHoverBelow,
        datalabels: {
          display: false,
          anchor: "end",
          align: "start",
          offset: 30,
          // set formatter function
          formatter: function (value, context) {
            return context.dataset.label;
          },
          color: "white",
          // font: {
          //   family: "Montserrat",
          //   weight: "bold",
          //   size: 20,
          // },
        },
      },
      {
        label: "Excellent",
        data: [100],
        backgroundColor: "#ffeacf",
        barThickness: 100,
        // datalabels: labelHoverBelow,
        datalabels: {
          display: false,
          anchor: "end",
          align: "start",
          offset: 50,
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            return context.dataset.label;
          },
          color: "white",
          // font: {
          //   family: "Montserrat",
          //   weight: "bold",
          //   size: 20,
          // },
        },
      },
    ],
  };

  const options = {

    defaultFontFamily: 'Montserrat !important',
    font: {
      weight: "normal",
      style: 'normal',
    },
    animation: true,
    maintainAspectRatio: true,
    aspectRatio: 2.5,
    responsive: true,
    indexAxis: "y",

    // edit element type and make it smaller
    elements: {
      bar: {
        // borderWidth: 8,
        // inflateAmount: 25,
      },
    },

    plugins: {
      title: {
        display: true,
        color: "black",
        font: {
          family: "Montserrat",
          style: 'normal',
          weight: '400',
          size: 25,
        },
        text: obj.title,
      },
      legend: {
        reverse: true,
        labels: {
          color: "black",
          boxWidth: 20,
          font: {
            family: "Montserrat",
            weight: "normal",
            style: 'normal',
            size: 15,
          },
          // formatter: () => {
          //   return "Here is text";
          // },
          filter: function (item, chart) {
            // item.fillStyle = "red";
            item.text =
              item.text + ": " + chart.datasets[item.datasetIndex].data[0];
            return (
              !item.text.includes("Great") &&
              !item.text.includes("Excellent") &&
              !item.text.includes("Good") &&
              !item.text.includes("Organization Needs Improvement")
            );
          },
        },
      },
    },

    scales: {
      xline: {
        display: false,
        // this x-axis applies to the scatter chart
        y: {
          font: {
            // family: "Montserrat",
            weight: "bold",
            // size: 20,
          },

          min: -100,
          max: 100,
        },
        x: {
          font: {
            // family: "Montserrat",
            weight: "bold",
            // size: 20,
          },
          min: -100,
          max: 100,
        },
      },

      x: {
        min: -100,
        max: 100,
        stacked: true,

        ticks: {
          font: {
            family: "Montserrat",
            weight: "normal",
            style: 'normal',
            size: 18,
          },
          stepSize: 10,
          callback: function (value, index, values) {
            return value == -100 ||
              value == 0 ||
              value == 50 ||
              value == 70 ||
              value == 100
              ? value
              : "";
          },
          color: "black",
        },
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false,
        },
      },
      y: {
        ticks: {
          color: "black",
          font: {
            family: "Montserrat",
            weight: "normal",
            style: 'normal',
            size: 15,
          },
        },
        font: {
          family: "Montserrat",
          weight: "normal",
          style: 'normal',
          size: 15,
        },
        min: -100,
        max: 100,
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
    datalabels: {
      color: 'black'
    }
  };

  const npsChartConfig = {
    type: "bar",
    data: data,
    options: options,
    plugins: [ChartDataLabels]
  };

  let ctx = document.getElementById(chartId).getContext("2d");
  ctx.imageSmoothingEnabled = true;
  const npsChart = new Chart(ctx, npsChartConfig)
  // return npsChartConfig;
};


// async function createNPSChart(chartId){
//   const npsChart = new Chart(chartId, )
// }