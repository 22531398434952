<template>
    <div class="d-flex align-items-center justify-content-center" >
       <div style="width: 800px"> <canvas id="report"></canvas> </div>
    </div>
</template>

<script>
    import { ipReportCreator } from "@/assets/js/interestProfiler/reportChart"
import axios from "axios"
    export default{
        name: 'IPReport',
        data(){
            return {
                uniqueId: null,
                categories_average: {}
            }
        },
        mounted(){
            this.uniqueId = this.$route.params.uniqueId;
            this.getReportData();
        },
        methods: {
            async getReportData(){
                await axios
                    .get(`api/survey-response/${this.uniqueId}/render-report-data/`)
                    .then((resp) => {
                        console.log(resp.data.ca)
                        this.categories_average = resp.data.categories_average
                        this.setChart()
                    })
                    .catch(err => console.log(err))
            },
            
            setChart(){
                console.log(this.categories_average)
                ipReportCreator(this.categories_average, 'report')
            }
        }
    }

</script>