export const ipReportCreator = (obj, chartId) => {
    const xAxisFontSize = 14
    const yAxisFontSize = 14

    const data = {
        labels: obj.labels,
        datasets: [{
          label: '',
            data: obj.data,
            backgroundColor: [
            'rgba(255, 99, 132, 0.9)',
            'rgba(255, 159, 64, 0.9)',
            'rgba(255, 205, 86, 0.9)',
            'rgba(75, 192, 192, 0.9)',
            'rgba(54, 162, 235, 0.9)',
            'rgba(153, 102, 255, 0.9)',
            ],
            borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            ],
            borderWidth: 1
        }]
    }

   const options = {
      defaultFontFamily: 'Montserrat !important',
      font: {
        weight: "normal",
        style: 'normal',
      },

      plugins: {
        legend: {
          display: false,
          labels: {
            display: false,
            color: "black",
            font: {
              family: "Montserrat",
              weight: "normal",
              size: 20,
              style: 'normal',

            },
          }
        },
        datalabels: {
          display: true,
          color: "black",
        },
        title: {
          display: true,
          color: "black",
          font: {
            family: "Montserrat",
            style: 'normal',
            weight: '500',
            size: 25,
          },
          text: 'Your Report',
        },
      },

      scales: {
        x: {
          ticks: {
            color: "black",
            font: {
            family: "Montserrat", weight: "bold", size: xAxisFontSize, style:'normal'
            },
          },
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
        },
        y: {
          // min: 0,
          // max: 40,

          // stacked: false,
          ticks: {
            color: "black",
            stepSize: 5,
            font: {
            family: "Montserrat",
            weight: "bold",
            size: yAxisFontSize,
            style: 'normal',
            },
          },
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
        },
      },
  }

    const config = {
        type: 'bar',
        data: data,
        options: options,
    };

    const ctx = document.getElementById(chartId).getContext("2d");
    const chart = new Chart(ctx, config)
};