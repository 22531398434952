<template>
    <div class="card border-0 shadow">
        <div class="card-header border-bottom d-flex align-items-center justify-content-between">
            <h2 class="fs-5 fw-bold mb-0"> All Campaign Templates </h2>

            <a  v-if="creatorEnabled" data-bs-toggle="modal" data-bs-target="#modalCreateTemplate" class="btn btn-sm btn-tertiary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="icon icon-xxs me-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>
                Create Template
            </a>
        </div>

        <div class="modal fade" id="modalCreateTemplate" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModalCreateTemplate"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center fw-bolder text-tertiary pb-3 mb-3">Create Survey Template</h2>

                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="this.addTemplateForm.title" placeholder="name@example.com">
                    <label for="floatingInput">Survey Template Title</label>
                </div>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.addTemplateForm.active">
                    <label class="form-check-label">
                        Active
                    </label>
                </div>

                <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                  <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                    Cancel
                  </button>
                  <button class="btn btn-info" type="button" @click="createTemplate">
                     Take me to Template Design Page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div v-if="isLoading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
            <ul v-else class="list-group list-group-flush list my--3">
                <li v-if="campaignTemplates.length" v-for="campaignTemplate in campaignTemplates" class="list-group-item p-3 m-1 border" :key="campaignTemplate">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <p class="mb-0">
                                <a href="#"> {{ campaignTemplate.title }} </a>
                            </p>
                        </div>

                        <div class="col text-end">

                            <button @click="this.selectedTemplate={...campaignTemplate}" class="btn btn-sm btn-gray-700 d-inline-flex align-items-center me-2" data-bs-toggle="modal" data-bs-target="#modalEditTemplate">
                                <svg class="icon icon-xxs me-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/></svg>
                                Edit
                            </button>

                            <button v-if="creatorEnabled" class="btn btn-sm btn-secondary d-inline-flex align-items-center me-2" data-bs-toggle="modal" data-bs-target="#modalDownloadReport">
                                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="icon icon-xxs me-2" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/></svg>
                                <router-link target="_blank" v-bind:to="`/survey-creator/${campaignTemplate.id}`" >Design</router-link>
                            </button>

                        </div>
                    </div>
                </li>

                <li v-else class="list-group-item text-center text-danger fw-bolder">
                    You Don't have any campaign templates!
                </li>
            </ul>
        </div>

        <div class="modal fade" id="modalEditTemplate" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModalEditTemplate"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Edit Survey Template</h2>
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="this.selectedTemplate.title" placeholder="name@example.com">
                    <label for="floatingInput">Survey Template Title</label>
                </div>

                <br>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="this.selectedTemplate.active">
                    <label class="form-check-label">
                        Active
                    </label>
                </div>

                <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                  <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModalEditTemplate" >
                    Cancel
                  </button>
                  <button class="btn btn-primary" type="button" @click="updateTemplate">
                    Update Template
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'CampaignTemplates',
        data(){
            return{
              campaignTemplates: [],
              selectedTemplate: {},
              creatorEnabled: false,
              addTemplateForm: {
                title: null,
                active: false
              },
              isLoading: true,
                // selectedReportData: []
            }
        },
        beforeMount(){
            this.getCampaignTemplates()
        },
        mounted(){
            console.log(this.selectedTemplate)
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },

        methods:{
          async getCampaignTemplates(){
            console.log("getting the campaign templates list")
            this.isLoading = true
            await axios
                .get("api/survey-templates-list/")
                .then((response) => {
                  this.campaignTemplates = response.data.results;
                  console.log(this.campaignTemplates)
                })
                .catch((error) => {
                  console.error("failed to fetch the templates list")
                  console.log(error);
                  this.$toast.error(`Failed to load campaign templates`);
                });
            this.isLoading = false
          },

          async updateTemplate(){
            await axios
                .put("api/survey-template/"+ this.selectedTemplate.id +"/", this.selectedTemplate)
                .then((response) => {
                    console.log("survey template submitted, response status:" + response.status)
                    this.$refs.closeModalEditTemplate.click()
                    this.getCampaignTemplates()
                    this.$toast.success("Saved successfully")
                })
                .catch(error =>{
                    this.$toast.error("Failed to save")
                    console.log(error);
                });
          },

          async createTemplate(){
            if (this.addTemplateForm.title === '' || this.addTemplateForm.title === null){
                this.$toast.error("Please enter a template title")
                return
            }

            await axios
                .post('api/survey-templates-list/', this.addTemplateForm)
                .then((response) => {
                    let createdTemplateId = response.data.id
                    this.$refs.closeModalCreateTemplate.click()
                    const url = this.$router.resolve(`/survey-creator/${createdTemplateId}`).href;
                    window.open(url, '_blank');
                })
                .catch(error => console.log(error))
          }

        }
    }
</script>