<template>
    <div class="card border-0 shadow">
        <div class="card-header border-bottom d-flex align-items-center justify-content-between">
            <h2 class="fs-5 fw-bold mb-0"> All Clients </h2>
            <a data-bs-toggle="modal" data-bs-target="#modalAddClient" class="btn btn-sm btn-tertiary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="icon icon-xxs me-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>
                Add Client
            </a>
        </div>

        <div class="modal fade" id="modalAddClient" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="addClientCloseModal"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center mb-4">Add Client</h2>
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" v-model="addClient.name" required>
                        <label for="floatingInput">Client Name</label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="addClient.active">
                        <label class="form-check-label" for="defaultCheck10">
                            Active
                        </label>
                    </div>

                    <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                    <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                        Cancel
                    </button>
                    <button class="btn btn-primary" @click="addClientFunc" >
                        Add Client
                    </button>
                    </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
            <ul class="list-group list-group-flush list my--3">
                <li v-for="org in organizations" class="list-group-item p-3 m-1 border d-flex align-items-center justify-content-between" :key="org">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <h2 class="h5 mb-0 bold">
                                <a href="#"> {{ org.name }} </a>
                            </h2>
                            <div v-if="org.active" class="d-flex align-items-center">
                                <div class="bg-success dot rounded-circle me-1"></div>
                                <small>Active</small>
                            </div>

                            <div v-else class="d-flex align-items-center">
                                <div class="bg-danger dot rounded-circle me-1"></div>
                                <small>Inactive</small>
                            </div>
                        </div>
                    </div>
                    <div v-if="updatingId === org.id" class="d-flex align-items-center">
                        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                    </div>
               
                    <div v-else class="form-check form-switch">
                        <input class="form-check-input" v-model="org.active" type="checkbox" role="switch" @change="updateActive(org.id, org)" id="flexSwitchCheckDisabled" >
                    </div>


                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default{
        name: 'ManageClient',
        data(){
            return{
                organizations: [],
                addClient: {
                    name: '',
                    active: false
                },
                selectedClient: {},

                updatingId: null
            }
        },
        mounted(){
            this.getOrganizations()
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },
        
        methods: {
            async getOrganizations(){
                await axios
                    .get("api/all-organizations-list/?limit=200")
                    .then((response) => {
                        this.organizations = response.data.results
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            async addClientFunc(){
                await axios
                    .post("api/organization-list/", this.addClient)
                    .then((response) => {
                        if(response.status === 201){
                            this.getOrganizations()
                            this.addClient = {}
                            this.$refs.closeModal.click()
                            this.$toast.success(`Client created successfully`);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(`Failed to create client`);
                    });
            },

            async updateActive(id, org){
                this.updatingId = id;

                axios
                    .post(`api/organization-list/${id}/update-active/`)
                    .then((resp) => {
                        if (resp.status === 201){
                            this.getOrganizations()
                        }
                    })
                    .catch((error) => {
                        org.active = !org.active
                        this.$toast.error(error.data)
                    })
                    .finally(() => {this.updatingId=null})
            },

            getClient(client){
                this.selectedClient = client
            }
        }
    }
</script>