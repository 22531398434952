<template>
    <div class="card border-0 shadow mb-4">
      <div class="card-body">
        <!-- <SectionSpecificFilter v-if="userPermissions.canDoSectionSpecificFilters" :section="this.section"/> -->
        <!-- <h2 class="text-center fw-normal mx-8" @blur="this.updateTitle" :contenteditable="section.duplicate === 1">{{ this.section1.title }}</h2> -->
        <h2 class="text-center fw-normal mx-8">{{ this.section1.title }}</h2>
        <h3 v-if="this.type==='srf'" class="text-center fw-normal mt-3">Factors that most contribute to staff retention:</h3>
        <h3 v-else-if="this.type==='saf'" class="text-center fw-normal mt-3">Factors that most contribute to staff attrition:</h3>
  
        <!-- <button
          v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
          type="button"
          class="btn btn-sm"
          title="Delete Page"
          @click="this.deletePage"
          style="position: absolute; top: 1.5em; right: 2.5em; "
      >
          <span class="fs-3 me-1 fw-bolder text-danger">X</span>
      </button>
  
        <div style="width: 7%; position: absolute; top: 5.5em; right: 8em;">
          <multiselect v-if="this.type === 'srf' && !applyingFilters" @select="update" v-model="this.$store.state.sections[this.index].filters.display_filters.display_count_srf" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select a Number"></multiselect>
          <multiselect v-else-if="this.type === 'saf' && !applyingFilters" @select="update" v-model="this.$store.state.sections[this.index].filters.display_filters.display_count_saf" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select a Number"></multiselect>
              <div v-if="applyingFilters" class="d-flex align-items-center justify-content-center">
                  <div class="spinner-border text-grey ms-auto" aria-hidden="true"></div>
              </div>                
        </div>
  
        <button
          v-if="userPermissions.canAddUpdateCustomReport"
          type="button"
          class="btn me-1"
          title="Set Demographics"
          @click="this.duplicatePage"
          style="position: absolute; top: 6em; right: 1.5em;"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
      </button> -->
  
        <div class="mx-8 mt-4">
          <!-- <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
              <hr>
              <span class="text-warning">Section Specific Filters:  </span>
              <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
                  <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
                  <span class="text-decoration-none">{{ value.join(', ') }}</span>
                  <br><br>
              </div>
          </div> -->
          <p class="edgility-gray lh-sm text-center">The following factors most likely contribute <strong>negatively</strong> to current staff retention.
          </p> <br>
        </div>

        <hr>

        <!-- For Survey 1  -->
        <p class="fw-bolder fs-4" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title1 }}
        </p>
        <br>

        <div class="text-center mx-6">
            <canvas :id="'srf' + this.section1.order + 1" />
        </div>
        <br>

        <!-- For Survey 2 -->
        <p class="fw-bolder fs-4" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title2 }}
        </p>
        <br>

        <div class="text-center mx-6">
            <canvas :id="'srf' + this.section2.order + 2" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
      import { stackedBarConfig } from '@/assets/js/srfChart'
      import axios from 'axios'
    //   import SectionSpecificFilter from './SectionSpecificFilter.vue'
  
      export default{
          name: "ComparativeStaffAttrition",
          props: ['type', 'section1', 'section2'],
  
          data(){
              return {
                  applyingFilters: false
              }
          },  
  
          computed: {
              userPermissions(){
                  return this.$store.state.userPermissions
              },
            //   index(){
            //       return this.$store.state.sections.indexOf(this.section)
            //   },
              id(){
                  if (this.type === 'srf'){
                      return "srfRetention"
                  }
  
                  else if (this.type === 'saf'){
                      return "srfAttrition"
                  }
              }
          },
  
          errorCaptured(err, instance, info){
              this.$toast.error("Something Went Wrong! Please see log for more information.");
              console.error(err)
              this.$router.go(-1)
              return false
          },
  
          components: {
            //   SectionSpecificFilter
          },
  
          methods: {
            //   async update(){
            //       var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
            //       const config = {
            //           params: this.$store.getters.params
            //       };
            //       this.$toast.info("Filters are being Applied")
            //       this.applyingFilters = true
            //       await axios
            //       .get(url, config)
            //       .then((response) => {
            //           if (response.status === 200){
            //               this.$store.commit('setReportData', response.data)
            //             this.$toast.info(`Filters applied successfully`);
            //           }
            //           else{
            //             this.$toast.warning(`No Responses Found for this Filter`);
            //           }
            //       })
            //       .catch((error) => {console.log(error); })
            //       .finally(() => {this.applyingFilters=false})
  
            //   },
            //   updateTitle(event){
            //       if (event.target.textContent == '' || event.target.textContent.trim() == ''){
            //           this.$toast.error(`Please Provide a Valid Name`);
            //           return
            //       }
            //       this.$store.state.sections[this.index].title = event.target.textContent
            //   },
            //   duplicatePage(){
            //       this.$emit('duplicatePage', this.index)
            //   },
  
            //   deletePage(){
            //       this.$emit('deletePage', this.index)
            //   }
          },
  
          mounted(){
              var labels1 = []
              var positive1 = []
              var negative1 = []
              var neutral1 = []
  
              var factors1 = []
              var count1 = 17


              var labels2 = []
              var positive2 = []
              var negative2 = []
              var neutral2 = []
  
              var factors2 = []
              var count2 = 17
  
              console.log(this.section)
  
              if (this.type === 'srf'){
                  console.log("entered srf")
                  factors1 = this.section1.retention_factors.top_retentions
                  count1 = this.section1.filters.display_filters.display_count_srf


                  factors2 = this.section2.retention_factors.top_retentions
                  count2 = this.section2.filters.display_filters.display_count_srf
              }
  
              else if(this.type === 'saf'){
                  console.log('Entered saf')
                  factors1 = this.section1.retention_factors.top_attritions
                  count1 = this.section1.filters.display_filters.display_count_saf


                  factors2 = this.section2.retention_factors.top_attritions
                  count2 = this.section2.filters.display_filters.display_count_saf
              }
  
              let attritions_string1 = JSON.stringify(factors1)
              let attrition_factors1 = JSON.parse(attritions_string1)

              let attritions_string2 = JSON.stringify(factors2)
              let attrition_factors2 = JSON.parse(attritions_string2)

              for (var page of attrition_factors1.slice(0, count1)){
                  for (var factor of page){
                      labels1.push(factor.retention_type)
                      positive1.push(parseInt(factor.percentages.positive))
                      negative1.push(parseInt(factor.percentages.negative))
                      neutral1.push(parseInt(factor.percentages.neutral))
                  }
              }


              for (var page of attrition_factors2.slice(0, count2)){
                  for (var factor of page){
                      labels2.push(factor.retention_type)
                      positive2.push(parseInt(factor.percentages.positive))
                      negative2.push(parseInt(factor.percentages.negative))
                      neutral2.push(parseInt(factor.percentages.neutral))
                  }
              }
  
              stackedBarConfig({
                  data: {
                      labels: labels1,
                      positive: {
                          dataValues: positive1,
                      },
                      neutral: {
                          dataValues: neutral1,
                      },
                      negative: {
                          dataValues: negative1,
                      },
                  }
              }, 'srf' + this.section1.order + 1)


              stackedBarConfig({
                  data: {
                      labels: labels2,
                      positive: {
                          dataValues: positive2,
                      },
                      neutral: {
                          dataValues: neutral2,
                      },
                      negative: {
                          dataValues: negative2,
                      },
                  }
              }, 'srf' + this.section2.order + 2)
          }
      }
  </script>