<template>
        <hr />
        <p class="fs-5 fw-bolder">Compare the current survey with other survey:</p>
        <div class="d-flex justify-content-between m-3 align-items-center">
            <div class="w-75">
                <div class="d-flex flex-row align-items-center justify-content-around w-75">
                    <div class="fw-bolder">Select Survey to compare:</div>
                    <multiselect style="width: 300px;" v-model="selected_survey" @select="this.changeChart()" :options="surveys" track-by="id" label="title" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Survey"></Multiselect>
                </div>
            </div>

            <div>
                <input class="form-check-input me-3" type="checkbox" v-model="compare_surveys" @change="compareChart" :disabled="selected_survey === null">
                <label class="form-check-label fw-bolder" for="defaultCheck10">
                    Compare Surveys
                </label>
            </div>
        </div>
        <!-- <p>Hello</p> -->

</template>


<script>
import axios from 'axios'

    export default {
        name: "ComparativeChart",
        data(){
            return {
                surveys: [],
                selected_survey: null,
                compare_surveys: false
            }
        },
        mounted(){
            console.log(this.surveys)
            console.log(this.$store.state.selectedSurveyID)
            this.getSurveys()
        },
        methods: {
            async getSurveys(){
                await axios
                    .get(`api/related-surveys/${this.$store.state.selectedSurveyID}/`)
                    .then(resp => this.surveys = resp.data)
                    .catch(err => console.log(err))
            },

            async changeChart(){
                console.log("entered change")
                if (this.compare_surveys)
                    this.compareChart()
            },

            async compareChart(){
                if (!this.compare_surveys){
                    this.$store.state.compareSurveys = false
                    return
                }

                this.$store.commit('setIsLoading', true)
                await axios
                    .get(`api/render-comparative-report-data/?id1=${this.$store.state.selectedSurveyID}&id2=${this.selected_survey.id}`)
                    .then(resp => {
                        if (resp.status === 200){
                            this.$store.commit('setComparativeCharts', {data1: resp.data.data1, data2: resp.data.data2})
                            this.$store.commit("setComparativeChartsTitles", {title1: resp.data.titles.title1, title2: resp.data.titles.title2})
                            this.$store.state.compareSurveys = true;
                        }
                        else if (resp.status === 204) {
                            this.$toast.warning("No response found for the Survey that you are trying to compare")
                        }
                    })
                    .catch(err => {
                        // this.$toast.error(err.data)
                    })
                this.$store.commit('setIsLoading', false)
                console.log(this.$store.state.comparativeChart1)
            }
        }

    }

</script>