<template xmlns="http://www.w3.org/1999/html">
  <div class="bg-white rounded shadow p-4 mb-4 mt-4">
    <div
    v-if="this.$store.state.hasReportData"
    style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
    >
      <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent" style="margin: 0;">
          <li class="breadcrumb-item"><a href="#" style="font-weight: bold; font-size: 20px;">{{ this.$store.state.reportData.survey_data.client_name }}</a></li>
          <li class="breadcrumb-item active" aria-current="page" style="font-size: 20px;">
            {{ this.$store.state.reportData.survey_data.campaign_name  }}
          </li>
        </ol>
      </nav>


      <div v-if="userPermissions.canAddUpdateCustomReport" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 50%">
      <multiselect @remove="openAddReportModal" @select="openAddReportModal" v-model="selectedReport" :options="reports" track-by="title" label="title" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select Report or Create One"></Multiselect>
      <button data-bs-toggle="modal" data-bs-target="#modalUpdateReport" class="btn btn-md btn-tertiary ms-2" style="width:45%" :disabled="showUpdateButton"> Update Report </button>
      </div>

      <div class="modal fade" id="modalAddReport" tabindex="-1" role="dialog" aria-hidden="false">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="addCustomReportCloseModal"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center mb-4">Add Custom Report</h2>
                    <div class="form-floating mb-3">
                        <input v-model="addReportData.title" type="text" class="form-control" id="customReportTitle" required>
                        <label for="customReportTitle">Please enter a name for the custom report</label>
                    </div>

                    <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                    <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                        Cancel
                    </button>
                    <button @click="addReport" class="btn btn-primary" :disabled="updatingReport">
                      <div v-if="updatingReport" class="d-flex align-items-center">
                        <span role="status" class="me-2">Adding Custom Report</span>
                        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                      </div>
                      <span v-else>
                        Add Custom Report
                      </span>
                    </button>
                    </div>
              </div>
            </div>
          </div>
        </div>

      <div class="modal fade" id="modalUpdateReport" tabindex="-1" role="dialog" aria-hidden="false">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="updateCustomReportCloseModal"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center mb-4">Update Your Report</h2>
                    <div class="form-floating mb-3">
                        <input v-model="selectedReport.title" type="text" class="form-control" id="floatingInput" required>
                    </div>

                    <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                    <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                        Cancel
                    </button>
                    <button @click="updateReport" class="btn btn-primary" :disabled="updatingReport">
                      <div v-if="updatingReport" class="d-flex align-items-center">
                        <span role="status" class="me-2">Updating Report</span>
                        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                      </div>
                      <span v-else>
                        Update Custom Report
                      </span>
                    </button>
                    </div>
              </div>
            </div>
          </div>
      </div>

      <button
          v-if="userPermissions.canDoGlobalFilters"
          type="button"
          class="btn me-1"
          title="Global Filter "
          data-bs-toggle="modal"
          data-bs-target="#modalFilterDemographic"
          @click="setGlobalFilters"
      >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16"> <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/> </svg>
      </button>
    </div>


    <div>
      <div v-if="Object.keys(this.$store.state.appliedFilters).length > 0" :key="appliedFilters">
        <hr>
        <span class="text-warning">Global Filters:  </span>
        <div v-for="(value, key) in appliedFilters">
          <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
          <span class="text-decoration-none">{{ value.join(', ') }}</span>
        </div>
      </div>
    </div>

    <br>
    <ComparativeChart :surveys="surveys" v-if="tenantFeatures.comparativeCharts || userFeatures.comparativeCharts" />
  </div>

  <div class="modal fade" id="modalFilterDemographic" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
        </div>
        <div class="modal-body px-md-5">
          <h2 class="h5 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Filter by Demographic Type and Values</h2>

          <div>
          <label class="typo__label">Select Demographic Type</label>
            <multiselect id="demographicType" @select="getPossibleValuesForDemographicType" track-by="enumValue"
                         label="displayName" v-model="selectedDemographicType" :options="demographicTypeOptions"
                         :searchable="false" :close-on-select="true" :show-labels="false"
                         placeholder="Select Demographics" :disabled="applyingFilters"></Multiselect>
          <br>

          <label class="typo__label">Select Demographic Values</label>
            <multiselect ref="demographicValues" v-model="selectedDemographicValues"
                         :options="demographicValuesOptions" :multiple="true" :close-on-select="false"
                         :clear-on-select="false" :preserve-search="true" placeholder="Pick Demographic Values"
                         :preselect-first="false" :disabled="applyingFilters"></multiselect>
          </div>
          <br>

          <div class="d-flex align-items-center justify-content-end gap-4">
            <button class="btn btn-secondary" type="button" @click="addFilter" :disabled="applyingFilters">
              Add Filter
            </button>
          </div>

          <br>
            <p v-for="(value, key) in this.$store.state.global_filters" class="p-3 m-1 border" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <span class="text"><span class="text fw-bolder"> {{ key.toUpperCase() }}: </span> <br>  <span v-for="val in value" class="badge bg-gray-600 me-2">{{ val }} </span> </span>
            <button class="btn btn-sm btn-danger" type="button" @click="removeFilter(key)" :disabled="applyingFilters"> Remove </button>
            </p>

          <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
            <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
              Cancel
            </button>
            <button class="btn btn-primary" type="button" @click="applyFilters" :disabled="applyingFilters">
              <div v-if="applyingFilters" class="d-flex align-items-center">
                <span role="status" class="me-2">Applying Filters</span>
                <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
              </div>
              <span v-else>
                Apply Filters
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapState } from 'vuex';
    import ComparativeChart from './ComparativeChart.vue';

    export default {
        name: 'GlobalFilters',
        components: {
          ComparativeChart
        },
        props: ['surveys'],

        data(){
          return {
            demographicTypeOptions: [],
            selectedDemographicType: '',
            demographicValuesOptions: [],
            selectedDemographicValues: [],

            reports: [{id: 0, title:'Create New Report'}],
            selectedReport: '',
            isDisabled: true,

            applyingFilters: false,
            updatingReport: false,

            addReportData: {
              title: '',
              data: this.$store.getters.params
            }
          }
        },

        computed: {
          ...mapState(['appliedFilters', 'tenantFeatures', 'userFeatures']),
          userPermissions(){
            return this.$store.state.userPermissions
          },
          showUpdateButton(){
            if (this.selectedReport === '' || this.selectedReport === null || this.selectedReport.id === 0 ){
              this.selectedReport = ''
              return true;
            }
            return false
          }
        },

        mounted(){
          console.log(this.appliedFilters)
          if (this.userPermissions.canAddUpdateCustomReport){
            this.getCustomReports()
          }
        },

        methods: {
          async setGlobalFilters(){
            if (this.demographicTypeOptions.length > 0){
              return
            }
            let url = `api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-types/`

            await axios
              .get(url)
              .then((response) => {
                  response.data.forEach((obj) => {
                      this.demographicTypeOptions.push({
                          displayName: obj['display_name'],
                          enumValue: obj['enum_value'],
                      })
                  })
              })
              .catch(err => console.log(err))
        },

          async getPossibleValuesForDemographicType(){
            this.selectedDemographicValues = []
            let url = `api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-values-for-type/`
            let params = {
                'demographic': this.selectedDemographicType.enumValue
            }

            const config = {
                params: params
            };

            await axios
              .get(url, config)
              .then((response) => {
                  this.demographicValuesOptions = response.data
              })
          },

          addFilter(){
            if (!Boolean(this.selectedDemographicType)){
              this.$toast.error(`Please select a demographic type`);
              return;
            }
            if (this.selectedDemographicValues.length === 0){
              this.$toast.error(`Please select one or more demographic value`);
              return;
            }
            let str = JSON.stringify(this.selectedDemographicValues)
            let parsed = JSON.parse(str)
            this.$store.state.global_filters[this.selectedDemographicType['enumValue']] = parsed
            this.selectedDemographicType = ''
            this.selectedDemographicValues = []
          },

          removeFilter(key){
            delete this.$store.state.global_filters[key]
          },

          async applyFilters(){
            if (this.$store.state.global_filters.length === 0){
              this.$toast.warning(`No filters to apply`);
              return
            }
            this.applyingFilters = true

            var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
            const config = {
              params: this.$store.getters.params
            };

            this.$store.commit('setIsLoading', true)

            await axios
              .get(url, config)
              .then((response) => {
                if (response.status === 200){
                  this.$store.commit('setReportData', response.data)
                  this.$refs.closeModal.click()
                  this.selectedDemographicType = ''
                  this.selectedDemographicValues = []
                  this.$toast.info(`Filters applied successfully`);
                  this.$store.commit('applyFilters')
                }
                else{
                  this.$toast.warning(`No Responses Found for this Filter`);
                }
              })
              .catch((error) => {
                console.error(error)
                this.$toast.error(`Failed to prepare the report`);
              })
              .finally(() => {this.applyingFilters=false})
            this.$store.commit('setIsLoading', false)
          },

          async getCustomReports(){
            await axios
              .get(`api/survey/${this.$store.state.selectedSurveyID}/custom-reports/`)
              .then((resp) => {
                this.reports = [{id: 0, title: 'Create New Report'}]
                if (resp.data){
                    resp.data.results.forEach((report) => {
                    this.reports.push({
                      id: report.id,
                      title: report.title,
                      data: report.data
                    })
                  })
                }
              })
          },
          async removeCustomFilters(){

          },

          async openAddReportModal(){
            let config = {}
            console.log(this.selectedReport)

            if (this.selectedReport === '' || this.selectedReport === null || this.selectedReport === undefined){
              this.selectedReport = ''
            }

            else if (this.selectedReport.title === 'Create New Report'){
              this.selectedReport = ''
              $("#modalAddReport").modal("show");
              return
            }


            else {
              let data = JSON.parse(this.selectedReport.data)

              config = {
                params: data
              };
            }

            var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";

            this.$store.commit('setIsLoading', true)

            await axios
              .get(url, config)
              .then((response) => {
                if (response.status === 200){
                  this.$store.commit('setReportData', response.data)
                  this.$refs.closeModal.click()
                  this.selectedDemographicType = ''
                  this.selectedDemographicValues = []
                  this.$toast.info(`Filters applied successfully`);
                }
                else{
                  this.$toast.warning(`No Responses Found for this Filter`);
                }
              })
              .catch((error) => {
                console.error(error)
                this.$toast.error(`Failed to prepare the report`);
              });
            this.$store.commit('setIsLoading', false)

          },

          async addReport(){

            if (this.addReportData.title === ''){
              this.$toast.error(`Please provide Report Name`);
              return
            }

            this.updatingReport = true
            this.addReportData.data = JSON.stringify(this.$store.getters.params)

            await axios
              .post(`api/survey/${this.$store.state.selectedSurveyID}/custom-reports/`, this.addReportData,)
              .then((resp) => {
                if (resp.status === 201){
                  this.selectedReport = resp.data
                  this.getCustomReports()
                  this.$refs.addCustomReportCloseModal.click()
                  this.$toast.success(`Report created successfully`);
                }
              })
              .catch((err) => {
                if (err.response.status === 400){
                  this.$toast.error(`Report with this name already exists`);
                }
                else{
                  this.$toast.error(`Failed to create the report`);
                }
              })
              .finally(() => {this.updatingReport = false})

          },

          async updateReport(){
            this.updatingReport = true
            let id = this.selectedReport.id
            let data = JSON.stringify(this.$store.getters.params)

            await axios
              .put(`api/custom-reports/${id}/`, {title: this.selectedReport.title, data: data})
              .then((resp) => {
                if (resp.status === 200){
                  this.$toast.success(`Report Updated Successfully`);
                  this.$refs.updateCustomReportCloseModal.click()
                  this.getCustomReports()
                }
              })
              .catch(err => {
                    this.$toast.error(`Failed to update report`);
                    console.log(err);
                  }
              )
              .finally(() => {this.updatingReport = false})

          }
        },
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
