<template>
  <div class="card border-0 shadow-md mb-4">
  <div class="card-body">
    <SectionSpecificFilter v-if="userPermissions.canDoSectionSpecificFilters"  :section="this.section" />

    <button
      v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
      type="button"
      class="btn btn-sm"
      title="Delete Page"
      @click="this.deletePage"
      style="position: absolute; top: 1.5em; right: 2.5em; "
    >
      <span class="fs-3 me-1 fw-bolder text-danger">X</span>
    </button>

    <TVPFilter  :section="this.section" />

    <button
        v-if="userPermissions.canAddUpdateCustomReport"
        type="button"
        class="btn me-1"
        title="Duplicate this page"
        @click="this.duplicatePage"
        style="position: absolute; top: 10.5em; right: 1.5em;"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
    </button>

    <h2 class="text-center fw-normal mx-8" @blur="this.updateTitle" :contenteditable="section.duplicate === 1">{{ this.$store.state.sections[this.index].title }}</h2>
    <div class="mx-7">
      <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
          <hr>
          <span class="text-warning">Section Specific Filters:  </span>
          <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
            <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
            <span class="text-decoration-none">{{ value.join(', ') }}</span>
            <br><br>
          </div>
      </div>
      <p class="edgility-gray lh-sm">Staff experience these three areas of your total value organization most similarly, regardless of their personal identity. Groups with fewer than 5 respondents are not represented to ensure anonymity.
      </p> <br>
      <br>
    </div>

    <div class="text-center mx-4">
      <div class="chart-row">
          <div v-for="variance in highest_variance" class="chart-insert-nps">
            <canvas :id="variance.chart_id + this.section.order" src="" style="width: 100%; max-width: 1200px; max-height: 300px;"/>
          </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
    import { tvpVarianceConfigCreator } from '@/assets/js/tvpCharts'
    import SectionSpecificFilter from './SectionSpecificFilter.vue'
    import TVPFilter from './TVPFilter.vue'
    export default{
        props: ['section'],
        name: "TVPHighVariance",
        data(){
            return{
              highest_variance: this.section.highest_variance
            }
        },

        computed:{
          userPermissions(){
            return this.$store.state.userPermissions
          },
          index(){
            return this.$store.state.sections.indexOf(this.section)
          }
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },

        components:{
          TVPFilter,
          SectionSpecificFilter
        },

        mounted(){
            let highVarianceString = JSON.stringify(this.highest_variance)
            let highestVariances = JSON.parse(highVarianceString)
            highestVariances.forEach(variance => {
                tvpVarianceConfigCreator({
                    title: variance.title,
                    data: variance.data
                }, variance.chart_id + this.section.order)
            })
        },
        methods: {
          updateTitle(event){
              if (event.target.textContent == '' || event.target.textContent.trim() == ''){
                  this.$toast.error(`Please Provide a Valid Name`);
                  return
              }
              this.$store.state.sections[this.index].title = event.target.textContent
          },
          duplicatePage(){
              this.$emit('duplicatePage', this.index)
          },

          deletePage(){
              this.$emit('deletePage', this.index)
          }
        }
    }
</script>