<template>
  <div id="survey"/>
</template>

<script>
import "survey-core/defaultV2.min.css";
import axios from "axios";
import {Survey} from "survey-knockout-ui";
import {StylesManager} from "survey-core";

StylesManager.applyTheme("defaultV2");

export default {
  name: 'surveyPage', mounted() {
    this.loadSurvey();
  }, data() {
    return {
      surveyJson: {}
    }
  }, methods: {

    escapeJSON(json) {
      var escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
      var meta = {    // table of character substitutions
        '\b': '\\b', '\t': '\\t', '\n': '\\n', '\f': '\\f', '\r': '\\r', '"': '\\"', '\\': '\\\\'
      };

      escapable.lastIndex = 0;
      return escapable.test(json) ? '"' + json.replace(escapable, function (a) {
        var c = meta[a];
        return (typeof c === 'string') ? c : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
      }) + '"' : '"' + json + '"';
    }, async loadSurvey() {

      var token = localStorage.getItem("token");
      var token_map = JSON.parse(token);
      const config = {
        headers: {Authorization: `Bearer ${token_map.access}`},
      };
      const surveyID = this.$route.params.survey_id;
      console.log("surveyID is: "+ surveyID)
      this.surveyJson = await axios
          .get("api/survey/" + surveyID + "/", config)
          .then((response) => {

            // this.surveyJson = JSON.parse(response.data.data);
            this.surveyJson = JSON.parse(this.escapeJSON(response.data.data));
            const survey = new Survey(this.surveyJson);
            survey.render("survey");
          })
          .catch((error) => {
            console.log(error);
          });
      console.log(this.surveyJson)
    }
  }
}
</script>