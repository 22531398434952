<template>
    <div class="card border-0 shadow mb-4">
        <!-- <button
            v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
            type="button"
            class="btn btn-sm"
            title="Delete Page"
            @click="this.deletePage"
            style="position: absolute; top: 1.5em; right: 2.5em; "
        >
            <span class="fs-3 me-1 fw-bolder text-danger">X</span>
        </button> -->
    <div class="card-body">

        <!-- <button

            type="button"
            class="btn me-1"
            title="Set Demographics"
            data-bs-toggle="modal"
            :data-bs-target="`#modalDemographicCharts${section.order}`"
            @click="this.setDemographicTypes"
            style="position: absolute; top: 6em; right: 1.5em;"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"><path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/></svg>
        </button> -->

        <!-- <button
            v-if="userPermissions.canAddUpdateCustomReport"
            type="button"
            class="btn me-1"
            title="Duplicate this page"
            @click="this.duplicatePage"
            style="position: absolute; top: 10.5em; right: 1.5em;"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
        </button> -->

        <!-- <div
            class="modal fade"
            :id="`modalDemographicCharts${section.order}`"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
        > -->
        <!-- <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header border-0">
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref="closeModal"
                ></button>
            </div>
            <div class="modal-body px-md-5">
                <h2 class="h4 text-center">Select Demographics</h2>
                <label class="typo__label">Select Demographics</label>
                <multiselect ref="demographicChats" track-by="enumValue" label="displayName"
                             v-model="this.$store.state.sections[this.index].filters.display_filters.display_charts"
                             :options="this.displayFiltersDemographicTypeOptions" :multiple="true"
                             :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                             placeholder="Pick some" :preselect-first="false" :disabled="applyingFilters"></multiselect>

                <div
                    class="d-flex align-items-center justify-content-end gap-4 mt-4"
                >
                <button class="btn btn-outline-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ref="closeModal"
                > Cancel </button>
                <button class="btn btn-primary" type="button" @click="this.applyFilter" :disabled="applyingFilters">
                    <div v-if="applyingFilters" class="d-flex align-items-center">
                        <span role="status" class="me-2">Applying Filters</span>
                        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                    </div>
                    <span v-else>
                        Apply Filters
                    </span>
                </button>
                </div>
            </div>
            </div>
        </div> -->
        <!-- </div> -->

        <!-- <SectionSpecificFilter v-if="this.userPermissions.canDoSectionSpecificFilters" :section="this.section" /> -->

        <h2 class="text-center fw-normal mx-8">{{ this.section1.title }}</h2>
        <div class="mx-8">
          <!-- <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
            <hr>
            <span class="text-warning">Section Specific Filters:  </span>
            <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
              <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
              <span class="text-decoration-none">{{ value.join(', ') }}</span>
              <br><br>
            </div>
          </div> -->
          <p class="edgility-gray lh-sm">The following outlines the composition of survey participants broken down by demographic group.
          <br>Groups with fewer than 5 respondents are not represented to ensure anonymity.</p>
        </div>

        <br>
        <div class="mx-8">
            <hr>
            <div class="d-flex align-items-center justify-content-around flex-row fw-bolder">
                <p class="fs-4 fw-bolder" style="max-width: 50%;">{{ this.$store.state.comparativeSurveyTitles.title1 }}</p>
                <p class="fs-4 fw-bolder" style="max-width: 50%;">{{ this.$store.state.comparativeSurveyTitles.title2 }}</p>
            </div>
            <hr>
            <br>
            <div v-for="(row, index) in breakdown1">
                <div v-if="breakdown1[index] !== null || breakdown2[index] !== null" class="chart-insert22">

                    <div v-if="breakdown1[index] !== null" class="chart-canvas-parent">
                        <canvas :id="'chart1' + breakdown1[index].index + this.section1.order" style="height: 300px; width: 300px"></canvas>
                    </div>
                    <div v-else class="chart-canvas-parent">
                        <div class="d-flex justify-content-center align-items-center" style="height: 300px; width: 300px">
                            <p class="fw-bolder">
                                {{ breakdown2[index].break_down.title }} falls under n less than 5
                            </p>
                        </div>
                    </div>

                    <div v-if="breakdown2[index] !== null" class="chart-canvas-parent">
                        <canvas :id="'chart2' + breakdown2[index].index + this.section2.order" style="height: 300px; width: 300px"></canvas>
                    </div>
                    <div v-else  class="chart-canvas-parent">
                        <div class="d-flex justify-content-center align-items-center" style="height: 300px; width: 300px">
                            <p class="fw-bolder">
                                {{ breakdown1[index].break_down.title }} falls under n less than 5
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import { doughnutConfigCreator } from '@/assets/js/dougnut';
    import axios from 'axios';
    // import SectionSpecificFilter from './SectionSpecificFilter.vue';
    import { mapState } from 'vuex';

    export default {
        name: "ComparativeParticipantsBreakdown",
        props: ['section1', 'section2'],
        data(){
            return {
                breakdown1: this.section1.participants_break_down_for_frontend.flat(1),
                breakdown2: this.section2.participants_break_down_for_frontend.flat(1),
                allNLessThan5Groups1: this.$store.state.reportData.allNLessThan5Groups,
                selectedValues: [],

                applyingFilters: false
            }
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },

        mounted(){
            
            let str1 = JSON.stringify(this.breakdown1)
            let str2 = JSON.stringify(this.breakdown2)
            let parsed1 = JSON.parse(str1)
            let parsed2 = JSON.parse(str2)

            console.log("Data 1")
            console.log("------------------------------------")
            console.log(this.breakdown1)

            console.log("Data 2")
            console.log("------------------------------------")
            console.log(this.breakdown2)
            
            for (let index=0; index < parsed1.length; index++) {
                console.log("Entered")
                if (parsed1[index] !== null){
                    const doughnut1 = doughnutConfigCreator(parsed1[index].break_down);
                    let id1 = `chart1${parsed1[index].index}${this.section1.order}`
                    const ctx1 = document.getElementById(id1).getContext('2d');
                    const myChart1 = new Chart(ctx1, doughnut1);
                }

                if (parsed2[index] !== null){
                    const doughnut2 = doughnutConfigCreator(parsed2[index].break_down);
                    let id2 = `chart2${parsed2[index].index}${this.section2.order}`
                    const ctx2 = document.getElementById(id2).getContext('2d');
                    const myChart2 = new Chart(ctx2, doughnut2);
                }
            }
        },

        errorCaptured(err, instance, info){
            console.log("error captured")
            console.log(err, instance, info)
            this.$toast.error("Something Went Wrong! See console for more Info")
            this.$router.go(-1)
        },

        // components: {
        //   SectionSpecificFilter
        // },

        computed: {
            ...mapState(['appliedFilters', 'sections', 'displayFiltersDemographicTypeOptions']),
            userPermissions(){
                return this.$store.state.userPermissions
            },
            index(){
                return this.$store.state.sections.indexOf(this.section)
            }

        },

        // methods: {
        //     async setDemographicTypes(){
        //         console.log(this.index)
        //         if (this.$store.state.displayFiltersDemographicTypeOptions.length > 0){
        //         return
        //         }
        //         let url = `api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-types/`

        //         await axios
        //             .get(url)
        //             .then((response) => {
        //             console.log(response.data)
        //             response.data.forEach((obj) => {
        //                 this.$store.state.displayFiltersDemographicTypeOptions.push({
        //                     displayName: obj['display_name'],
        //                     enumValue: obj['enum_value'],
        //                 })
        //             })
        //             console.log(this.displayFiltersDemographicTypeOptions)
        //             })
        //             .catch(err => console.log(err))
        //     },

        //     async applyFilter(){
        //         console.log(this.index)
        //         var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
        //         var token = localStorage.getItem("token");
        //         var token_map = JSON.parse(token);
        //         // this.$store.state.sections[this.index].filters.display_filters.display_charts = this.selectedValues
        //         const config = {
        //         params: this.$store.getters.params
        //         };

        //         this.applyingFilters = true

        //         await axios
        //         .get(url, config)
        //         .then((response) => {
        //             if (response.status === 200){
        //                 console.log(response.data)
        //                 this.$store.commit('setReportData', response.data)
        //                 console.log(this.$store.state.reportData)
        //                 this.$store.commit('applyFilters')
        //                 this.$refs.closeModal.click()
        //                 console.log(this.$store.state.sections);
        //                 this.$toast.info(`Filters applied successfully`);
        //             }

        //             else{
        //               this.$toast.warning(`No Responses Found for this Filter`);
        //             }

        //         })
        //         .catch((error) => {
        //             this.$toast.error(`Failed to apply filter`);
        //             console.log(error);
        //             console.log("Error in Promise")
        //         })
        //         .finally(() => {this.applyingFilters = false})
        //     },

        //     updateTitle(event){
        //         if (event.target.textContent == '' || event.target.textContent.trim() == ''){
        //             this.$toast.error(`Please Provide a Valid Name`);
        //             return
        //         }
        //         this.$store.state.sections[this.index].title = event.target.textContent
        //     },

        //     duplicatePage(){
        //         this.$emit('duplicatePage', this.index)
        //     },

        //     deletePage(){
        //         this.$emit('deletePage', this.index)
        //     }
        // }
    };
</script>