import { labelHoverAbove, labelHoverBelow, floatingTest, fixLabelByTrimming, fixLabelByTrimmingAndNewLines } from "./common";
import { replaceChartByImage } from "./replaceImage";
export const stackedBarConfig = (obj, chartId) => {
  const labelSize = '13%'
  const sideLabelSize = '10%'
  const tickSize = 15
  const numberSize = 15
  const barThickness = 40
  let aspectRatio = 2.5
  
  let factor_count = obj.data.labels.length
  aspectRatio = (factor_count>3 ? 1.8 : factor_count === 1 ? 4.5 : 3.5 ) + Math.floor((17 - factor_count) / 6);

  obj.data.labels = obj.data.labels.map((l) => fixLabelByTrimming(l));
  console.log(chartId);


  const data = {
    // labels: [
    //   "My work",
    //   "My colleagues",
    //   "Our brand",
    //   "Our core values",
    //   "Our impact",
    // ],

    labels: obj.data.labels,
    datasets: [
      {
        // datalabels: {
        //   display: true,
        //   color: "black",
        //   font: {
        //     size: 20,
        //   },
        // },

        label: "Negative",
        // data: [58, 53, 28, 28, 28],
        data: obj.data.negative.dataValues,
        backgroundColor: ["#1863df"],
      },
      {
        // datalabels: {
        //   display: true,
        //   color: "black",
        //   font: {
        //     size: 20,
        //   },
        // },
        label: "Neutral",
        // data: [28, 16, 72, 31, 31],
        data: obj.data.neutral.dataValues,
        backgroundColor: ["#9F1D51"],
      },

      {
        // datalabels: {
        //   display: true,
        //   color: "black",
        //   font: {
        //     size: 20,
        //   },
        // },
        label: "Positive",
        // data: [13, 31, 0, 41, 44],
        data: obj.data.positive.dataValues,
        backgroundColor: ["#E99B23"],
      },
    ],
  };

  const options = {
    defaultFontFamily: "Montserrat",
    font: {
      family: "Montserrat",
      weight: "normal",
      style: 'normal',
    },
    responsive: true,
    indexAxis: "y",
    maintainAspectRatio: true,
    aspectRatio: aspectRatio,
    animation: false,
    plugins: {
      datalabels: {
        color: "black",
        // if value is 0 or null, don't show datalabel

        // formatter: (value, index, values) => {
        //   if (value === 0 || value === null) {
        //     return "";
        //   } else {
        //     return null;
        //   }
        // },

        display: function (context) {
          return context.dataset.data[context.dataIndex] > 1;
        },

        font: {
          family: "Montserrat",
          size: numberSize,
          style: "normal",
          weight: "normal",
        },
      },
      legend: {
        labels: {
          color: "black",
          boxWidth: 40,
          padding: 10,
          font: {
            family: "Montserrat",
            size: labelSize,
            weight: "normal",
            style: 'normal',
          },
        },
        display: true,
      },
    },

    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          beginAtZero: true,
          steps: 20,
          color: "black",
          font: {
            family: "Montserrat",
            weight: "normal",
            style: 'normal',
            size: tickSize
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          offset: true,
          drawTicks: true,
        },
        ticks: {
          color: "black",
          beginAtZero: true,
          padding: 5,
          steps: 20,
          font: {
            family: "Montserrat",
            size: sideLabelSize,
            weight: "normal",
            style: 'normal',
          }
        },
      },
    },
  };

  const stackedBarConfig = {
    type: "bar",
    data,
    options,
  };

  var canvas = document.getElementById(chartId)
  var ctx = canvas.getContext("2d");
  ctx.imageSmoothingEnabled = true;
  const stackedBarChart = new Chart(ctx, stackedBarConfig);
};
