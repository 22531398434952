<template>
     <div class="container-b fix-white-bg">
        <div class="new-left-col edgility-index-kite-img-div">
            <img
                    :src="`../../assets/img/report/${kiteScore}-transparent.png`"
                    alt="Level 1 Soaring"
                    class="left-kite-img"
                    style="max-width: 80%;"
            />
        </div>
        <div class="new-right-col">
            <div class="chart-row v-center center-t">
                <h2>{{ tenant }} Equity Index Score</h2>
                <!-- <h1 class="t-y big bold text-shadow">##</h1> -->
                <div v-if="kiteScore === 'Soaring'" class="equity-text">
                    <span class="t-y">S</span><span class="t-b">o</span>
                    <span class="t-r">a</span><span class="t-y">r</span>
                    <span class="t-b">i</span><span class="t-r">n</span>
                    <span class="t-y">g</span><span class="t-b">!</span>
                </div>

                <div v-else class="equity-text t-r">
                    <p> {{ kiteScore }} </p>
                </div>

                <div class="bigger">
                    <p v-if="kiteScore === 'Soaring'">
                        You’re doing right by everyone. Continue to monitor and adjust as
                        needed.
                    </p>

                    <p v-else-if="kiteScore === 'Gliding'">
                        You are doing right by everyone in some areas, but need to improve
                        in others. Focus on growth areas to really let your organization
                        soar!
                    </p>

                    <p v-else-if="kiteScore === 'Climbing'">
                        You have leaned in heavily on some strengths that only work for
                        some people but have left others behind.
                    </p>

                    <p v-else-if="kiteScore === 'Developing'">
                        You have work to do in several areas. Listen to the voices of
                        staff who are most disengaged and focus on improving in those
                        areas first. Then choose one or two areas of focus for all staff.
                    </p>

                    <p v-else-if="kiteScore === 'Emerging'">
                        You are struggling to meet the needs of all of your staff. Focus
                        on the areas they care about most, and start there.
                    </p>

                    <p v-else-if="kiteScore === 'Embarking'">
                        You have work to do in many areas, and staff are experiencing your
                        organization quite differently. Engage your staff to identify what
                        is most important to them, and focus there first.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EquityIndex',
        props: ['section'],
        data(){
            return {
                kiteScore: this.section.kite_score
            }
        },
        computed:{
            tenant(){
                return localStorage.getItem("subdomin")
            }
        },

        mounted(){
            console.log(this.kiteScore)
        }
    }
</script>