<template>
  <div id="survey-creator"/>
</template>
<style scoped>
#surveyCreator {
  height: 100vh;
  width: 100vw;
}
</style>
<script>
import "survey-creator-core/survey-creator-core.min.css";
import "survey-core/defaultV2.min.css";
import axios from "axios";

import "survey-core/defaultV2.min.css";
import "survey-creator-knockout/survey-creator-knockout";
import {SurveyCreator} from "survey-creator-knockout";
import {StylesManager} from "survey-core";
import router from "@/router";

StylesManager.applyTheme("defaultV2");

export default {
  name: 'survey-creator', mounted() {
    this.loadSurveyTemplate();
  }, data() {
    return {
      surveyJson: {},
      creator: {}
    }
  },
  methods: {

    escapeJSON(json) {
      var escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
      var meta = {    // table of character substitutions
        '\b': '\\b', '\t': '\\t', '\n': '\\n', '\f': '\\f', '\r': '\\r', '"': '\\"', '\\': '\\\\'
      };

      escapable.lastIndex = 0;
      return escapable.test(json) ? '"' + json.replace(escapable, function (a) {
        var c = meta[a];
        return (typeof c === 'string') ? c : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
      }) + '"' : '"' + json + '"';
    },

    async loadSurveyTemplate() {
      const surveyID = this.$route.params.survey_template_id;
      const creatorOptions = {
        showLogicTab: true,
        isAutoSave: true,
        showTranslationTab: true
      };

      this.surveyJson = await axios
          .get("api/survey-template/" + surveyID + "/")
          .then((response) => {
            this.surveyJson = JSON.parse(JSON.parse(this.escapeJSON(response.data.data)));
            const creator = new SurveyCreator(creatorOptions);
            this.creator = creator;
            console.log("survey json")
            console.log(this.surveyJson)
            creator.JSON = this.surveyJson
            creator.render("survey-creator");
            creator.saveSurveyFunc = this.saveSurveyTemplate;
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403){
                console.log("unauthorised access. redirect to login")
                this.$router.push('/login')
                return
            }
            this.$toast.error("Failed to load campaign template data")
          });
      console.log(this.surveyJson)
    },

    async saveSurveyTemplate(surveyJSON, v){
      const templateID = this.$route.params.survey_template_id;
      console.log("saving the template")
      console.log(this.creator.JSON)
        let request = {};

        request["id"] = templateID;
        request["data"] = JSON.stringify(this.creator.JSON);
        axios.put("api/survey-template/"+ templateID +"/", request).then((response) => {
            console.log("survey template submitted, response status:" + response.status)
            this.$toast.success("Saved successfully")
        }).catch(error =>{
          this.$toast.error("Failed to save")
          console.log(error);
      });
    }
  }
}
</script>