<template>
    <div v-for="page in appendix" >
    <div><a id=""></a></div>
    <div class="container-b fill-white" style="width: 100%">
        <div v-if="page[0] === 0" class="topcenter-row bg-offwhite">
            <h2 class="bolder center-t padding-add">
                <span class="blue">Appendix</span>
            </h2>
            <img src="../assets/img/report/scores-key.svg" alt="scores" class="key-row" />
        </div>
    <div class="new-row">
        <div class="tg-wrap">
            <table
                id="tg-rUw5L"
                class="tg"
                style="
                    table-layout: fixed;
                    width: 90%;
                    border-right: hidden;
                    border-bottom: hidden;
                "
            >
                <colgroup>
                    <col style="width: 80%" />
                    <col style="width: 20%" />
                </colgroup>
                <thead>
                <tr>
                    <th class="tg-9eob"></th>
                    <th class="tg-myj0" style="border-color: white">
                        Your Org's Averages
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="question_data in page[1]">
                        <td class="tg-tyud">
                            <span class="q-slash">\</span>
                            {{ question_data[1].title }}
                        </td>
                        <td class="tg-v3bh">{{ question_data[1].average }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</div>
</div>
</template>

<script>
    export default {
        name: 'Appendix',

        data(){
            return {
                appendix: this.$store.state.reportData.appendix
            }
        },

        mounted(){
            console.log(this.appendix)
        }
    }
</script>