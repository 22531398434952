<template>
    <div class="card border-0 shadow">
        <div class="card-header border-bottom d-flex align-items-center justify-content-between">
            <h2 class="fs-5 fw-bold mb-0"> All Custom Reports </h2>

        </div>

        <div class="card-body">
            
            <div v-if="isLoading" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <ul v-else class="list-group list-group-flush list my--3">
                <li v-if="reports.length" v-for="report in reports" class="list-group-item p-3 m-1 border" :key="report">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <p class="mb-0">
                                <a href="#"> {{ report.title }} </a>
                            </p>
                        </div>

                        <div class="col text-end">

                            <button @click="this.setReport(report)" class="btn btn-sm btn-gray-700 d-inline-flex align-items-center" data-bs-toggle="modal" data-bs-target="#modalDownloadReport">
                                <svg fill="none" class="icon icon-xxs me-2" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path></svg>
                                Download PDF report
                            </button>
                            <button @click="this.deleteReport(report.id)" class="btn btn-sm btn-danger d-inline-flex align-items-center ms-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-xxs me-2" fill="currentColor" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/></svg>
                                Delete
                            </button>
                        </div>
                    </div>
                </li>

                <li v-else class="list-group-item text-center text-danger fw-bolder">
                    You Don't have any Customized Report...!
                </li>
            </ul>
        </div>

        <div class="modal fade" id="modalDownloadReport" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="downloadReportModelClose"></button>
                    </div>
                    <div class="modal-body px-md-5">
                        <h2 class="h5 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Include/Exclude the Section from Report</h2>
                    </div>

                    <div v-if="Object.keys(this.selectedReport).length > 0" class="card-body" style="height: 400px; overflow: auto;">
                        <ul class="list-group list-group-flush list my--3">
                            <li v-for="section in this.selectedReport.data.sections" :class="'list-group-item p-3 m-1 border' + (!section.exclude ? ' bg-success bg-opacity-25' : ' bg-danger bg-opacity-25')" :key="section.exclude">
                                <div class="row align-items-center">
                                    <div class="col-auto ms--2">
                                        <p class="mb-0 pt-1 pb-1 ps-2 pe-2" >
                                            {{ section.title  }}
                                        </p>
                                    </div>

                                    <div class="col text-end">
                                        <!-- <input  class="form-check-input bg-danger bg-opacity-10" type="checkbox" v-model="section.exclude"> -->
                                        <button @click="section.exclude = !section.exclude" class="btn btn-md" :title="(section.exclude ? 'Include Section to Report' : 'Exclude Section from Report')">
                                            <svg v-if="section.exclude" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/></svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/></svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="d-flex align-items-center justify-content-center gap-4 m-4">
                        <button @click="downloadReportWithSortedSection" class="btn btn-sm btn-tertiary"> Download </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import {saveAs} from "file-saver";

    export default {
        name: 'Reports',
        data(){
            return{
                reports: [],
                selectedReport: {

                },
                selectedReportData: [],
                isLoading: true
            }
        },
        beforeMount(){
            this.getCustomReports()
        },
        mounted(){
            console.log(this.selectedReport)
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },
        
        methods:{
          async getCustomReports(){
            this.isLoading = true
            await axios
              .get(`api/survey/${this.$store.state.selectedSurveyID}/custom-reports/`)
              .then((resp) => {
                if (resp.status === 200) {
                    this.reports = resp.data.results
                }
              })
            this.isLoading = false
          },

          async report_file_name() {
            var token = localStorage.getItem("token");
            var token_map = JSON.parse(token);
            var url = "api/report-name/" + this.$store.state.selectedSurveyID + "/";
            try {
                const response = await axios({
                url: url, method: "GET", responseType: "text",
                headers: {Authorization: `Bearer ${token_map.access}`},
                });
                console.log(response.data);
                let parsed = JSON.parse(response.data)
                return parsed.filename
            } catch (error) {
                this.$toast.error(`Failed to prepare report`);
                console.error(error);
            }
          },

          async downloadReport(id){
            var url = `api/custom-reports/${id}/download-pdf-report`
            try {
                this.$store.commit('setIsLoading', true)
                var token = localStorage.getItem("token");
                var token_map = JSON.parse(token);
                const response = await axios({
                    url: url,
                    method: "GET",
                    responseType: "blob",
                    headers: {Authorization: `Bearer ${token_map.access}`},
                });
                let report_name = this.$store.state.reportData.survey_data.campaign_name.toUpperCase().split(' ').join('_')
                let org = this.$store.state.reportData.survey_data.organization.toUpperCase().split(' ').join('_')

                const currentDate = new Date();
                const day = String(currentDate.getDate()).padStart(2, '0');
                const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Note: Months are zero-based
                const year = currentDate.getFullYear();
                this.$store.commit('setIsLoading', false)
                // Use FileSaver.js to save the file
                saveAs(response.data, `${report_name}_${org}_${day+month+year}.pdf`);
                this.$toast.success(`Report downloaded successfully`);
            } catch (error) {
                this.$toast.error(`Failed to download the report`);
                console.error(error);
                this.$store.commit('setIsLoading', false)
            }
          },

          async downloadReportWithSortedSection(){
            this.selectedReport.data.sections.forEach((section) => {
                section.exclude = section.exclude ? 1 : 0
            })
            this.selectedReport.data.sections = JSON.stringify(this.selectedReport.data.sections)
            this.selectedReport.data = JSON.stringify(this.selectedReport.data);
            this.$refs.downloadReportModelClose.click()

            this.updateAndDownloadReport()

          },

          async deleteReport(id){
            await axios
              .delete(`api/custom-reports/${id}/`)
              .then((resp) => {
                this.$toast.success(`Deleted successfully`);
                this.getCustomReports()
              })
              .catch((err) => console.log(err))
          },

          setReport(report){
            this.selectedReport = report;
            this.selectedReport.data = JSON.parse(this.selectedReport.data);
            this.selectedReport.data.sections = JSON.parse(this.selectedReport.data.sections);

            this.selectedReport.data.sections.forEach((section) => {
                if (!('exclude' in section)){
                    section.exclude = 0
                }
            })

            console.log(this.selectedReport.data.sections)


          },

          async updateAndDownloadReport(){
            let id = this.selectedReport.id
            let data = this.selectedReport.data

            await axios
              .put(`api/custom-reports/${id}/`, {title: this.selectedReport.title, data: data})
              .then((resp) => {
                this.downloadReport(this.selectedReport.id)
              })
              .catch(err => {
                    console.log(err);
                  }
              )

          },

        }
    }
</script>