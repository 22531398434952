import { labelHoverAbove, labelHoverBelow, floatingTest, fixLabelByTrimming, fixLabelByTrimmingAndNewLines } from "./common";

export const comparisonConfigCreator = (obj, chartId, data2=null) => {
    console.log(chartId)
    const labelHoverAbove = {
      offset: 15,
      font: {
        size: 20,
        weight: "bold",
        family: "Montserrat",
      },

      display: true,
      color: function (context) {
        // returns the same color as that values background color
        return context.chart.data.datasets[context.datasetIndex].backgroundColor[
          context.dataIndex
        ];
      },
      anchor: "end",
      align: "end",
      // display value on the bar
      formatter: function (value, context) {
        // return dataset label
        return context.dataset.value;
      },
    };

    let dataset = [
      {
        // label: obj.orgName, not used.
        backgroundColor: ["#1863df", "#9F1D51", "#E99B23"],
        // borderColor: ["#1863df", "#9F1D51", "#E99B23"],
        // borderWidth: 0,
        data: obj.data,
      }
    ]

    if (data2 !== null){
      dataset.push({
        backgroundColor: ["#5c84ce", "#c0567d", "#e4b364"],
        data: data2,
      })
    }

    dataset.push({
      backgroundColor: [
        "rgb(157,187,240)",
        "rgb(213,150,172)",
        "rgb(247,210,162)",
      ],
      // borderColor: ["#1863df", "#9F1D51", "#E99B23"],
      // borderWidth: 0,
    // Reference data for:
      // All other Orgs - on Edgility Pillars of Equity - How You Compare
    // C&T	3.55196318
    // F&C	3.732844247
    // I&B	3.83231813
    // Rounded off to one digit after decimal
      data: [3.6, 3.7, 3.8],
    })

    const data = {
      labels: [
        "Clarity & Transparency",
        "Fairness & Consistency",
        "Inclusion & Belonging",
      ],

      datasets: dataset,

      // datasets: [
      //   {
      //     // label: obj.orgName, not used.
      //     backgroundColor: ["#1863df", "#9F1D51", "#E99B23"],
      //     // borderColor: ["#1863df", "#9F1D51", "#E99B23"],
      //     // borderWidth: 0,
      //     data: obj.data,
      //   },
      //   {
      //     backgroundColor: ["#5c84ce", "#e4b364", "#c0567d"],
      //     data: data2,
      //   },
      //   {
      //     backgroundColor: [
      //       "rgb(157,187,240)",
      //       "rgb(213,150,172)",
      //       "rgb(247,210,162)",
      //     ],
      //     // borderColor: ["#1863df", "#9F1D51", "#E99B23"],
      //     // borderWidth: 0,
      //   // Reference data for:
      //     // All other Orgs - on Edgility Pillars of Equity - How You Compare
      //   // C&T	3.55196318
      //   // F&C	3.732844247
      //   // I&B	3.83231813
      //   // Rounded off to one digit after decimal
      //     data: [3.6, 3.7, 3.8],
      //   },
      // ],
    };

    const options = {
      font: {
        family: "Montserrat",
      },
      plugins: {
        title: {
          color: "black",
          display: false,
        },
        legend: {
          display: false,
          color: "black",
          labels: {
            // generateLabels: (chart) => {},

            font: {
              family: "Montserrat",
              size: 22,
            },
          },
          font: {
            family: "Montserrat",
            weight: "normal",
            style: 'normal',
          },
        },
        datalabels: {
          ...labelHoverAbove,
        },
      },

      scales: {
        x: {
          display: true,
          drawBorder: false,
          color: "black",
          grid: {
            color: "black",
            display: false,
            borderColor: "#000",
            borderWidth: 2,
          },
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 1,
            color: "black",
            font: {
              size: 20,
              weight: "400",
              // color: "#000",
              family: "Montserrat",
            },
          },
        },
        y: {
          display: true,
          min: 0,
          max: 5,
          drawBorder: false,
          grid: {
            font: {
              color: "black",
              family: "Montserrat",
            },
            drawOnChartArea: false,

            borderColor: "#000",
            borderWidth: 2,
            tickColor: "#000",
            tickLength: 20,
            tickWidth: 2,
          },
          ticks: {
            display: true,
            color: "black",

            beginAtZero: true,
            stepSize: 1,
            font: {
              size: 20,
              weight: "normal",
            },
          },
        },
      },
    };

    const config = {
      type: "bar",
      data,
      options,
    };
    var ctx = document.getElementById(chartId).getContext("2d");
    ctx.imageSmoothingEnabled = true;
    const BarLineChart = new Chart(ctx, config);
  };
