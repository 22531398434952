<template>
    <button
          type="button"
          class="btn me-1"
          title="Section specific filter"
          data-bs-toggle="modal"
          :data-bs-target="`#modalFilters${this.index}`"
          @click="this.setDemographicTypes"
          style="position: absolute; top: 2em; left: 2em;"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" class="bi bi-funnel-fill" viewBox="0 0 16 16"> <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/> </svg>
      </button>

    <div class="modal fade" :id="`modalFilters${this.index}`" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
          </div>
          <div class="modal-body px-md-5">
            <h2 class="h5 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Filter by Demographic Type and Values</h2>

            <p class="text mt-2 mb-2 text-danger text-bolder">
                This Filter is Specific To:
              <span>{{section.title}}</span>
            </p>

            <div>
            <label class="typo__label">Select Demographic Type</label>
              <multiselect id="demographicType" @select="this.getDemographicValue" track-by="enumValue"
                           label="displayName" v-model="selectedDemographic" :options="demographicsOptions"
                           :searchable="false" :close-on-select="true" :show-labels="false"
                           placeholder="Select Demographics" :disabled="applyingFilters"></Multiselect>
            <br>

            <label class="typo__label">Select Demographic Values</label>
              <multiselect ref="demographicValues" v-model="this.selectedValues" :options="this.valuesOptions"
                           :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                           placeholder="Pick Demographic Values" :preselect-first="false" :disabled="applyingFilters"></multiselect>
            </div>
            <br>

            <div class="d-flex align-items-center justify-content-end gap-4">
              <button class="btn btn-secondary" type="button" @click="addFilter" :disabled="applyingFilters">
                Add Filter
              </button>
            </div>
            <br>

            <p v-for="(value, key) in this.addedFilters" class="p-3 m-1 border" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <span class="text"><span class="text fw-bolder"> {{ key.toUpperCase() }}: </span> <br>  <span v-for="val in value" class="badge bg-gray-600 me-2">{{ val }} </span> </span>
              <button class="btn btn-sm btn-danger" type="button" @click="removeFilter(key)" :disabled="applyingFilters"> Remove </button>
            </p>

            <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
              <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                Cancel
              </button>
              <button class="btn btn-primary" type="button" @click="applyFilters" :disabled="applyingFilters">
                <div v-if="applyingFilters" class="d-flex align-items-center">
                  <span role="status" class="me-2">Applying Filters</span>
                  <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                </div>
                <span v-else>
                  Apply Filters
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default{
        name: 'SectionSpecificFilter',
        props: ['section'],

        data(){
            return{
                demographicsOptions: [],
                selectedDemographic: '',
                valuesOptions: [],
                selectedValues: [],
                applyingFilters: false,
                addedFilters: {...this.section.filters.section_filters}
            }
        },

        mounted(){
          console.log(this.index)
        },

        computed: {
            index(){
              return this.$store.state.sections.indexOf(this.section)
            }
        },

        methods: {
            async setDemographicTypes(){
              console.log(this.index)
                if (this.demographicsOptions.length > 0){
                  return
                }
                let url = `api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-types/`

                await axios
                .get(url)
                .then((response) => {
                    response.data.forEach((obj) => {
                        this.demographicsOptions.push({
                            displayName: obj['display_name'],
                            enumValue: obj['enum_value'],
                        })
                    })
                })
                .catch(err => console.log(err))
            },

            async getDemographicValue(){
                this.valuesOptions = []
                this.selectedValues = []
                let url = `api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-values-for-type/`
                let params = {
                  'demographic': this.selectedDemographic.enumValue
                }

                const config = {
                    params: params
                };

                await axios
                .get(url, config)
                .then((response) => {
                    this.valuesOptions = response.data
                })
            },

            addFilter(){
                if (!Boolean(this.selectedDemographic)){
                    this.$toast.error(`Please select a demographic type`);
                    return;
                }
                if (this.selectedValues.length === 0){
                    this.$toast.error(`Please select one or more demographic value`);
                    return;
                }

                let str = JSON.stringify(this.selectedValues)
                this.addedFilters[this.selectedDemographic['enumValue']] = JSON.parse(str)

                this.selectedDemographic = ''
                this.selectedValues = []
            },

            removeFilter(key){
              delete this.addedFilters[key]
            },

            async applyFilters(){
            this.applyingFilters = true
            var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
            this.$store.state.sections[this.index].filters.section_filters = this.addedFilters

            const config = {
              params: this.$store.getters.params
            };

            await axios
              .get(url, config)
              .then((response) => {
                if (response.status === 200){
                  this.$store.commit('setReportData', response.data)
                  this.$refs.closeModal.click()
                  this.selectedDemographicType = ''
                  this.selectedDemographicValues = []
                  console.log(response.data)
                  this.$toast.info(`Filters applied successfully`);
                }

                else{
                  this.$toast.warning(`No Responses Found for this Filter`);
                }

              })
              .catch((error) => {
                this.$store.state.section[this.index].filters.section_filters = {}
                this.$toast.error(`Error while preparing the report data`);
                console.log(error);
              })
              .finally(() => {this.applyingFilters = false;})

          },
        }
    }
</script>