<template>
  <div class="card border-0 shadow mb-4">
    <div class="card-body">
      <SectionSpecificFilter v-if="userPermissions.canDoSectionSpecificFilters" :section="this.section"/>
      <h2 class="text-center fw-normal mx-8" @blur="this.updateTitle" :contenteditable="section.duplicate === 1">{{ this.$store.state.sections[this.index].title }}</h2>
      <h3 v-if="this.type==='srf'" class="text-center fw-normal mt-3">Factors that most contribute to staff retention:</h3>
      <h3 v-else-if="this.type==='saf'" class="text-center fw-normal mt-3">Factors that most contribute to staff attrition:</h3>

      <button
        v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
        type="button"
        class="btn btn-sm"
        title="Delete Page"
        @click="this.deletePage"
        style="position: absolute; top: 1.5em; right: 2.5em; "
    >
        <span class="fs-3 me-1 fw-bolder text-danger">X</span>
    </button>

      <div style="width: 7%; position: absolute; top: 5.5em; right: 8em;">
        <multiselect v-if="this.type === 'srf' && !applyingFilters" @select="update" v-model="this.$store.state.sections[this.index].filters.display_filters.display_count_srf" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select a Number"></multiselect>
        <multiselect v-else-if="this.type === 'saf' && !applyingFilters" @select="update" v-model="this.$store.state.sections[this.index].filters.display_filters.display_count_saf" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select a Number"></multiselect>
            <div v-if="applyingFilters" class="d-flex align-items-center justify-content-center">
                <div class="spinner-border text-grey ms-auto" aria-hidden="true"></div>
            </div>                
      </div>

      <button
        v-if="userPermissions.canAddUpdateCustomReport"
        type="button"
        class="btn me-1"
        title="Set Demographics"
        @click="this.duplicatePage"
        style="position: absolute; top: 6em; right: 1.5em;"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
    </button>

      <div class="mx-8 mt-4">
        <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
            <hr>
            <span class="text-warning">Section Specific Filters:  </span>
            <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
                <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
                <span class="text-decoration-none">{{ value.join(', ') }}</span>
                <br><br>
            </div>
        </div>
        <p class="edgility-gray lh-sm text-center">The following factors most likely contribute <strong>negatively</strong> to current staff retention.
        </p> <br>
      </div>
      <div class="text-center mx-6">
          <canvas :id="this.id + this.section.order" />
      </div>
    </div>
  </div>
</template>

<script>
    import { stackedBarConfig } from '@/assets/js/srfChart'
    import axios from 'axios'
    import SectionSpecificFilter from './SectionSpecificFilter.vue'

    export default{
        name: "StaffAttrition",
        props: ['section', 'type'],

        data(){
            return {
                applyingFilters: false
            }
        },  

        computed: {
            userPermissions(){
                return this.$store.state.userPermissions
            },
            index(){
                return this.$store.state.sections.indexOf(this.section)
            },
            id(){
                if (this.type === 'srf'){
                    return "srfRetention"
                }

                else if (this.type === 'saf'){
                    return "srfAttrition"
                }
            }
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },

        components: {
            SectionSpecificFilter
        },

        methods: {
            async update(){
                var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
                const config = {
                    params: this.$store.getters.params
                };
                this.$toast.info("Filters are being Applied")
                this.applyingFilters = true
                await axios
                .get(url, config)
                .then((response) => {
                    if (response.status === 200){
                        this.$store.commit('setReportData', response.data)
                      this.$toast.info(`Filters applied successfully`);
                    }
                    else{
                      this.$toast.warning(`No Responses Found for this Filter`);
                    }
                })
                .catch((error) => {console.log(error); })
                .finally(() => {this.applyingFilters=false})

            },
            updateTitle(event){
                if (event.target.textContent == '' || event.target.textContent.trim() == ''){
                    this.$toast.error(`Please Provide a Valid Name`);
                    return
                }
                this.$store.state.sections[this.index].title = event.target.textContent
            },
            duplicatePage(){
                this.$emit('duplicatePage', this.index)
            },

            deletePage(){
                this.$emit('deletePage', this.index)
            }
        },

        mounted(){
            var labels = []
            var positive = []
            var negative = []
            var neutral = []

            var factors = []
            var count = 17

            console.log(this.section)

            if (this.type === 'srf'){
                console.log("entered srf")
                factors = this.section.retention_factors.top_retentions
                count = this.section.filters.display_filters.display_count_srf
            }

            else if(this.type === 'saf'){
                console.log('Entered saf')
                factors = this.section.retention_factors.top_attritions
                count = this.section.filters.display_filters.display_count_saf
            }

            let attritions_string = JSON.stringify(factors)
            let attrition_factors = JSON.parse(attritions_string)
            for (var page of attrition_factors.slice(0, count)){
                for (var factor of page){
                    labels.push(factor.retention_type)
                    positive.push(parseInt(factor.percentages.positive))
                    negative.push(parseInt(factor.percentages.negative))
                    neutral.push(parseInt(factor.percentages.neutral))
                }
            }

            stackedBarConfig({
                data: {
                    labels: labels,
                    positive: {
                        dataValues: positive,
                    },
                    neutral: {
                        dataValues: neutral,
                    },
                    negative: {
                        dataValues: negative,
                    },
                }
            }, this.id + this.section.order)
        }
    }
</script>