// hover data label above bar
export const labelHoverAbove = {
    backgroundColor: function (context) {
      var color = context.dataset.backgroundColor;
      return color;
    },
    borderWidth: 2,
    borderColor: function (context) {
      var color = context.dataset.borderColor;
      return color;
    },
    opacity: 0.7,
    offset: 15,
  
    display: true,
    color: "black",
    anchor: "end",
    align: "end",
    // display value on the bar
    formatter: function (value, context) {
      // return dataset lable
      return context.dataset.label;
    },
};
  
export  const labelHoverBelow = {
    backgroundColor: "#A01C52",
    borderWidth: 2,
    borderColor: "#A01C52",
    opacity: 0.7,
    offset: 40,
  
    display: true,
    color: "black",
    anchor: "center",
    align: "bottom",
    // display value on the bar
    formatter: function (value, context) {
      // return dataset lable
      return context.dataset.label + " < 0";
    },
};

export  const floatingTest = {
    // backgroundColor: "#A01C52",
    // borderWidth: 2,
    // borderColor: "#A01C52",
    // offset: 40,
  
    opacity: 0.7,
    display: "auto",
    color: "black",
    // anchor: "center",
    // align: "bottom",
    // display value on the bar
    formatter: function (value, context) {
      return value.x;
    },
    font: {
      family: "Montserrat",
      size: 13,
      weight: "normal",
      style: 'normal',
  
    },
};
  
export  const fixLabelByTrimming = (label) => {
  
    // remove bracketed info from label
    if (label.indexOf("(") !== -1) {
        label = label.substring(0, label.indexOf("(")).trim();
    }
  
    // if length of label is still long, trim it to 40 and append a ... to signify the trim
    if (label.length > 40) {
        label = label.substring(0, 40) + "...";
    }
  
    return label;
};

export  const fixLabelTVPDisparitiesByTrimming = (label) => {
  
  // remove bracketed info from label
  if (label.indexOf("(") !== -1) {
      label = label.substring(0, label.indexOf("(")).trim();
  }

  // if length of label is still long, trim it to 40 and append a ... to signify the trim
  if (label.length > 18) {
      label = label.substring(0, 10) + "...";
  }

  return label;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
  
export  const fixLabelByTrimmingAndNewLines = (label) => {
    // remove bracketed info from label
    let ind = label.indexOf("(");
    if (ind !== -1) {
        label = label.substring(0, label.indexOf("(")).trim();
    }
  
    if (label.length > 12) {
        label = label.replace(" ", "\n")
            .replace(",", ", \n")
            .replace("/", "/ \n")
            .replace("(", "\n(")
        ;
    }
  
    // if length of label is still long, trim it to 40 and append a ... to signify the trim
    if (label.length > 40) {
        label = label.substring(0, 40) + "...";
    }
  
    return label;
};