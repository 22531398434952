<template>
    <div v-for="(feature, index) in tenantFeatures" class="card border-0 shadow mb-4">
        <div class="card-header border-bottom d-flex align-items-center justify-content-between">
            <h2 class="fs-5 fw-bolder mb-0"> Feature{{ index+1 }}: {{ feature.feature.display_name }} </h2>
        </div>

        <div class="card-body">
            <ul class="list-group list-group-flush list my--3">
                <li v-for="tenant in feature.tenants" class="list-group-item p-3 m-1 border d-flex align-items-center justify-content-between" :key="org">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <h2 class="h5 mb-0 bold">
                                <a href="#"> {{ tenant.tenant_name }} </a>
                            </h2>
                            <div v-if="tenant.enabled" class="d-flex align-items-center">
                                <div class="bg-success dot rounded-circle me-1"></div>
                                <small>Enabled</small>
                            </div>

                            <div v-else class="d-flex align-items-center">
                                <div class="bg-danger dot rounded-circle me-1"></div>
                                <small>Disabled</small>
                            </div>
                        </div>
                    </div>
                    <div v-if="updatingId === tenant.tenant_id" class="d-flex align-items-center">
                        <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                    </div>
               
                    <div v-else class="form-check form-switch">
                        <input class="form-check-input" v-model="tenant.enabled" type="checkbox" role="switch" @change="updateEnabled(tenant, feature.feature)" id="flexSwitchCheckDisabled" >
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: "ManageFeatures",

    data() {
        return {
            tenantFeatures: [],
            updatingId: null
        }
    },

    mounted() {
        this.getTenantFeaturesList()
    },

    methods: {
        async getTenantFeaturesList(){
            axios
                .get('api/tenant-features-list/')
                .then(resp => {
                    this.tenantFeatures = resp.data
                })
                .catch(err => console.log(err))

        },

        async updateEnabled(tenant, feature){
            this.updatingId = tenant.tenant_id;

            axios
                .post(`api/tenant/${tenant.tenant_id}/switch-feature-enable/?feature=${feature.name}`)
                .then((resp) => {
                    if (resp.status === 200){
                        this.getTenantFeaturesList();
                    }

                    if (tenant.tenant_id === this.$store.state.tenantId)
                        this.$store.commit("setTenantFeatures")
                })
                .catch((error) => {
                    tenant.enabled = !tenant.enabled
                    this.$toast.error(error.data)
                })
                .finally(() => {this.updatingId=null})
        },
    }
}
</script>