<template>
    <button
        type="button"
        class="btn me-1"
        title="Set Demographics"
        data-bs-toggle="modal"
        :data-bs-target="`#modalDemographicCharts${this.section.order}`"
        @click="this.setDemographicTypes"
        style="position: absolute; top: 6em; right: 1.5em;"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"><path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/></svg>
    </button>

    <div class="modal fade" :id="`modalDemographicCharts${this.section.order}`" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="tvpfilterCloseModal"></button>
                </div>
                <div class="modal-body px-md-5">
                    <h2 class="h5 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Display filter on TVP Variance</h2>
                    <label class="typo__label">Select By</label>
                    <multiselect @select="this.getValues" v-model="selectedOption" :options="['Demographics', 'Sub Categories']" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" :preselect-first="false"
                                 :disabled="applyingFilters"></multiselect>
                    <br>

                    <label class="typo__label">Select a Value</label>
                    <multiselect track-by="displayName" label="displayName" v-model="this.selectedValues" :options="valuesOption" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" :preselect-first="false"
                                 :disabled="applyingFilters"></multiselect>
                    <br>

                    <label class="typo__label">Select How Many Items to be Displayed</label>
                    <multiselect v-model="this.selectedNumber" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" :preselect-first="false"
                                 :disabled="applyingFilters"></multiselect>

                    <div v-if="Object.keys(this.$store.state.sections[this.index].filters.display_filters).length > 0" class="mt-3">
                        <hr>
                        <p class="fw-bolder">Applied Filter:  <span v-if="!applyingFilters" class="btn badge bg-danger badge-md" @click="removeFilter" :aria-disabled="applyingFilters" :disabled="applyingFilters"> Remove Filter</span></p>
                        <p> <span class="fw-medium">Filter by: </span> <span class="text-danger"> {{ this.$store.state.sections[this.index].filters.display_filters.by }}</span> </p>
                        <p> <span>Filtered Values: </span> <span  v-for=" val in this.$store.state.sections[this.index].filters.display_filters.values" class="badge bg-gray-600 me-2"> {{ val.enumValue }}</span> </p>
                        <p> <span>Filtered Item Count: </span> <span class="text-danger"> {{ this.$store.state.sections[this.index].filters.display_filters.count }}</span> </p>
                    </div>

                    <div v-if="removingFilters" class="text-center mt-3 d-flex align-items-center justify-content-center">
                        <span role="status" class="me-2 text-info fw-bolder align-items-center">Removing Filters</span>
                        <div class="spinner-grow spinner-grow-sm ms-1" aria-hidden="true"></div>
                        <div class="spinner-grow spinner-grow-sm ms-1" aria-hidden="true"></div>
                        <div class="spinner-grow spinner-grow-sm ms-1" aria-hidden="true"></div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                        <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            Cancel
                        </button>
                        <button class="btn btn-primary" type="button" @click="applyFilter" :disabled="applyingFilters || removingFilters">
                            <div v-if="applyingFilters" class="d-flex align-items-center">
                                <span role="status" class="me-2">Applying Filters</span>
                                <div class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                            </div>
                            <span v-else>
                                Apply Filters
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        props: ['section'],
        name: 'TVPFilter',
        data(){
            return{
                selectedOption: '',
                valuesOption: [],
                selectedValues: [],
                selectedNumber: 3,

                applyingFilters: false,
                removingFilters: false
            }
        },

        computed:{
          index(){
            return this.$store.state.sections.indexOf(this.section)
          }
        },

        methods:{
            async getValues(){
                this.selectedValues = []

                if (this.selectedOption === 'Demographics'){
                    await axios
                        .get(`api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/demographic-types/`)
                        .then((resp) => {
                            this.valuesOption = []
                            resp.data.forEach((obj) => {
                                this.valuesOption.push({
                                    displayName: obj['display_name'],
                                    enumValue: obj['enum_value']
                                })
                            })
                        })
                        .catch(err => console.log(err))
                }

                else if (this.selectedOption === 'Sub Categories'){
                    await axios
                        .get(`api/dynamic-reporting/survey/${this.$store.state.selectedSurveyID}/sub-categories/`)
                        .then((resp) => {
                            this.valuesOption = []
                            resp.data.forEach((obj) => {
                                this.valuesOption.push({
                                    displayName: obj['display_name'],
                                    enumValue: obj['enum_value']
                                })
                            })
                        })
                        .catch(err => console.log(err))
                }
            },

            async applyFilter() {
                if (this.selectedOption === '' || this.selectedValues.length < 1 || this.selectedOption === null){
                    this.$toast.error('Please Provide All Filter Options')
                    return
                }
                console.log(this.selectedOption)
                var tvpFilters = {
                    by: this.selectedOption,
                    values: this.selectedValues,
                    count: this.selectedNumber
                }

                this.$store.state.sections[this.index].filters.display_filters = tvpFilters
                var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
                const config = {
                    params: this.$store.getters.params
                };

                this.applyingFilters = true

                await axios
                    .get(url, config)
                    .then((response) => {
                        if (response.status === 200){
                            this.$refs.tvpfilterCloseModal.click()
                            this.$store.commit('setReportData', response.data)
                            this.selectedOption = ''
                            this.selectedValues = []
                            this.selectedNumber = 3
                        }

                        else{
                            this.$toast.info("No Responses Found for this Filter")
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {this.applyingFilters = false})
            },

            async removeFilter(){
                this.$store.state.sections[this.index].filters.display_filters = {}

                var url = "api/render-report-data/" + this.$store.state.selectedSurveyID + "/";
                const config = {
                    params: this.$store.getters.params
                };

                this.removingFilters = true

                await axios
                    .get(url, config)
                    .then((response) => {
                        if (response.status === 200){
                            this.$refs.tvpfilterCloseModal.click()
                            this.$store.commit('setReportData', response.data)
                            this.selectedOption = ''
                            this.selectedValues = []
                            this.selectedNumber = 3
                        }

                        else{
                            this.$toast.info("No Responses Found for this Filter")
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {this.removingFilters=false})
            }
        }
    }
</script>