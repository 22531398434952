<template>

  <div id="errorMessage" v-if="this.errorMessage.title">
    <div class="row mb-4 mb-lg-5"></div>
    <div class="row mb-4 mb-lg-5">
      <div class="col-md-1">
      </div>
      <div class="col-md-10">
        <div class="mt-5 mb-3 mt-sm-0">
          <span class="h3 fw-bolder">{{ this.errorMessage.title }}</span>
        </div>
        <p class="lead">{{ this.errorMessage.text }}</p>
      </div>
    </div>
  </div>
  <multiselect @select="this.loadSurvey()" v-if="!this.errorMessage.title && this.showLanguageSelector" v-model="selected" :options="languages" track-by="code" label="name" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select Language"></Multiselect>


  <div v-if="this.error_while_submitting === false" id="survey"> </div>
  
  <div v-else>
    <div class="row mb-4 mb-lg-5"></div>
      <div class="row mb-4 mb-lg-5">
        <div class="col-md-1">
        </div>
        <div class="col-md-10">
          <div class="mt-5 mb-3 mt-sm-0">
            <span class="h3 fw-bolder text-danger">Error</span>
          </div>
          <p class="lead text-danger">There was an error with your entry.  Please check that your browser is free of errors and/or malware and re-enter and submit your response</p>
        </div>
      </div>
  </div>
  
</template>

<script>
import "survey-core/defaultV2.min.css";
import axios from "axios";
import {Survey} from "survey-knockout-ui";
import {StylesManager, surveyLocalization} from "survey-core";
import "survey-core/survey.i18n";

StylesManager.applyTheme("defaultV2");
surveyLocalization.supportedLocales = ["en", "de", "es", "fr"];
export default {
  name: 'surveyPublicPage', mounted() {
    this.loadSurvey();
    document.title = `${this.tenant} Campaign`
  },
  computed:{
    tenant(){
      return localStorage.getItem("subdomain")
    }
  },
  data() {
    return {
      selected: '',
      languages: [
        { code: 'en', name: 'English'},
        { code: 'es', name: 'Espanol' }
      ],
      showLanguageSelector: true,
      errorMessage: {
        title: '', text:''
      },
      surveyJson: {},
      surveyResponse:{
        invitation_unique_id: '',
        data: {}
      },
      is_interest_profiler_survey: false,
      error_while_submitting: false
    }
  },

  methods: {

    escapeJSON(json) {
      var escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
      var meta = {    // table of character substitutions
        '\b': '\\b', '\t': '\\t', '\n': '\\n', '\f': '\\f', '\r': '\\r', '"': '\\"', '\\': '\\\\'
      };

      escapable.lastIndex = 0;
      return escapable.test(json) ? '"' + json.replace(escapable, function (a) {
        var c = meta[a];
        return (typeof c === 'string') ? c : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
      }) + '"' : '"' + json + '"';
    }, async loadSurvey() {

      const surveyUniqueID = this.$route.params.surveyUniqueID;
      this.surveyResponse.invitation_unique_id = surveyUniqueID;

      this.surveyJson = await axios
          .get("api/public/survey-invitation/" + surveyUniqueID + "/")
          .then((response) => {
            this.surveyJson = JSON.parse(this.escapeJSON(response.data.survey_data));
            const survey = new Survey(this.surveyJson);
            this.is_interest_profiler_survey = response.data.is_interest_profiler_survey
            survey.locale = this.selected.code;
            survey.onComplete.add(this.surveyComplete);
            survey.onCurrentPageChanged.add(this.handleLanguageSelectorVisibility);
            survey.render("survey");
          })
          .catch((error) => {
            if(error.response === undefined || error.response.data === undefined
                || error.response.data.detail === undefined){
              this.errorMessage.title = "Survey not available"
              this.errorMessage.text = "Failed to render the survey"
            }
            else if(error.response.data.detail === 'invitation_link_has_expired') {
              this.errorMessage.title = "Survey closed!"
              this.errorMessage.text = "This survey is no longer accepting the responses!"
            } else{
              this.errorMessage.title = "Survey not available"
              this.errorMessage.text = "This link is invalid"
            }
          });
      console.log(this.surveyJson)
    },

    surveyComplete(json) {
      let request = {};
      request["invitation_unique_id"] = this.surveyResponse.invitation_unique_id
      request["data"] = JSON.stringify(json.data)
      axios.post("api/public/survey-response/", request)
        .then((response) => {
          console.log("survey submitted, response status:" + response.status)
          console.log(this.is_interest_profiler_survey)
          if (this.is_interest_profiler_survey){
            this.$router.push({
              path: `/report/${response.data.unique_id}/`
            })
          }
        })
        .catch(resp => this.error_while_submitting = true)
    },
    handleLanguageSelectorVisibility(_,options){
      this.showLanguageSelector = _.currentPageNo === 0;
    }
  },
}
</script>