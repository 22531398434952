<template>
    <section class="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
      <div class="container">

        <div class="row justify-content-center form-bg-image " >
          <div class="col-5 d-flex align-items-center justify-content-center "  >
            <div class="shadow border-0 border-light p-4 w-100 loginbg" >
              <div class="text-center text-md-center mb-4 mt-4 mt-md-0 ">
                <p class="text-center mt-5">
                  <img :src="`../assets/img/logo/${tenant}/logo.svg`" style="width: 6%;" :alt="`${tenant}logo`">
                </p>
                <h1 class="mb-1 h4 link-gray-800">Reset Password</h1>
              </div>

              <h6 v-if="fromLogin" class="text-center h-5 mb-4"> Request an Email Verification Link </h6>
              <h6 v-if="invalidToken" class="text-center h-5 mb-4"> The link is invalid or has expired. Please make sure you're using the password reset link from the most recent email. Or Request a new Verification Link. </h6>

              <div>
                <div v-if="fromLogin || invalidToken">
                  <div class="form-group">
                    <div class="form-group mb-4 m-auto">
                      <label for="Username" class="fw-bolder">Email</label>
                      <div class="input-group">
                        <input
                            @focus="userNotFound = false; noUsername = false"
                            type="email"
                            class="form-control"
                            id="email"
                            autofocus
                            required
                            v-model="email"
                        />
                      </div>
                      <div v-if="noUsername" class="text-center mt-2"><span class="text-danger">Please enter the Email</span></div>
                    </div>
                  </div>
                  <div class="d-grid">
                    <button @click="sendLink" :disabled="sending" type="submit" class="btn m-auto col-5 " style="background-color: #A01850; color: white; border-radius: 0;"> <span v-if="!sending"> Send Link</span><div v-else class="spinner-border" role="status"></div></button>
                  </div>
                </div>

                <div v-else>
                  <div class="form-group mb-4 m-auto">
                    <label for="New Password">New Password</label>
                    <div class="input-group">
                      <input type="password" placeholder="Password" class="form-control" v-model="password" required/>
                    </div>
                  </div>

                  <div class="form-group mb-4 m-auto">
                    <label for="Confirm Password">Confirm Password</label>
                    <div class="input-group">
                      <input type="password" placeholder="Password" class="form-control" v-model="confirmPassword" required/>
                    </div>
                  </div>
                  <div class="d-grid">
                    <button @click="resetPassword" :disabled="sending" type="submit" class="btn m-auto col-5 " style="background-color: #A01850; color: white; border-radius: 0;"><span v-if="!sending">Reset Password</span><div v-else class="spinner-border" role="status"></div></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

<script>
import axios from 'axios';

export default{
    name: 'ForgetPassword',
    data(){
        return{
            token: this.$route.query.token,
            email: '',
            code: '',

            sending: false,
            sent: false,

            userNotFound: false,
            noUsername: false,

            password: null,
            confirmPassword: null,

            fromLogin: false,
            invalidToken: false,

        }
    },
    beforeMount(){
      if(this.token === undefined || this.token === null){
        this.fromLogin = true
      }

      else{
        this.verifyToken()
          .finally(() => {
            if (this.invalidToken) window.history.replaceState(null, null, this.$route.path)
          })
      }
    },
    computed:{
      tenant(){
          return localStorage.getItem("subdomain")
      }
    },

    methods: {
      async sendLink(){
        if (this.email == null || this.email === '' || this.email === undefined){
          this.noUsername = true;
          return
        }

        this.noUsername = false;
        this.sending = true;
        this.userNotFound = false;
        await axios
          .get(`api/send-verification-link/?email=${encodeURIComponent(this.email)}&subdomain=${localStorage.getItem('subdomain')}`)
          .then(resp => {
            if (resp.status == 200) {
              this.$toast.success("Password reset mail has been sent to your mail successfully, please check the spam folder as well.")
              this.sent = true;
              this.$router.replace({name: 'login'})
            }
          })
          .catch(err => {
            console.log(err.response.status == 404)
            if (err.response.status == 404){
              this.$toast.error("Error. Please try again")
            }
            else if (err.response.status == 500){
              this.$toast.error("Unknown Error occurred while sending the verification code")
              this.$router.replace({name: 'forgetPassword'})
            }
          })
          .finally(() => {this.sending = false})

      },

      async verifyToken(){
        this.token.replace(' ', '')
        if (this.token.length < 30){
          this.invalidToken = true;
          return
        }
        await axios
        .post('api/verify-link/', {'token': this.token})
        .catch(err => {
          if (err.response.status === 400 || err.response.status === 404){
            this.invalidToken = true
          }
        })
      },

      async resetPassword(){
        if (this.password == null || this.password.length < 7 || this.password === ""){
          this.$toast.error("password must be of atleast 7 characters")
          return
        }

        if (this.password !== this.confirmPassword){
          this.$toast.error("password and confirm Password Doeasn't match")
          return
        }

        this.sending = true

        await axios
          .post('api/change-password-from-verification-link/', {'token': this.token, 'password': this.password})
          .then(resp => {
            if (resp.status === 201) {
              this.$toast.success("password changed successfully")
              this.$router.replace({name: 'login'})
            }
          })
          .catch(err => {
            this.$toast.error("Unknown error occurred")
            this.$router.replace({name: 'login'})
          })
          .finally(() => this.sending = false)
      }
    }
}
</script>