<template>
    <div class="card border-0 shadow">
        <div class="card-header border-bottom d-flex align-items-center justify-content-between">
            <h2 class="fs-5 fw-bold mb-0"> All Tenants </h2>

            <a data-bs-toggle="modal" data-bs-target="#modalCreateTenant" class="btn btn-sm btn-tertiary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="icon icon-xxs me-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>
                Create Tenant
            </a>
        </div>

        <div class="modal fade" id="modalCreateTenant" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModalCreateTenant"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center fw-bolder text-tertiary pb-3 mb-3">Create Tenant</h2>

                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="addTenantForm.name" placeholder="name@example.com">
                    <label for="floatingInput">Name of the Tenant</label>
                </div>

                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="addTenantForm.context_path" placeholder="name@example.com">
                    <label for="floatingInput">Context Path</label>
                </div>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="addTenantForm.active">
                    <label class="form-check-label">
                        Active
                    </label>
                </div>

                <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                    <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModalCreateTenant" >
                        Cancel
                    </button>
                    <button class="btn btn-info" type="button" @click="createTenant">
                        Create Tenant
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div v-if="isLoading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
            <ul v-else class="list-group list-group-flush list my--3">
                <li v-if="tenants.length" v-for="tenant in tenants" class="list-group-item p-3 m-1 border" :key="tenant">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <p class="mb-0">
                                <a href="#"> {{ tenant.name }} </a>
                            </p>
                        </div>

                        <div class="col text-end">

                            <button @click="selectedTenant={...tenant}" class="btn btn-sm btn-gray-700 d-inline-flex align-items-center me-2" data-bs-toggle="modal" data-bs-target="#modalEditTenant">
                                <svg class="icon icon-xxs me-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/></svg>
                                Edit
                            </button>

                        </div>
                    </div>
                </li>

                <li v-else class="list-group-item text-center text-danger fw-bolder">
                    You Don't have any Tenant!
                </li>
            </ul>
        </div>

        <div class="modal fade" id="modalEditTenant" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModalEditTenant"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Edit Survey Template</h2>
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="selectedTenant.name" placeholder="name@example.com">
                    <label for="floatingInput">Tenant Name</label>
                </div>
                
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="selectedTenant.context_path" placeholder="name@example.com">
                    <label for="floatingInput">Context path</label>
                </div>

                <br>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="selectedTenant.active">
                    <label class="form-check-label">
                        Active
                    </label>
                </div>

                <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                  <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModalEditTenant" >
                    Cancel
                  </button>
                  <button class="btn btn-primary" type="button" @click="updateTenant">
                    Update Tenant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default{
    name: 'ManageTenant',
    data(){
        return {
            tenants: [],
            selectedTenant: {},
            addTenantForm: {
                name: null,
                active: true,
                context_path: " "
            },
            isLoading: true,
        }
    },
    beforeMount(){
        this.getTenants()
    },
    methods: {
        async getTenants(){
            await axios
                .get('api/tenant/')
                .then((resp) => { this.tenants = resp.data.results })
                .catch((err) => { this.$toast.error("An error occured") })
                .finally(() => {
                    this.isLoading = false
                })
        },

        async updateTenant(){
            if (!this.validateContextpath(this.selectedTenant)){
                return
            }

            await axios
                .put(`api/tenant/${this.selectedTenant.id}/`, this.selectedTenant)
                .then((resp) => {
                    this.getTenants()
                    this.$toast.success("Tenant Updated")
                    this.$refs.closeModalEditTenant.click()
                })
                .catch(err => this.$toast.error("An error Occured"))
        },

        validateContextpath(form){
            if (form.name === null || form.name === ''){
                this.$toast.error("Please provide a name for Tenant")
                return false
            }

            if (form.context_path === null || form.context_path === ''){
                this.$toast.error("Please provide a context path")
                return false
            }

            if (!(/^[a-zA-Z\-]+$/.test(form.context_path))){
                this.$toast.error("context path should only contain alphabets and hyphens")
                return false
            }

            if (form.context_path.startsWith("-") || form.context_path.endsWith("-")){
                this.$toast.error("context path should not start or ends with hyphens")
                return false
            }
            return true
        },

        async createTenant(){
            if (!this.validateContextpath(this.addTenantForm)){
                return
            }

            await axios
                .post('api/tenant/', this.addTenantForm)
                .then(resp => {
                    this.$toast.success("Successfully created new Tenant")
                    this.$refs.closeModalCreateTenant.click()
                    this.getTenants()
                })
        }
    }
}
</script>