import { labelHoverAbove, labelHoverBelow, fixLabelByTrimming, fixLabelByTrimmingAndNewLines, shuffleArray, fixLabelTVPDisparitiesByTrimming } from "./common";
import { replaceChartByImage } from "./replaceImage";
export const tvpVarianceConfigCreator = (obj, chartId) => {
  Chart.register(ChartDataLabels);
  const legendSize = 15;
  const legendPadding = 10;
  const legendBoxWidth = 25;
  const titleSize = 25;
  const labelSize = 12;
  const pointRadius = 13;
  const xtickSize = 15;
  const ytickSize = 15;
  const aspectRatio= 4;
  const barThickness= 100

  const floatingTest = {
    opacity: 0.7,
    display: "auto",
    color: "black",
    formatter: function (value, context) {
      return value.x;
    },
    font: {
      family: "Montserrat",
      size: labelSize,
      color: "black",
      weight: "normal",
      style: 'normal'
    },
  };

  // obj.data.forEach((item, index) => {

  //   item.label = fixLabelByTrimming(item.label);
  // });

  const backgroundColorList = [
    "#ea4b4b",
    "#b8e1e9",
    "#ffcfcf",
    "#1882a0",
    "#A1D2CE",
    "#48A9A6",
    "#a25a77",
    "#999999",
    "#575A4B",
  ];

  let receivedDatasets = [];

  obj.data[0].dataValue.forEach((nematicValue, index) => {
    receivedDatasets.push({
      label: fixLabelByTrimming(obj.data[0].label[index]),
      backgroundColor: backgroundColorList[index],
      borderColor: "black",
      data: [parseFloat(nematicValue).toFixed(2)],

      type: "scatter",
      pointRadius: pointRadius,
      pointStyle: "circle",
      borderWidth: 1,
      datalabels: floatingTest,
    });
  });

  const data = {
    labels: ["Average Score"],

    datasets: [
      ...receivedDatasets,
      // this is the line chart data

      // {
      //   label: "Agender",
      //   data: [3.59],
      //   borderColor: "black",
      //   // backgroundColor: "rgb(114,150,206, 0.8)",
      //   backgroundColor: "#fff8efff",

      //   type: "scatter",

      //   pointStyle: "circle",
      //   pointRadius: 22,
      //   // datalabels: labelHoverAbove,
      //   datalabels: floatingTest,
      // },
      // {
      //   label: "Cisgender Female",
      //   data: [3.7],
      //   borderColor: "black",
      //   // convert ea4b4b to rgb
      //   // backgroundColor: "rgb(234, 75, 75, 0.8)",
      //   backgroundColor: "#ffcfcfff",

      //   type: "scatter",
      //   pointStyle: "circle",
      //   pointRadius: 22,
      //   // datalabels: labelHoverAbove,
      //   datalabels: floatingTest,
      // },
      // {
      //   label: "Transgender male",
      //   data: [2.0],
      //   borderColor: "black",
      //   backgroundColor: "#b8e1e9",
      //   type: "scatter",
      //   pointStyle: "circle",
      //   pointRadius: 22,
      //   datalabels: floatingTest,

      //   // datalabels: labelHoverAbove,
      //   // backgroundColor: "#CB3C94",
      //   // backgroundColor: "rgb(203,60,148, 0.8)",
      // },
      // {
      //   label: "Group 4",
      //   datalabels: floatingTest,
      //   data: [3.9],
      //   borderColor: "black",
      //   backgroundColor: "#ea4b4b",
      //   type: "scatter",
      //   pointStyle: "circle",
      //   pointRadius: 22,
      //   // return label with data value
      //   // label: function (context) {
      //   //   console.log(context);
      //   //   return "Personal Score 3" + " " + context.data.x;
      //   // },
      //   // backgroundColor: "#EA4C4C",
      //   // backgroundColor: "rgb(234,76,76, 0.8)",
      //   // order: 0,
      //   // datalabels: labelHoverAbove,
      // },

      // --------------------------------------------------
      // This is bar chart
      {
        type: "bar",
        label: "Organization Needs Improvement",
        backgroundColor: "rgb(55,56,58)",
        borderColor: "rgb(55,56,58)",
        data: [1],
        barThickness: barThickness,

        // datalabels: labelHoverAbove,
        datalabels: {
          display: false,
          color: 'black',
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            return context.dataset.label;
          },

          font: {
            family: "Montserrat",
            weight: "bold",
            size: 20,
            style: 'normal'
          },
        },
      },
      {
        type: "bar",
        label: "Organization Needs Improvement",
        backgroundColor: "#1863df",
        borderColor: "#1863df",
        data: [2],
        barThickness: barThickness,

        // datalabels: labelHoverAbove,
        datalabels: {
          display: false,
          color: 'black',
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            return context.dataset.label;
          },


          font: {
            family: "Montserrat",
            weight: "bold",
            size: 20,
            style: 'normal',
          },
        },
      },
      {
        label: "Good",
        data: [3],
        backgroundColor: "#9F1D51",
        borderColor: "#9F1D51",
        barThickness: barThickness,
        // datalabels: labelHoverBelow,
        datalabels: {
          display: false,
          color: 'black',
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            return context.dataset.label;
          },

          font: {
            family: "Montserrat",
            weight: "bold",
            size: 20,
            style: 'normal',
          },
        },
      },
      {
        label: "Great",
        data: [4],
        backgroundColor: "#E99B23",
        borderColor: "#E99B23",
        barThickness: barThickness,
        // datalabels: labelHoverBelow,
        datalabels: {
          display: false,
          color: 'black',
          anchor: "end",
          align: "start",
          offset: 30,
          // set formatter function
          formatter: function (value, context) {
            return context.dataset.label;
          },

          font: {
            family: "Montserrat",
            weight: "bold",
            size: 20,
            style: 'normal',
          },
        },
      },
      {
        label: "Excellent",
        data: [5],
        backgroundColor: "#ffeacf",
        borderColor: "#ffeacf",
        barThickness: barThickness,
        // datalabels: labelHoverBelow,
        datalabels: {
          display: false,
          color: 'black',
          anchor: "end",
          align: "start",
          offset: 30,
          // set formatter function
          formatter: function (value, context) {
            // return dataset lable
            return context.dataset.label;
          },

          font: {
            family: "Montserrat",
            weight: "bold",
            size: 20,
            style: 'normal',
          },
        },
      },
    ],
  };

  const options = {
    font: {
      family: "Montserrat",
    },
    animation: true,
    maintainAspectRatio: true,
    aspectRatio: aspectRatio,
    responsive: true,
    indexAxis: "y",

    // edit element type and make it smaller
    elements: {
      bar: {
        // borderWidth: 8,
        inflateAmount: 25,
      },
    },

    plugins: {
      title: {
        display: true,
        color: "black",
        font: {
          family: "Montserrat",
          weight: "400",
          size: titleSize,
          style: 'normal',
        },
        text: obj.title,
      },
      legend: {
        reverse: true,
        labels: {
          color: "black",
          boxWidth: legendBoxWidth,
          padding: legendPadding,
          font: {
            family: "Montserrat",
            size: legendSize,
            style: 'normal',
            weight: "100",
          },
          formatter: () => {
            return "Here is text";
          },
          filter: function (item, chart) {
            // item.fillStyle = "red";
            item.text =
              item.text + ": " + chart.datasets[item.datasetIndex].data[0];
            return (
              !item.text.includes("Great") &&
              !item.text.includes("Excellent") &&
              !item.text.includes("Good") &&
              !item.text.includes("Organization Needs Improvement")
            );
          },
        },
      },
    },

    scales: {
      xline: {
        display: false,

        // this x-axis applies to the scatter chart
        min: 0,
        max: 5,

        // y: {
        //   font: {
        //     family: "Montserrat",
        //     weight: "bold",
        //     size: 20,
        //   },

        //   min: 0,
        //   max: 5,
        // },
        // x: {
        //   font: {
        //     family: "Montserrat",
        //     weight: "bold",
        //     size: 20,
        //   },
        //   min: 0,
        //   max: 5,
        // },
      },
      // yline: {
      //   display: false,
      //   min: 0,
      //   max: 0,
      // },

      x: {
        min: 0,
        max: 5,
        stacked: true,

        ticks: {
          font: {
            family: "Montserrat",
            size: xtickSize,
            weight: "normal",
            style: 'normal',
          },
          padding: 0,
          stepSize: 1,
          callback: function (value, index, values) {
            return value === 0 ||
              value === 1 ||
              value === 2 ||
              value === 3 ||
              value === 4 ||
              value === 5
              ? value
              : "";
          },
          color: "black",
        },
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: false,
        },
      },
      y: {
        display: true,
        ticks: {
          display: true,
          color: "black",
          font: {
            weight: "normal",
            family: "Montserrat",
            size: ytickSize ,
            style: 'normal',
          },
        },
        font: {
          family: "Montserrat",
          weight: "normal",
          size: 20,
          style: 'normal',
        },
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },

    datalabels: {
        color: 'black'
    }
  };

  const tvpChartConfig = {
    type: "bar",
    data: data,
    options: options,
  };
  var canvas = document.getElementById(chartId)
  var ctx = canvas.getContext("2d");
  ctx.imageSmoothingEnabled = true;
  const BarLineChart = new Chart(ctx, tvpChartConfig);

};


export const barLineCreator = (config, chartId, isFiltersApplied=false, line_data=[3.99, 3.78, 3.81, 3.50, 3.84], aspectRatio=2, pointRadius=10, for_variance=false) => {
  let xAxisFontSize = 18;
    let yAxisFontSize = 18;
    let titleSize = 15;
    let legendSize = 18;
    
    var label = 'Average score amongst other organizations'

    if (isFiltersApplied){
      label = 'Average for other Organizations - All Demographics'
    }

    if (for_variance){

      let labels_count = line_data.length
      aspectRatio = (labels_count>4 ? 1.1 : labels_count>2 ? 0.9 : 0.8)

      if (labels_count > 4){
        xAxisFontSize = 14;
        yAxisFontSize = 14;
        legendSize = 14;
      }
      
    }
      
      // let newLineData = config.labels.map(label => fixLabelByTrimmingAndNewLines(label))
    const backgroundColorList = ["#ffcfcfff", "#fff8efff", "#b8e1e9", "#ea4b4b"];
    let receivedDatasets = [];

    config.labels = config.labels.map(l=> fixLabelTVPDisparitiesByTrimming(l));
    const data = {
      // labels: ["Attract", "Grow", "Engage", "Reward", "Assess"],
      labels: config.labels,
      datasets: [
        //* line data
        {
          type: "line",
          label: for_variance ? "Variance" : label,
          backgroundColor: "#999999",
          borderColor: "#999999",

          // hover not used for this pdf.
          // hoverBorderWidth: 1,
          // hoverRadius: 8,
          // hoverBorderColor: "black",

          pointRadius: pointRadius,
          pointBorderColor: "black",
          // Reference data for: Average among other organizations - on Total Value Proposition slide
          // - [ ] Attract: 3.99
          // - [ ] Grow: 3.78
          // - [ ] Engage: 3.81
          // - [ ] Reward: 3.50
          // - [ ] Assess: 3.84
        data: line_data,
        },
        {
          // bar data
          //   type: "bar",
          label: "Your Score",
          backgroundColor: for_variance ? 
            shuffleArray([ "#1863df", "#E99B23", "#9F1D51", "#ffeacf", "#37383A"]) 
            : [ "#1863df", "#E99B23", "#9F1D51", "#ffeacf", "#37383A"],
          // borderColor: ["#1863df", "#E99B23", "#9F1D51", "#ffeacf", "#37383A"],
          // data: [4.6, 4.5, 3.6, 3.56, 3.46],
          data: config.data || [4.6, 4.5, 3.6, 3.56, 3.46],
          //   categoryPercentage: 0.5,
          //   barPercentage: 0.8,
        },

      ],
    };

    const options = {
      // maintainAspectRatio: false,
      animation: false,
      responsive: true,
      maintainAspectRatio: true,
      indexAxis: "x",
      aspectRatio: aspectRatio,

      font: {
        // family: "Montserrat",
        weight: "normal",
        // size: 10,
      },

      title: {
        display: true,
        text: "Hello world",
        color: "black",
        font: {
          family: "Montserrat",
          weight: "normal",
          size: titleSize,
          style: 'normal',
        },
      },

      plugins: {
        datalabels: {
          display: false,
          color: "black",
        },
        legend: {
          labels: {
            color: "black",
            font: {
              family: "Montserrat",
              weight: "normal",
              size: legendSize,
              style: 'normal',

            },
            usePointStyle: true,
            pointRadius: 10,
            filter: function (item, chart) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("Your Score");
            },
          },
        },
      },

      scales: {
        x: {
          ticks: {
            color: "black",
            font: {
            family: "Montserrat", weight: "normal", size: xAxisFontSize, style:'normal'
            },
          },
          
        },
        y: {
          min: 0,
          max: 5,

          stacked: true,
          ticks: {
            color: "black",
            stepSize: 1,
            font: {
            family: "Montserrat",
            weight: "normal",
            size: yAxisFontSize,
            style: 'normal',
            },
          },
          grid: {
            display: false,
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
        },
      },
    };

    const barLineConfig = {
      type: "bar",
      data: data,
      options: options,
    };
    var canvas = document.getElementById(chartId)
    var ctx = canvas.getContext("2d");
    ctx.imageSmoothingEnabled = true;
    const BarLineChart = new Chart(ctx, barLineConfig);
};