<!-- Previous Login Page  -->

<template>
  <NewSideBar @sidebar-click="setActivePage"/>

  <section v-if="active==='campaign'" class="content">
    <nav
        class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0"
    >
      <div class="container-fluid px-0">
        <div
            class="d-flex justify-content-between w-100"
            id="navbarSupportedContent"
        >
          <div class="d-flex align-items-center">
            <!-- Search form -->

            <!-- / Search form -->
          </div>
          <!-- Navbar links -->
          <ul class="navbar-nav align-items-center">
            <li class="nav-item dropdown ms-lg-3">
              <a class="nav-link dropdown-toggle pt-1 px-0" href="#" role="button" data-bs-toggle="dropdown"
                 aria-expanded="false">
                <div class="media d-flex align-items-center">
                  <img class="avatar rounded-circle" alt="Image placeholder" src="../assets/img/icons/user-ico.png">
                  <div class="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span class="mb-0 font-small fw-bold text-gray-900">{{this.username}}</span>
                  </div>
                </div>
              </a>
              <div class="dropdown-menu dashboard-dropdown  mt-2 py-1">

                <a v-if="this.$store.state.isSwitched" class="dropdown-item d-flex align-items-center" @click="this.switchBack()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="dropdown-icon text-tertiary me-2" fill="currentColor" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/></svg>
                  Switch back
                </a>

                <a  class="dropdown-item d-flex align-items-center" @click="logout();">
                  <svg class="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
    >
      <div class="d-block mb-4 mb-md-0">
<!--       <nav aria-label="breadcrumb" class="d-none d-md-inline-block">-->
<!--         <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">-->
<!--           <li class="breadcrumb-item">-->
<!--             <a href="#">-->
<!--               Edgility-->
<!--&lt;!&ndash;               TODO add the clientName here &ndash;&gt;-->
<!--             </a>-->
<!--           </li>-->
<!--           <li class="breadcrumb-item"><a href="#">Client</a></li>-->
<!--           <li class="breadcrumb-item active" aria-current="page">-->
<!--             Campaign Name-->
<!--&lt;!&ndash;             TODO add the campaignName here&ndash;&gt;-->
<!--           </li>-->
<!--           <li class="breadcrumb-item active" aria-current="page">-->
<!--             {{ surveys.page }}-->
<!--           </li>-->
<!--         </ol>-->
<!--       </nav>-->
        <h2 class="h4">Campaigns</h2>
        <p class="mb-0">Select a Campaign</p>
      </div>
      <div class="btn-toolbar mb-2 mb-md-0">
        <button
            v-if="userPermissions.canCreateCampaign"
            type="button"
            class="btn btn-block btn-primary mb-3"
            data-bs-toggle="modal"
            data-bs-target="#modalSignIn"
            @click="loadCreateCampaignModalData();">
          New Campaign
        </button>
      </div>
    </div>
    <div class="table-settings mb-4">
      <div class="row align-items-center justify-content-between">
<!--        <div class="col col-md-6 col-lg-3 col-xl-4">-->
<!--          <div class="input-group me-2 me-lg-3 fmxw-400">-->
<!--            <span class="input-group-text">-->
<!--              <svg-->
<!--                  class="icon icon-xs"-->
<!--                  x-description="Heroicon name: solid/search"-->
<!--                  xmlns="http://www.w3.org/2000/svg"-->
<!--                  viewBox="0 0 20 20"-->
<!--                  fill="currentColor"-->
<!--                  aria-hidden="true"-->
<!--              >-->
<!--                <path-->
<!--                    fill-rule="evenodd"-->
<!--                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"-->
<!--                    clip-rule="evenodd"-->
<!--                ></path>-->
<!--              </svg>-->
<!--            </span>-->
<!--            <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                placeholder="Search campaigns"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

        <!-- Modal Content -->
        <div
            class="modal fade"
            id="modalSignIn"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref="closeModal"
                ></button>
              </div>
              <div class="modal-body px-md-5">
                <h2 class="h4 text-center fw-bolder text-tertiary border-2 border-bottom pb-3 mb-3">Create a New Campaign</h2>
                <p class="text-center mb-4">
                  Selections with an asterisk (*) are required.
                </p>
                <label for="textarea">Campaign Name*:</label>
                <textarea
                    class="form-control"
                    placeholder=""
                    id="textarea"
                    rows="1"
                    style="resize: none"
                    v-model="createCampaignForm.campaignName"
                ></textarea>


                <label for="expire-date" class="mt-2"
                >Survey Expiration Date*:</label
                >

                <div class="input-group">
                  <VueDatePicker v-model="createCampaignForm.expirationDate" id="expirationDate" :format="format_the_date" ></VueDatePicker>
                </div>

                <label for="textarea" class="mt-2">Client*:</label>

                <select
                    class="form-select mb-3 w-100 form-control bg-gray-300"
                    id="org-select "
                    name="orgSelect"
                    v-model="createCampaignForm.organization"
                >
                  <option>Select a client</option>
                  <option v-for="org in organizations" v-bind:value="org.id">
                    {{ org.name }}
                  </option>
                </select>

                <div class="mb-2">
                  <label class="my-1 me-2 mt-2" for="template"
                  >Campaign Template</label
                  >
                  <select
                      class="form-select mb-3 w-100 form-control bg-gray-300"
                      id="template-select " v-model="createCampaignForm.campaignTemplate"
                      name="template" @change="this.loadDynamicQuestions()"
                  >
                    <option value="0">Select a campaign template</option>
                    <option v-for="template in surveyTemplates" v-bind:value="template.id">
                      {{ template.title }}
                    </option>
                  </select>
                </div>

                <div id="dynamicQuestions" v-if="dynamicQuestions.length">
                  <div class="mb-2">
                    <p class="my-1 me-2 mt-2 text-center fw-bolder" style="font-size: .8em" for="template">Customize answers below </p> <p class="my-1 me-2 mt-2 text-center" style="font-size: .87em"> (add the character: | followed by the Spanish answer text if required)</p>

                    <div v-for="(question, index) in this.createCampaignForm.dynamicQuestionOptions">

                      <div class="mb-2">
                        <label class="my-1 me-2 mt-2" for="template"> {{ question.title }}</label>

                        <div style="margin-left: 1em; font-size: 80%;">
                          <div v-for="(option, optionIndex) in question.options">

                            <label for="textarea" class="mt-2 fw-bolder">Answer #{{ optionIndex + 1 }}</label>
                            <textarea class="form-control-sm" :placeholder="question.title.includes('role') ? 'Ex: Assistant Professor | Profesor asistente' : 'Ex: Red Team | Equipo Rojo'" id="textarea" rows="1"
                                      style="resize: none;"
                                      v-model="question.options[optionIndex]"></textarea>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div
                    class="d-flex align-items-center justify-content-end gap-4"
                >
                  <button class="btn btn-outline-primary"
                          type="button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref="closeModal" >
                    Cancel
                  </button>
                  <button class="btn btn-sm btn-primary" type="button" @click="this.create_campaign();">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="col-4 col-md-2 col-xl-1 ps-md-0 text-end">-->
<!--          <div class="dropdown">-->
<!--            <button-->
<!--                class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-1"-->
<!--                data-bs-toggle="dropdown"-->
<!--                aria-haspopup="true"-->
<!--                aria-expanded="false"-->
<!--            >-->
<!--              <svg-->
<!--                  class="icon icon-sm"-->
<!--                  fill="currentColor"-->
<!--                  viewBox="0 0 20 20"-->
<!--                  xmlns="http://www.w3.org/2000/svg"-->
<!--              >-->
<!--                <path-->
<!--                    fill-rule="evenodd"-->
<!--                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"-->
<!--                    clip-rule="evenodd"-->
<!--                ></path>-->
<!--              </svg>-->
<!--              <span class="visually-hidden">Toggle Dropdown</span>-->
<!--            </button>-->
<!--            <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end pb-0">-->
<!--              <span class="small ps-3 fw-bold text-dark">Show</span>-->
<!--              <a-->
<!--                  class="dropdown-item d-flex align-items-center fw-bold"-->
<!--                  href="#"-->
<!--              >10-->
<!--                <svg-->
<!--                    class="icon icon-xxs ms-auto"-->
<!--                    fill="currentColor"-->
<!--                    viewBox="0 0 20 20"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                >-->
<!--                  <path-->
<!--                      fill-rule="evenodd"-->
<!--                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"-->
<!--                      clip-rule="evenodd"-->
<!--                  ></path>-->
<!--                </svg>-->
<!--              </a>-->
<!--              <a class="dropdown-item fw-bold" href="#">20</a>-->
<!--              <a class="dropdown-item fw-bold rounded-bottom" href="#">30</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="input-group mb-2" style="width: 30em;">
        <input type="text" class="form-control" id="exampleInputIconRight" placeholder="Search" aria-label="Search" v-model="searchedFor">
        <span class="input-group-text btn bg-primary" id="basic-addon2" @click="load_surveys">
          <svg class="icon icon-xxs" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
        </span>
    </div>


    <SurveyList :surveys="surveys" :key="isSwitched"
                @csv-clicked="Download_csv"
                @report-csv-clicked="downloadCsvReport"
                @report-pdf-clicked="downloadPdfReport"
                @explore-click="exploreReport"
                @load-page-clicked="loadSpecificPage"/>
  </section>

  <section id="explore-section" class="content" v-if="active!=='campaign'">
    <div style="height: 30px"></div>

        <GlobalFilters :surveys="surveys" :key="this.$store.state.reportData.survey_data" v-if="this.$store.state.reportData.survey_data &&
        active !== 'manage-users' && active !== 'manage-client' && active !=='manage-campaign-templates' && active !== 'manage-tenant' && active !== 'manage-features' && active !== 'manage-user-features'" />

    <RouterView :key="this.$store.state.sections" />

    <footer class="bg-white rounded shadow p-5 mb-4 mt-4">
        <div class="row">
        </div>
    </footer>
  </section>

</template>

<script>
import axios from "axios";
import {saveAs} from "file-saver";
import NewSideBar from "@/components/NewSideBar.vue";
import SurveyList from "@/components/SurveyList.vue";
import ParticipantsBreakdown from "@/components/ParticipantsBreakdown.vue";
import NPSBreakdown from "@/components/NPSBreakdown.vue";
import TVPVariance from "@/components/TVPVariance.vue";
import TVPLowlights from "@/components/TVPLowlights.vue";
import TVPHighVariance from "@/components/TVPHighVariance.vue";
import TVPLowVariance from "@/components/TVPLowVariance.vue";
import TVPHighlights from "@/components/TVPHighlights.vue";
import PillarsEquity from "@/components/PillarsEquity.vue";
import NextStep from "@/components/NextStep.vue";
import ComparativeChart from "@/components/ComparativeChart.vue";
import router from "@/router";
import { mapState } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import GlobalFilters from "@/components/GlobalFilters.vue";
import { ref } from "vue";


const createCampaignInit = {
  campaignName: '', expirationDate: '', organization: '', campaignTemplate: '', dynamicQuestionOptions: []
}

export default {
  name: "Dashboard",
  components: {
    NewSideBar,
    SurveyList,
    VueDatePicker,
    ParticipantsBreakdown,
    NPSBreakdown,
    TVPVariance,
    TVPLowVariance,
    TVPHighVariance,
    TVPLowlights,
    TVPHighlights,
    PillarsEquity,
    NextStep,
    GlobalFilters,
    ComparativeChart
  },


  computed: {
    ...mapState(['active', 'hasReportData', 'reportData', 'tenantFeatures']),
    userPermissions(){
      return this.$store.state.userPermissions
    },
    username(){
      return this.$store.state.username;
    },
    tenant(){
      return localStorage.getItem("subdomain")
    }
  },

  errorCaptured(err, instance, info){
    this.$toast.error("Something Went Wrong! Please see log for more information.");
    console.error(err)
    this.$router.go(-1)
    return false
  },

  watch: {
    userPermissions(oldValue, newValue) {
      this.surveys = []
      this.load_surveys()
    }
  },

  data() {
    return {
      surveys: [], query: "", organizations: [], surveyTemplates: [], reportData: {},
      selectedOrg: '', dynamicQuestions: [], createCampaignForm: createCampaignInit,
      searchedFor: '',
    };
  },
  mounted() {
    if(this.$store === undefined || localStorage.getItem('token') === null){
      this.$router.push('/login')
      return
    }
    this.$store.commit('setReportData', {});
    this.$store.commit('setActivepage', 'campaign')
    this.$store.commit('setHasReportData', false)
    document.title =  `${this.tenant} Portal`;
    this.$store.commit("setTenantFeatures")
    this.$store.commit("setUserFeatures")
    this.load_surveys();
  },
  methods: {
    format_the_date(date){
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
    async load_surveys() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get("api/survey/?search=" + this.searchedFor)
        .then((response) => {
          this.surveys = response.data;
        })
        .catch((error) => {
          this.$toast.error("error loading the campaigns data")
          console.error("failed to load surveys")
          console.log(error);
        });
        this.$store.commit('setIsLoading', false)
    },
    logout() {
      this.$store.state.username = '';
      localStorage.setItem("token", "");
      this.$toast.success("Logged out successfully")
      router.replace('/login')
    },
    async loadCreateCampaignModalData() {
      this.resetCampaignData()
      axios
          .get("api/survey-templates-list/")
          .then((response) => {
            this.surveyTemplates = response.data.results;
            this.surveyTemplates = this.surveyTemplates.filter((template) => template.active)
          })
          .catch((error) => {
            console.error("failed to fetch the templates list")
            console.log(error);
          });

      axios
          .get("api/organization-list/")
          .then((response) => {
            this.organizations = response.data.results;
          })
          .catch((error) => {
            console.error("failed to fetch the organizations")
            console.log(error);
          });
    },

    loadDynamicQuestions() {
      this.dynamicQuestions = [];
      this.$store.commit('setIsLoading', true)
      axios
          .get("api/survey-template/" + this.createCampaignForm.campaignTemplate + "/get-dynamic-questions/")
          .then((response) => {
            console.log(response.data)
            this.dynamicQuestions = response.data;
            for (let counter = 0; counter < this.dynamicQuestions.length; counter++) {
              this.createCampaignForm.dynamicQuestionOptions[counter] = {
                title: this.dynamicQuestions[counter].title,
                question_id: this.dynamicQuestions[counter].question_id, options: [, , , , , , , , ,]
              }
            }
            this.$store.commit('setIsLoading', false)
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit('setIsLoading', false)
          });

      this.dynamicQuestions = [];

      // populate the questionIDs for options
      for (let counter = 0; counter < this.dynamicQuestions.length; counter++) {
        var questionId = this.dynamicQuestions[counter].questionId
        this.createCampaignForm.dynamicQuestionOptions[counter] = {questionId: questionId}
      }
    },
    async create_campaign() {
      this.createCampaignForm.expirationDate = this.format_the_date(this.createCampaignForm.expirationDate)
      this.$store.commit('setIsLoading', true)
      await axios
          .post("api/create-survey-campaign/", this.createCampaignForm)
          .then((response) => {
            this.$toast.success(`Campaign created successfully`);
            this.$refs.closeModal.click();
            this.load_surveys();
            this.createCampaignForm = {campaignName: '', expirationDate: '', organization: '', campaignTemplate: '', dynamicQuestionOptions: []};
          }).catch((error) => {
            this.$toast.error(`Failed to create campaign`);
            console.log(error);
          });
      this.$store.commit('setIsLoading', false)
    },

    resetCampaignData(){
      this.createCampaignForm = {campaignName: '', expirationDate: '', organization: '', campaignTemplate: '', dynamicQuestionOptions: []};
    },

    async  report_file_name(id) {
      var url = "api/report-name/" + id + "/";
      try {
        const response = await axios({
          url: url, method: "GET", responseType: "text",
        });
        return JSON.parse(response.data)['filename']
      } catch (error) {
        console.error(error);
      }
    },

    async loadSpecificPage(link){
      this.$store.commit('setIsLoading', true)
      await axios
          .get(link)
          .then((response) => {
            console.log(response.data)
            this.surveys = response.data;

          })
          .catch((error) => {
            console.log(error);
          });
      this.$store.commit('setIsLoading', false)
    },

    async Download_csv(id, title) {
      var url = "api/survey/" + id + "/generate-txt-csv";
      try {
        this.$store.commit('setIsLoading', true)
        const response = await axios({
          url: url, method: "GET", responseType: "blob",
        });
        // Use FileSaver.js to save the file
        var filename = await this.report_file_name(id)
        this.$store.commit('setIsLoading', false)
        saveAs(response.data, `${filename}.csv`);
        this.$toast.success(`CSV downloaded successfully`);
      } catch (error) {
        this.$toast.error(`Failed to download CSV`);
        console.error(error);
      }
      this.$store.commit('setIsLoading', false)
    },

  async downloadCsvReport(id) {
    var url = "api/download-csv-report/" + id + "/";
    try {
      this.$store.commit('setIsLoading', true)
      const response = await axios({
        url: url,
        method: "GET",
        responseType: "blob",
      });
      // Use FileSaver.js to save the file
      var filename = await this.report_file_name(id)
      this.$store.commit('setIsLoading', false)
      saveAs(response.data, `${filename}.csv`);
      this.$toast.success(`Report CSV downloaded successfully`);
    } catch (error) {
      console.error(error);
      this.$toast.error(`Failed to download Report CSV`);
    }
  },
    async downloadPdfReport(id) {
      var url = "api/download-pdf-report/" + id + "/";
      try {
        this.$store.commit('setIsLoading', true)

        const response = await axios({
          url: url,
          method: "GET",
          responseType: "blob",
        });
        this.$store.commit('setIsLoading', false)
        if (response.status === 200){
          var filename = await this.report_file_name(id)
          // Use FileSaver.js to save the file
          saveAs(response.data, `${filename}.pdf`);
          this.$toast.success(`Report downloaded successfully`);
        }
        else {
          this.$toast.error(`Unknown error occured while downloading PDF`);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(`Failed to download Report`);
      }
    },

    async exploreReport(id){
      var url = "api/render-report-data/" + id + "/";
      this.$store.state.selectedSurveyID = id
      this.$store.state.demographicFilters = {}
      this.$store.commit('resetComparativeSurveyData')
      // if (!Boolean(token)) {
      //   this.$router.replace('/login')
      //   return;
      // }

      this.$store.commit('setIsLoading', true)
      await axios
          .get(url)
          .then((response) => {
            if(response.status === 200){
              this.$store.commit('setReportData', response.data)
              // this.$store.commit('setSections')
              console.log(this.$store.state.sections)
              this.$store.commit('resetParams')
              this.$store.commit('resetAppliedFilters')
              this.$store.commit('resetGlobalFilters')
              console.log(this.$store.state.reportData)

              this.$store.commit('setHasReportData', true)
              this.$store.commit('setActivepage', 'breakdown')
              this.$router.push({
                name: 'breakdown'
              })
              this.$toast.success(`Exploring the report`);
            }

            else{
              this.$toast.warning(`No responses found for this survey`);
            }

          })
          .catch((error) => {
            console.log(error);
          });
      this.$store.commit('setIsLoading', false)

      // setting active store to breakdown

    },

    setActivePage(currentPage) {
      console.log("setting the current page to "+ currentPage)
      if (currentPage === 'campaign'){
        this.$router.push({
          name: 'dashboard',
        })
      }
      else if (this.hasReportData || ['manage-users', 'manage-client', 'manage-campaign-templates', 'manage-tenant', 'manage-features', 'manage-user-features'].includes(currentPage)){
        this.$router.push({
          name: currentPage,
        })
      }
    },

    switchBack(){
      this.$store.commit('switchBack')
      this.$toast.info("Switched Back")
    }
  }
};
</script>
