<template>
    <div class="card border-0 shadow mb-4">
    <div class="card-body">
      <!-- <button
        v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
        type="button"
        class="btn btn-sm"
        title="Delete Page"
        @click="this.deletePage"
        style="position: absolute; top: 1.5em; right: 2.5em; "
      >
        <span class="fs-3 me-1 fw-bolder text-danger">X</span>
      </button>
  
      <button
        v-if="userPermissions.canAddUpdateCustomReport"
        type="button"
        class="btn me-1"
        title="Set Demographics"
        @click="this.duplicatePage"
        style="position: absolute; top: 6em; right: 1.5em;"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
      </button>
  
      <SectionSpecificFilter v-if="userPermissions.canDoSectionSpecificFilters" :section="this.section"/> -->
      <!-- <h2 class="text-center fw-normal mx-8" @blur="this.updateTitle" :contenteditable="section.duplicate === 1">{{ this.$store.state.sections[this.index].title }}</h2> -->
      <h2 class="text-center fw-normal mx-8">{{ this.section1.title }}</h2>
        <div class="mx-8">
            <!-- <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
                <hr>
                <span class="text-warning">Section Specific Filters:  </span>
                <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
                <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
                <span class="text-decoration-none">{{ value.join(', ') }}</span>
                <br><br>
                </div>
            </div> -->
            <p class="edgility-gray lh-sm">These are the average scores your organization received in each area of the Total Value Proposition. Use this data to understand how each factor is contributing to staff retention and attrition. A higher score correlates to a factor that serves to help retain staff. A lower score correlates to a factor that may contribute to staff attrition.
            </p> <br>
        </div>

        <hr>
        <br>


        <!-- ------------------ For Survye 1 --------------------- -->
        <p class="fw-bolder fs-3" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title1 }}
        </p>
        <br>
        <div class="text-center mx-4">
            <center>
                <div style="width: 80% !important; height: auto !important">
                <canvas :id="'tvpBar' + this.section1.order + 1" style="width: 80%; height: auto;"></canvas>
                </div>
            </center>
        </div>
    </div>
  
    <div class="tvp-row">
        <div v-for="(value, category_name) in sub_categories_average1" class="tvp-info">
            <p :class="'tvp-bold tvp-banner ' + value.css_class">{{ category_name }}</p>
            <p class="tvp-bold">{{ value.category_average.toFixed(2)}}</p>
                <p v-if="!power_20_survey" v-for="(average, subcategory) in value.sub_categories_average" class="tvp-row">
                    <span class="tvp-bold tab">{{ subcategory }}</span>
                    {{ average.toFixed(2) }}
                </p>
  
                <p v-else class="mb-5">
  
                </p>
        </div>
    </div>
    <br>
    <br>


    <!-- --------------- For survey 2 -------------------- -->
    <p class="fw-bolder fs-3" style="text-align: center;">
        {{ this.$store.state.comparativeSurveyTitles.title2 }}
    </p>
    <div class="card-body">
     <div class="text-center mx-4">
            <center>
                <div style="width: 80% !important; height: auto !important">
                <canvas :id="'tvpBar' + this.section2.order + 2" style="width: 80%; height: auto;"></canvas>
                </div>
            </center>
        </div>
    </div>
  
    <div class="tvp-row">
        <div v-for="(value, category_name) in sub_categories_average2" class="tvp-info">
            <p :class="'tvp-bold tvp-banner ' + value.css_class">{{ category_name }}</p>
            <p class="tvp-bold">{{ value.category_average.toFixed(2)}}</p>
                <p v-if="!power_20_survey" v-for="(average, subcategory) in value.sub_categories_average" class="tvp-row">
                    <span class="tvp-bold tab">{{ subcategory }}</span>
                    {{ average.toFixed(2) }}
                </p>
  
                <p v-else class="mb-5">
  
                </p>
        </div>
    </div>
  </div>
  
  <div class="card border-0 shadow mb-4">
    <div class="card-body">
      <h2 class="text-center fw-normal">Glossary</h2>
      <div class="mx-6">
      <br><br>
        <div class="row">
          <div class="col-12">
              <!-- Tabs -->
              <nav>
                  <div class="nav nav-tabs nav-justified mb-4" id="nav-tab" role="tablist">
                      <a class="nav-item nav-link white edgility-blue-bg edg-nav-tab" id="nav-1-tab" data-bs-toggle="tab" href="#nav-1" role="tab" aria-controls="nav-1" aria-selected="true">Attract</a>
  
                      <a class="nav-item nav-link white edgility-yellow-bg edg-nav-tab" id="nav-2-tab" data-bs-toggle="tab" href="#nav-2" role="tab" aria-controls="nav-2" aria-selected="false">Grow</a>
  
                      <a class="nav-item nav-link edgility-red-bg white edg-nav-tab" id="nav-3-tab" data-bs-toggle="tab" href="#nav-3" role="tab" aria-controls="nav-3" aria-selected="false">Engage</a>
  
                      <a class="nav-item nav-link edgility-offwt-bg edg-nav-tab edgility-black" id="nav-4-tab" data-bs-toggle="tab" href="#nav-4" role="tab" aria-controls="nav-4" aria-selected="false">Reward</a>
  
                      <a class="nav-item nav-link edgility-black-bg white edg-nav-tab" id="nav-5-tab" data-bs-toggle="tab" href="#nav-5" role="tab" aria-controls="nav-5" aria-selected="false">Assess</a>
                  </div>
              </nav>
              <div class="tab-content edgility-offwt-bg" id="nav-tabContent">
                  <div class="tab-pane fade show active edg-tab-1" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                      <h5 class="fw-black">Brand:</h5>
                      <p class="lh-sm">What we stand for is clear and well received by our stakeholders.</p>
                      <h5 class="fw-black">Impact:</h5>
                      <p class="lh-sm">What we stand for is clear and well received by our stakeholders.</p>
                      <h5 class="fw-black">Strategy:</h5>
                      <p class="lh-sm">What we stand for is clear and well received by our stakeholders.</p>
                  </div>
                  <div class="tab-pane fade edg-tab-2" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                    <h5 class="fw-black">Development:</h5>
                    <p class="lh-sm">Our managers reinforce feedback culture and provide meaningful support.</p>
                    <h5 class="fw-black">Investment:</h5>
                    <p class="lh-sm">Our training and professional growth are efficient tools to ensure our success in our work.</p>
                    <h5 class="fw-black">Support:</h5>
                    <p class="lh-sm">Our managers create safe spaces that help us connect to the company and work.</p>
                  </div>
                  <div class="tab-pane fade edg-tab-3" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                    <h5 class="fw-black">Alignment:</h5>
                    <p class="lh-sm">Staff workloads are meaningful, impactful and motivating.</p>
                    <h5 class="fw-black">Inclusion:</h5>
                    <p class="lh-sm">Our company invites peers of all different identities to contribute and be heard.</p>
                    <h5 class="fw-black">Wellness:</h5>
                    <p class="lh-sm">There's evident respect for the physical and mental health of our company's employees.</p>
                  </div>
                  <div class="tab-pane fade edg-tab-4" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab">
                    <h5 class="fw-black">Benefits:</h5>
                    <p class="lh-sm">Our company's benefits (health insurance, retirement, etc.) are fair, appropriate and easy to access.</p>
                    <h5 class="fw-black">Recognition:</h5>
                    <p class="lh-sm">The expectations and processes for promotions and pay increases are clear.</p>
                    <h5 class="fw-black">Salary:</h5>
                    <p class="lh-sm">Our salaries are fair and reflective of the work people contribute to the company.</p>
                  </div>
                  <div class="tab-pane fade edg-tab-5" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab">
                    <h5 class="fw-black">Accountability:</h5>
                    <p class="lh-sm">Our leadership owns up to the company's shortcomings and resolves issues efficiently.</p>
                    <h5 class="fw-black">Evaluation:</h5>
                    <p class="lh-sm">Performance evaluation is accurate and allows staff to articulate our strengths and areas of improvement.</p>
                    <h5 class="fw-black">Goals:</h5>
                    <p class="lh-sm">The criteria for success are straightforward and can be applied to our team and our company as a whole.</p>
                  </div>
              </div>
              <!-- End of tab -->
          </div>
      </div>
  
  
      </div>
    </div>
  </div>
  <!-- END White div with shadow begins -->
  
  </template>
  
  <script>
      import {barLineCreator} from '@/assets/js/tvpCharts'
    //   import SectionSpecificFilter from './SectionSpecificFilter.vue'
  
      export default{
          name: "ComparativeTVPVariance",
          props: ['section1', 'section2'],
          data(){
              return{
                sub_categories_average1: this.section1.sub_categories_average,
                sub_categories_average2: this.section2.sub_categories_average,

                overall_averages1: this.section1.averages,
                overall_averages2: this.section2.averages,

                power_20_survey: this.$store.state.reportData.report_type_is_power_20
              }
          },
  
          components:{
            // SectionSpecificFilter
          },
  
          computed: {
            userPermissions(){
              return this.$store.state.userPermissions
            },
            // index(){
            //   return this.$store.state.sections.indexOf(this.section)
            // },
          },
  
          errorCaptured(err, instance, info){
              this.$toast.error("Something Went Wrong! Please see log for more information.");
              console.error(err)
              this.$router.go(-1)
              return false
          },
  
          mounted(){
            let averages_string1 = JSON.stringify(this.overall_averages1)
            let averages1 = JSON.parse(averages_string1)
            let labels1 = Object.keys(averages1.categories)
            console.log(this.$store.state.reportData.is_filters_applied)
            var data1 = []
            labels1.forEach(key => {
                data1.push(averages1.categories[key].average)
            })
            barLineCreator({
                title: 'Bar Line Chart',
                labels: labels1,
                data: data1
            }, 'tvpBar' + this.section1.order + 1, this.$store.state.reportData.is_filters_applied)


            let averages_string2 = JSON.stringify(this.overall_averages2)
            let averages2 = JSON.parse(averages_string2)
            let labels2 = Object.keys(averages2.categories)
            console.log(this.$store.state.reportData.is_filters_applied)
            var data2 = []
            labels2.forEach(key => {
                data2.push(averages2.categories[key].average)
            })
            barLineCreator({
                title: 'Bar Line Chart',
                labels: labels2,
                data: data2
            }, 'tvpBar' + this.section2.order + 2, this.$store.state.reportData.is_filters_applied)
          },
  
        //   methods: {
        //     updateTitle(event){
        //       if (event.target.textContent == '' || event.target.textContent.trim() == ''){
        //           this.$toast.error(`Please Provide a Valid Name`);
        //           return
        //       }
        //       this.$store.state.sections[this.index].title = event.target.textContent
        //     },
        //     duplicatePage(){
        //       this.$emit('duplicatePage', this.index)
        //     },
  
        //     deletePage(){
        //       this.$emit('deletePage', this.index)
        //     }
        //   }
      }
  </script>