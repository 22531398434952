<template>
    <div class="card border-0 shadow">
        <div class="card-header border-bottom d-flex align-items-center justify-content-between">
            <h2 class="fs-5 fw-bold mb-0"> All Users </h2>
            <a data-bs-toggle="modal" data-bs-target="#modalAddUser" class="btn btn-sm btn-tertiary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="icon icon-xxs me-2" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>
                Add User
            </a>
        </div>


        <div class="modal fade" id="modalAddUser" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="addUserCloseModal"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center mb-4">Add User</h2>
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" v-model="addUser.email" required>
                        <label for="floatingInput">Email address</label>
                    </div>

                    <div class="input-group mb-3">
                        <input v-model="addUser.first_name" type="text" class="form-control" placeholder="First Name" aria-label="FirstName">
                        <input v-model="addUser.last_name" type="text" class="form-control" placeholder="LastName" aria-label="LastName">
                    </div>

                    <div class="input-group mb-3">
                        <input v-model="addUser.password" style="z-index: 0;" type="text" class="form-control" placeholder="Password" aria-label="Password" aria-describedby="button-addon2" ref="addPassword" required>
                        <svg v-if="hidePassword" @click="hidePassword=!hidePassword" style="position: absolute; top: 20%; left: 92%; opacity: 0.7; cursor: pointer; z-index: 1;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/></svg>
                        <svg v-else @click="hidePassword=!hidePassword" style="position: absolute; top: 20%; left: 92%; opacity: 0.7; cursor: pointer; z-index: 1;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16"><path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/><path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/><path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/></svg>
                    </div>



                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="addUser.is_active">
                        <label class="form-check-label" for="defaultCheck10">
                            Active
                        </label>
                    </div>

                    <multiselect track-by="name" label="name" v-model="addUserSelectedGroup" :options="groups" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Set/Update User Role"></Multiselect>

                    <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                    <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                        Cancel
                    </button>
                    <button class="btn btn-primary" @click="this.addUserFunc()" >
                        Add User
                    </button>
                    </div>
              </div>
            </div>
          </div>
        </div>


        <div class="card-body">
            <ul class="list-group list-group-flush list my--3">
                <li v-for="user in users" class="list-group-item px-0" :key="user">
                    <div class="row align-items-center">
                        <div class="col-auto ms--2">
                            <h2 class="h5 mb-0 bold">
                                <a href="#">{{user.first_name}} {{user.last_name}} - {{ user.email }}  </a>
                            </h2>
                            <small v-if="user.group" class="text-danger"> ({{ user.group }}) </small>
                            <div v-if="user.is_active" class="d-flex align-items-center">
                                <div class="bg-success dot rounded-circle me-1"></div>
                                <small>Active</small>
                            </div>

                            <div v-else class="d-flex align-items-center">
                                <div class="bg-danger dot rounded-circle me-1"></div>
                                <small>Inactive</small>
                            </div>

                            <button @click="deleteUser(user.id)" class="badge badge-md bg-danger"> Delete User</button>
                            <button v-if="userPermissions.isPlatformAdmin" @click="switchUser(user.id)" class="ms-2 badge badge-md bg-success"> Switch to this User</button>
                        </div>

                        <div class="col text-end">
                            <button @click="getUserOrganizationData(user)" data-bs-toggle="modal" data-bs-target="#modalUserOrganization" class="btn btn-sm btn-gray-300 d-inline-flex align-items-center me-2">
                                <svg class="icon icon-xxs me-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/></svg>
                                Manage association with Clients
                            </button>
                            <button @click="this.getUser(user)" data-bs-toggle="modal" data-bs-target="#modalUpdateUser" class="btn btn-sm btn-secondary d-inline-flex align-items-center">
                                <svg class="icon icon-xxs me-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/></svg>
                                Edit User
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="modal fade" id="modalUserOrganization" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center mb-4">Manage association with Clients</h2>

                <label class="h6 fw-bolder">Clients:</label>

                <p v-for="org in userOrganizations" class="border p-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                  <span> {{ org }} </span>
                  <button class="btn btn-sm btn-outline-danger" type="button" @click="removeOrganizationFromUser(org)"> Remove Client </button>
                </p>

                <div class="mt-4" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                <multiselect  v-model="selectedOrganization" :options="organizations" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select clients to add to user"></Multiselect>
                <button class="btn btn-tertiary btn-md ms-3" @click="addOrganizationToUser">
                    Add
                </button>
                </div>

<!--                <div class="d-flex align-items-center justify-content-end gap-4 mt-4">-->
<!--                  <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >-->
<!--                    Cancel-->
<!--                  </button>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="modalUpdateUser" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
              </div>

              <div class="modal-body px-md-5">
                <h2 class="h4 text-center mb-4">Update/Edit User</h2>
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="selectedUser.email" placeholder="name@example.com">
                    <label for="floatingInput">Email address</label>
                </div>

                <div class="input-group mb-3">
                    <input v-model="selectedUser.first_name" type="text" class="form-control" placeholder="First Name" aria-label="FirstName">
                    <input v-model="selectedUser.last_name" type="text" class="form-control" placeholder="LastName" aria-label="LastName">
                </div>



                <br>

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="selectedUser.is_active">
                    <label class="form-check-label" for="defaultCheck10">
                        Active
                    </label>
                </div>

                <multiselect track-by="name" label="name" v-model="selectedGroup" :options="groups" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Set/Update User Role"></Multiselect>

                <div class="d-flex align-items-center justify-content-end gap-4 mt-4">
                  <button class="btn btn-outline-primary" type="button" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" >
                    Cancel
                  </button>
                  <button class="btn btn-primary" type="button" @click="updateUser">
                    Update User
                  </button>
                </div>

                <div class="mb-2">
                  <span class="me-2">Change Password</span>
                </div>
                <div class="input-group mb-3">
                  <input v-model="setPassword" style="z-index: 0" type="password" class="form-control" placeholder="Type new Password Here" ref="updatePassword">
                  <svg v-if="hidePassword" @click="hidePassword=!hidePassword" style="position: absolute; top: 20%; left: 61%; opacity: 0.7; cursor: pointer; z-index: 1;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/></svg>
                  <svg v-else @click="hidePassword=!hidePassword" style="position: absolute; top: 20%; left: 61%; opacity: 0.7; cursor: pointer; z-index: 1;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16"><path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/><path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/><path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/></svg>
                  <button @click="updatePassword" class="btn btn-tertiary" type="button" id="button-addon2">Change Password</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default{
        name: 'ManageUsers',
        data(){
            return{
                addUser: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    password: '',
                    is_active: false,
                },
                addUserSelectedGroup: [],
                users: [],
                groupsList: [],
                selectedUser: {},
                organizations: [],
                userOrganizations: [],
                selectedOrganization: '',
                selectedGroup: [],
                setPassword: '',
                hidePassword: false
            }

        },

        mounted(){
            this.getUsers()
        },

        watch: {
            hidePassword(oldValue, newValue){
                if (newValue){
                    this.$refs.addPassword.type = 'text'
                    this.$refs.updatePassword.type='text'
                }
                else {
                    this.$refs.addPassword.type = 'password'
                    this.$refs.updatePassword.type='password'
                }
            }
        },

        computed:{
            userPermissions(){
                let perm = JSON.parse(localStorage.getItem("token")).userPermissions
                return perm
            },
            groups(){
                if (this.userPermissions.isPlatformAdmin) return this.groupsList.slice(1)
                else if (this.userPermissions.isPlatformStaff) return this.groupsList.slice(2)
                else if (this.userPermissions.isTenantAdmin) return this.groupsList.slice(3)
                else if (this.userPermissions.isTenantStaff) return this.groupsList.slice(4)
            }
        },

        errorCaptured(err, instance, info){
            this.$toast.error("Something Went Wrong! Please see log for more information.");
            console.error(err)
            this.$router.go(-1)
            return false
        },

        methods: {
            async getUsers(){
                await axios
                    .get('api/user/')
                    .then((response) => {
                        this.users = response.data.results
                        this.users.forEach((user) => {
                            delete user['password']
                        })

                        console.log(this.users)
                    })
                    .catch(err => console.log(err))

              axios
                  .get('api/user/tenant-specific-groups-list')
                  .then((response) => {
                    this.groupsList = response.data
                  })
                  .catch(err => console.log(err))
            },

            getUser(user){
                this.selectedUser = {...user}
            },

            async getOrganizations(){
                await axios
                    .get("api/organization-list/")
                    .then((response) => {
                        this.organizations = []
                        response.data.results.forEach((org) => {
                            this.organizations.push(org.name)
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            async getUserOrganizations(id){
                await axios
                    .get(`api/user/${id}/organizations/`)
                    .then((resp) => {
                        this.userOrganizations = resp.data;
                    })
                    .catch((error) => console.log(error));
            },

            async updateUser(){
                const config = {
                    params: {
                        group: this.selectedGroup.id
                    }
                };

                await axios
                    .put(`api/user/${this.selectedUser.id}/`, this.selectedUser, config)
                    .then((resp) => {
                        console.log(resp);
                        this.$refs.closeModal.click()
                        this.selectedGroup = []
                    })
                    .catch(err => console.log(err))

                this.getUsers()
            },

            async addUserFunc(){
                console.log(this.addUser)
                if (this.addUser.email === ''){
                    this.$toast.warning(`email is required`);
                    return
                }

                if (this.addUser.password === ''){
                    this.$toast.warning(`password is required`);
                    return
                }

                if (this.addUser.password.length < 7){
                    this.$toast.warning(`password should be of atleast 7 characters`);
                    return
                }

                const config = {
                    params: {
                        group: this.addUserSelectedGroup.id
                    }
                };

                await axios
                    .post(`api/user/`, this.addUser, config)
                    .then((resp) => {
                        if (resp.status === 200 || resp.status === 201){
                            console.log(resp);
                            this.getUsers()
                            this.$refs.addUserCloseModal.click()
                            this.addUserSelectedGroup = []
                            this.$toast.success(`User created successfully`);
                        }

                        else if (resp.status === 400){
                            this.$toast.warning(`can not create the user`);
                        }
                    })
                    .catch(err => {
                      this.$toast.error(`failed to create user`);
                      console.log(err)
                    })
            },

            async deleteUser(id){
                await axios
                    .delete(`api/user/${id}/`)
                    .then((resp) => {
                        if (resp.status === 204 ) {
                            this.getUsers()
                        }
                    })
                    .catch(err => console.log(err))
            },

            async updatePassword(){
                if (this.setPassword === ''){
                    this.$toast.error(`password must be provided`);
                    return
                }

                if (this.setPassword.length < 7){
                    this.$toast.error(`password should be of atleast 7 characters`);
                    return
                }
                await axios
                    .post(`api/user/${this.selectedUser.id}/change-password/`, {password: this.setPassword})
                    .then((resp) => {
                        if (resp.status === 201){
                            this.$toast.success(`password Changed Successfully`);
                            this.setPassword = ''
                        }
                    })
            },

            getUserOrganizationData(user){
                this.getUser(user)
                this.getOrganizations()
                this.getUserOrganizations(user.id)
            },

            async removeOrganizationFromUser(org){
                let id = this.selectedUser.id
                const config = {
                    params: { organization: org }
                };

                await axios
                    .delete(`api/user/${id}/delete-organization/`, config)
                    .then((resp) => {
                        if (resp.status === 204 ) {
                            this.getUserOrganizations(id)
                        }
                    })
                    .catch(err => console.log(err))
            },

            async addOrganizationToUser(){
                let id = this.selectedUser.id
                await axios
                    .post(`api/user/${id}/add-organization/`, {organization: this.selectedOrganization})
                    .then((resp) => {
                        if (resp.status === 201 ) {
                            this.getUserOrganizations(id)
                        }
                        else{
                            this.$toast.error(resp.data['Error'])
                        }
                    })
                    .catch(err => console.log(err))
            },

            async switchUser(id){
                await axios
                    .post(`api/switch-user/${id}/`, {subdomain: localStorage.getItem('subdomain')})
                    .then((resp) => {
                        const adminToken = localStorage.getItem('token')
                        localStorage.setItem('adminToken', adminToken)
                        const token = resp.data;
                        localStorage.setItem("token", JSON.stringify(token));
                        this.$toast.success(`Switched to ${this.$store.state.username}`)
                        this.$store.state.isSwitched = true
                        this.$store.state.reportData = {}
                        this.$store.commit('resetParams')
                        this.$store.commit('resetAppliedFilters')
                        this.$store.commit('setHasReportData', false)
                        this.$store.commit('setActivepage', 'breakdown')
                        this.$router.push('/dashboard')
                    })
                    .catch(err => {
                        this.$toast.error("Failed to switch. Unknown error occured")
                        console.log(err)
                    })
            }
        }

    }
</script>