<template>
    <div class="card border-0 shadow mb-4">
      <div class="card-body mx-auto">
        <!-- <SectionSpecificFilter v-if="userPermissions.canDoSectionSpecificFilters" :section="this.section" section-name="Total Value Propositions"/> -->
        <!-- <h2 class="text-center fw-normal mx-8" @blur="this.updateTitle" :contenteditable="section.duplicate === 1">{{ this.$store.state.sections[this.index].title }}</h2> -->
        <h2 class="text-center fw-normal mx-8">{{ this.section1.title }}</h2>
  
        <!-- <button
          v-if="userPermissions.canDoSectionSpecificFilters && section.duplicate"
          type="button"
          class="btn btn-sm"
          title="Delete Page"
          @click="this.deletePage"
          style="position: absolute; top: 1.5em; right: 2.5em; "
        >
          <span class="fs-3 me-1 fw-bolder text-danger">X</span>
        </button>
  
        <button
          v-if="userPermissions.canAddUpdateCustomReport"
          type="button"
          class="btn me-1"
          title="Set Demographics"
          @click="this.duplicatePage"
          style="position: absolute; top: 6em; right: 1.5em;"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-gear-fill" fill="currentColor" viewBox="0 0 24 24"><path d="M4 22h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2zm2-9h3v-3h2v3h3v2h-3v3H9v-3H6v-2z"/><path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"/></svg>
        </button> -->
  
        <div style="width: 7%; position: absolute; top: 2em; right: 8em;">
        <!-- <multiselect v-model="this.$store.state.sections[this.index].filters.display_filters.display_count" :options="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select a Number"></multiselect> -->
        </div>
          <div class="mx-2">
            <!-- <div v-if="Object.keys(this.$store.state.sections[this.index].filters.section_filters).length > 0">
              <hr>
              <span class="text-warning">Section Specific Filters:  </span>
              <div v-for="(value, key) in this.$store.state.sections[this.index].filters.section_filters">
                <span class="text-decoration-none"> {{ key.toUpperCase() }}: </span>
                <span class="text-decoration-none">{{ value.join(', ') }}</span>
                <br><br>
              </div>
            </div> -->
            <p class="edgility-gray lh-sm text-center">These are the <span class="edgility-red">bottom areas</span> of your organization's total value proposition.
            </p> <br>
          </div>
          <hr>

          <!-- Survey 1 -->
          <p class="fw-bolder fs-3" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title1 }}
          </p>
  
          <div class="mx-1">
            <ol>
              <li v-for="lowlight in lowlights1">
                <span :class="class_for_category(lowlight.category)">
                  {{ lowlight.sub_category }}
                </span>
                {{ lowlight.text }}
              </li>
            </ol>
          </div>
          <br>

          <!-- Survey 2 -->
          <p class="fw-bolder fs-3" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title2 }}
          </p>
  
          <div class="mx-1">
            <ol>
              <li v-for="lowlight in lowlights2">
                <span :class="class_for_category(lowlight.category)">
                  {{ lowlight.sub_category }}
                </span>
                {{ lowlight.text }}
              </li>
            </ol>
          </div>


      </div>
    </div>
  
    <div class="card border-0 shadow mb-4">
    <div class="card-body">
      <h2 class="text-center fw-normal">TVP Data Table</h2>
        <div class="mx-6">
          <table class="table">
            <tbody>
                <tr>
                    <th></th> <!-- Empty cell for first column -->
                    <th colspan="3" class="fw-bolder fs-5" style="text-align: center;">{{ this.$store.state.comparativeSurveyTitles.title1 }}</th>
                    <th colspan="3" class="fw-bolder fs-5" style="text-align: center;">{{ this.$store.state.comparativeSurveyTitles.title2 }}</th>
                </tr>
            
                <tr v-for="(lowlight, index) in lowlights1">
                    <th style="border-right: 1px solid black;" scope="row"> {{ index + 1 }} </th>
                    
                    <td> {{ lowlight.sub_category }} </td>
                    <td> {{ lowlight.average }} </td>
                    <td :class="class_for_category(lowlight.category)" style="border-right: 1px solid black;"> {{ lowlight.category }} </td>

                    <td> {{ lowlights2[index].sub_category }} </td>
                    <td> {{ lowlights2[index].average }} </td>
                    <td :class="class_for_category(lowlights2[index].category)"> {{ lowlights2[index].category }} </td>

                </tr>
  
            </tbody>
          </table>
  
  
        </div>
    </div>
  </div>
  
  </template>
  
  <script>
    //   import SectionSpecificFilter from './SectionSpecificFilter.vue';
  
      export default{
          props: ['section1', 'section2'],
          name: "ComparativeTVPLowlights",
          data(){
              return{
                lowlights1: this.section1.lowlights_for_frontend_ui,
                lowlights2: this.section2.lowlights_for_frontend_ui
              }
          },
  
          computed: {
            userPermissions(){
              return this.$store.state.userPermissions
            },
            // highlightsCount1(){
            //   return this.lowlights1.slice(0, this.$store.state.sections[this.index].filters.display_filters.display_count)
            // },
            // highlightsCount2(){
            //   return this.lowlights2.slice(0, this.$store.state.sections[this.index].filters.display_filters.display_count)
            // },
            // index(){
            //   return this.$store.state.sections.indexOf(this.section)
            // }
          },
  
          components: {
            // SectionSpecificFilter,
          },
  
          errorCaptured(err, instance, info){
              this.$toast.error("Something Went Wrong! Please see log for more information.");
              console.error(err)
              this.$router.go(-1)
              return false
          },
  
          methods: {
            // updateTitle(event){
            //     if (event.target.textContent == '' || event.target.textContent.trim() == ''){
            //         this.$toast.error(`Please Provide a Valid Name`);
            //         return
            //     }
            //     this.$store.state.sections[this.index].title = event.target.textContent
            // },
            // duplicatePage(){
            //     this.$emit('duplicatePage', this.index)
            // },
  
            // deletePage(){
            //     this.$emit('deletePage', this.index)
            // },
  
            class_for_category(cat){
              if (cat === 'Attract'){
                return 'edgility-blue'
              }
  
              else if (cat === 'Grow'){
                return 'edgility-yellow'
              }
  
              else if (cat === 'Engage'){
                return 'edgility-red'
              }
  
              else if (cat === 'Reward'){
                return 'edgility-offwhite3'
              }
  
              else if (cat === 'Grow'){
                return 'edgility-black'
              }
  
            }
          }
      }
  </script>