<template>
    <div class="card border-0 shadow-md mb-4" >
      <div class="card-body">
        <div class="row flex-grow-1 mx-9 justify-content-around ">
          <div class="col-6">
            <h4 class="lh-2">Edgility Pillars of Equity</h4>
            <br>
            <p class="edgility-gray ">
              If your policies are <span class="edgility-blue">clear and transparent</span>, staff
              know what to expect from the organization. This helps prevent
              assumptions about decision-making criteria and makes your total
              value proposition less fuzzy.<br /><br />
              Once expectations are normalized, it's crucial that policies are
              implemented <span class="fw-extrabold edgility-red">fairly and consistently</span>. Implementing
              regular audits, leadership training and clear decision-making
              autonomy prevent exceptions from undermining the system.<br /><br />
              Clear and consistently implemented policies only work if they impact
              all staff similarly, no matter their identity, role or background.
              It's important to prioritize <span class="fw-extrabold edgility-yellow">inclusion and belonging</span> to ensure well intended policies don't leave segments of staff feeling undervalued, isolated
              or disadvantaged.
            </p>
          </div>
          <div class="col-3">
            <br> <br>
            <div class="fmxw-300">
              <div class="col clip-it edgility-blue-bg white fw-bolder mb-3">
                <span class="fx-black edgility-offwhite2 me-2">1</span> Clarity & <br>Transparency
              </div>
            </div>
            <div class="fmxw-300">
              <div class="col clip-it edgility-red-bg white fw-bolder mb-3">
                <span class="fx-black edgility-offwhite2 me-2">2</span> Fairness & <br>Consistency
              </div>
            </div>
            <div class="fmxw-300">
              <div class="col clip-it edgility-yellow-bg white fw-bolder">
                <span class="fx-black edgility-offwhite2 me-2">3</span> Inclusion & <br>Belonging
              </div>
            </div>
          </div>
        </div>
        </div>
    </div> <br> <br>
    <!-- END White div with shadow-md begins -->
    
    <div class="card border-0 shadow-md mb-4">
      <div class="card-body">
        <div class="mx-4">
          <h4 class="fw-extrabold border-bottom pb-3 ps-5 ">Averages for {{ this.$store.state.comparativeSurveyTitles.title1 }}</h4>
          <div class="row mx-4">
            <div class="col  mx-auto">
              <p class="display-1 fw-bolder edgility-blue text-center"> {{ pillars_average_list1[0] }} </p>
              <div class="row justify-content-center">
                <div class="col-1 text-center px-0">
                  <svg class="icon icon-m" fill="#1863DF"  viewBox="0 0 25 50" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path class="cls-1" d="m21.84,46.51s-.06.04-.1.06c-1.16-1.38-2.31-2.76-3.48-4.13-1.27-1.49-2.55-2.97-3.81-4.46-1.29-1.51-2.56-3.03-3.84-4.53-1.24-1.45-2.49-2.89-3.73-4.34-1.26-1.47-2.47-2.96-3.75-4.41C2.08,23.52,1.16,22.23,0,21.13v-.15c.59-1.63,1.21-3.24,1.79-4.88C3.46,11.33,5.12,6.57,6.79,1.8c.03-.1.06-.19.21-.22,4.94,14.97,9.89,29.95,14.83,44.93"/><path class="cls-1" d="m26.58,14.99c-.09.85-.1,1.71-.21,2.56-.23,1.64-.21,3.31-.34,4.96-.17,2.16-.41,4.31-.58,6.48-.06.76-.12,1.53-.15,2.29-.09,2.28-.37,4.53-.5,6.8-.11,1.99-.34,3.99-.58,6.03-.44-1.32-.89-2.64-1.34-3.96-1.83-5.49-3.66-10.98-5.51-16.46-.21-.61-.08-.96.34-1.42,1.51-1.67,2.94-3.41,4.42-5.11,1.09-1.26,2.16-2.52,3.3-3.74.41-.44.69-1.04,1.31-1.38.19,1.01-.06,1.99-.15,2.94"/><path class="cls-1" d="m25.53,8.82c-1.89,2.15-3.75,4.31-5.6,6.47-1.04,1.21-2.04,2.42-3.07,3.64-.17.21-.34.54-.6.51-.29-.03-.27-.41-.35-.65-1.79-5.48-3.59-10.96-5.39-16.43-.23-.72-.46-1.44-.74-2.35,1.16.6,2.18,1.11,3.18,1.64,1.89,1.01,3.77,2.04,5.66,3.05,2.26,1.21,4.53,2.41,6.81,3.6.28.15.36.24.11.54"/>
                <br></svg>
                </div>
                <div class="col-5 text-center px-0"><p>Clarity & Transparency</p></div>
              </div>
            </div>
            <div class="col">
              <p class="display-1 fw-bolder edgility-red text-center"> {{ pillars_average_list1[1] }} </p>
              <div class="row justify-content-center">
                <div class="col-1 text-center px-0">
                  <svg class="icon icon-m" fill="#9F1D51"  viewBox="0 0 25 50" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path class="cls-1" d="m21.84,46.51s-.06.04-.1.06c-1.16-1.38-2.31-2.76-3.48-4.13-1.27-1.49-2.55-2.97-3.81-4.46-1.29-1.51-2.56-3.03-3.84-4.53-1.24-1.45-2.49-2.89-3.73-4.34-1.26-1.47-2.47-2.96-3.75-4.41C2.08,23.52,1.16,22.23,0,21.13v-.15c.59-1.63,1.21-3.24,1.79-4.88C3.46,11.33,5.12,6.57,6.79,1.8c.03-.1.06-.19.21-.22,4.94,14.97,9.89,29.95,14.83,44.93"/><path class="cls-1" d="m26.58,14.99c-.09.85-.1,1.71-.21,2.56-.23,1.64-.21,3.31-.34,4.96-.17,2.16-.41,4.31-.58,6.48-.06.76-.12,1.53-.15,2.29-.09,2.28-.37,4.53-.5,6.8-.11,1.99-.34,3.99-.58,6.03-.44-1.32-.89-2.64-1.34-3.96-1.83-5.49-3.66-10.98-5.51-16.46-.21-.61-.08-.96.34-1.42,1.51-1.67,2.94-3.41,4.42-5.11,1.09-1.26,2.16-2.52,3.3-3.74.41-.44.69-1.04,1.31-1.38.19,1.01-.06,1.99-.15,2.94"/><path class="cls-1" d="m25.53,8.82c-1.89,2.15-3.75,4.31-5.6,6.47-1.04,1.21-2.04,2.42-3.07,3.64-.17.21-.34.54-.6.51-.29-.03-.27-.41-.35-.65-1.79-5.48-3.59-10.96-5.39-16.43-.23-.72-.46-1.44-.74-2.35,1.16.6,2.18,1.11,3.18,1.64,1.89,1.01,3.77,2.04,5.66,3.05,2.26,1.21,4.53,2.41,6.81,3.6.28.15.36.24.11.54"/>
                <br></svg>
                </div>
                <div class="col-5 text-center px-0"><p>Fairness & Consistency</p></div>
              </div>
            </div>
            <div class="col">
              <p class="display-1 fw-bolder edgility-yellow text-center"> {{ pillars_average_list1[2] }} </p>
              <div class="row justify-content-center">
                <div class="col-1 text-center px-0">
                  <svg class="icon icon-m" fill="#E99C23"  viewBox="0 0 25 50" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path class="cls-1" d="m21.84,46.51s-.06.04-.1.06c-1.16-1.38-2.31-2.76-3.48-4.13-1.27-1.49-2.55-2.97-3.81-4.46-1.29-1.51-2.56-3.03-3.84-4.53-1.24-1.45-2.49-2.89-3.73-4.34-1.26-1.47-2.47-2.96-3.75-4.41C2.08,23.52,1.16,22.23,0,21.13v-.15c.59-1.63,1.21-3.24,1.79-4.88C3.46,11.33,5.12,6.57,6.79,1.8c.03-.1.06-.19.21-.22,4.94,14.97,9.89,29.95,14.83,44.93"/><path class="cls-1" d="m26.58,14.99c-.09.85-.1,1.71-.21,2.56-.23,1.64-.21,3.31-.34,4.96-.17,2.16-.41,4.31-.58,6.48-.06.76-.12,1.53-.15,2.29-.09,2.28-.37,4.53-.5,6.8-.11,1.99-.34,3.99-.58,6.03-.44-1.32-.89-2.64-1.34-3.96-1.83-5.49-3.66-10.98-5.51-16.46-.21-.61-.08-.96.34-1.42,1.51-1.67,2.94-3.41,4.42-5.11,1.09-1.26,2.16-2.52,3.3-3.74.41-.44.69-1.04,1.31-1.38.19,1.01-.06,1.99-.15,2.94"/><path class="cls-1" d="m25.53,8.82c-1.89,2.15-3.75,4.31-5.6,6.47-1.04,1.21-2.04,2.42-3.07,3.64-.17.21-.34.54-.6.51-.29-.03-.27-.41-.35-.65-1.79-5.48-3.59-10.96-5.39-16.43-.23-.72-.46-1.44-.74-2.35,1.16.6,2.18,1.11,3.18,1.64,1.89,1.01,3.77,2.04,5.66,3.05,2.26,1.21,4.53,2.41,6.81,3.6.28.15.36.24.11.54"/>
                <br></svg>
                </div>
                <div class="col-5 text-center px-0"><p>Inclusion & Belonging</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 shadow-md mb-4">
      <div class="card-body">
        <div class="mx-4">
          <h4 class="fw-extrabold border-bottom pb-3 ps-5 ">Averages for {{ this.$store.state.comparativeSurveyTitles.title2 }}</h4>
          <div class="row mx-4">
            <div class="col  mx-auto">
              <p class="display-1 fw-bolder edgility-blue text-center"> {{ pillars_average_list2[0] }} </p>
              <div class="row justify-content-center">
                <div class="col-1 text-center px-0">
                  <svg class="icon icon-m" fill="#1863DF"  viewBox="0 0 25 50" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path class="cls-1" d="m21.84,46.51s-.06.04-.1.06c-1.16-1.38-2.31-2.76-3.48-4.13-1.27-1.49-2.55-2.97-3.81-4.46-1.29-1.51-2.56-3.03-3.84-4.53-1.24-1.45-2.49-2.89-3.73-4.34-1.26-1.47-2.47-2.96-3.75-4.41C2.08,23.52,1.16,22.23,0,21.13v-.15c.59-1.63,1.21-3.24,1.79-4.88C3.46,11.33,5.12,6.57,6.79,1.8c.03-.1.06-.19.21-.22,4.94,14.97,9.89,29.95,14.83,44.93"/><path class="cls-1" d="m26.58,14.99c-.09.85-.1,1.71-.21,2.56-.23,1.64-.21,3.31-.34,4.96-.17,2.16-.41,4.31-.58,6.48-.06.76-.12,1.53-.15,2.29-.09,2.28-.37,4.53-.5,6.8-.11,1.99-.34,3.99-.58,6.03-.44-1.32-.89-2.64-1.34-3.96-1.83-5.49-3.66-10.98-5.51-16.46-.21-.61-.08-.96.34-1.42,1.51-1.67,2.94-3.41,4.42-5.11,1.09-1.26,2.16-2.52,3.3-3.74.41-.44.69-1.04,1.31-1.38.19,1.01-.06,1.99-.15,2.94"/><path class="cls-1" d="m25.53,8.82c-1.89,2.15-3.75,4.31-5.6,6.47-1.04,1.21-2.04,2.42-3.07,3.64-.17.21-.34.54-.6.51-.29-.03-.27-.41-.35-.65-1.79-5.48-3.59-10.96-5.39-16.43-.23-.72-.46-1.44-.74-2.35,1.16.6,2.18,1.11,3.18,1.64,1.89,1.01,3.77,2.04,5.66,3.05,2.26,1.21,4.53,2.41,6.81,3.6.28.15.36.24.11.54"/>
                <br></svg>
                </div>
                <div class="col-5 text-center px-0"><p>Clarity & Transparency</p></div>
              </div>
            </div>
            <div class="col">
              <p class="display-1 fw-bolder edgility-red text-center"> {{ pillars_average_list2[1] }} </p>
              <div class="row justify-content-center">
                <div class="col-1 text-center px-0">
                  <svg class="icon icon-m" fill="#9F1D51"  viewBox="0 0 25 50" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path class="cls-1" d="m21.84,46.51s-.06.04-.1.06c-1.16-1.38-2.31-2.76-3.48-4.13-1.27-1.49-2.55-2.97-3.81-4.46-1.29-1.51-2.56-3.03-3.84-4.53-1.24-1.45-2.49-2.89-3.73-4.34-1.26-1.47-2.47-2.96-3.75-4.41C2.08,23.52,1.16,22.23,0,21.13v-.15c.59-1.63,1.21-3.24,1.79-4.88C3.46,11.33,5.12,6.57,6.79,1.8c.03-.1.06-.19.21-.22,4.94,14.97,9.89,29.95,14.83,44.93"/><path class="cls-1" d="m26.58,14.99c-.09.85-.1,1.71-.21,2.56-.23,1.64-.21,3.31-.34,4.96-.17,2.16-.41,4.31-.58,6.48-.06.76-.12,1.53-.15,2.29-.09,2.28-.37,4.53-.5,6.8-.11,1.99-.34,3.99-.58,6.03-.44-1.32-.89-2.64-1.34-3.96-1.83-5.49-3.66-10.98-5.51-16.46-.21-.61-.08-.96.34-1.42,1.51-1.67,2.94-3.41,4.42-5.11,1.09-1.26,2.16-2.52,3.3-3.74.41-.44.69-1.04,1.31-1.38.19,1.01-.06,1.99-.15,2.94"/><path class="cls-1" d="m25.53,8.82c-1.89,2.15-3.75,4.31-5.6,6.47-1.04,1.21-2.04,2.42-3.07,3.64-.17.21-.34.54-.6.51-.29-.03-.27-.41-.35-.65-1.79-5.48-3.59-10.96-5.39-16.43-.23-.72-.46-1.44-.74-2.35,1.16.6,2.18,1.11,3.18,1.64,1.89,1.01,3.77,2.04,5.66,3.05,2.26,1.21,4.53,2.41,6.81,3.6.28.15.36.24.11.54"/>
                <br></svg>
                </div>
                <div class="col-5 text-center px-0"><p>Fairness & Consistency</p></div>
              </div>
            </div>
            <div class="col">
              <p class="display-1 fw-bolder edgility-yellow text-center"> {{ pillars_average_list2[2] }} </p>
              <div class="row justify-content-center">
                <div class="col-1 text-center px-0">
                  <svg class="icon icon-m" fill="#E99C23"  viewBox="0 0 25 50" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path class="cls-1" d="m21.84,46.51s-.06.04-.1.06c-1.16-1.38-2.31-2.76-3.48-4.13-1.27-1.49-2.55-2.97-3.81-4.46-1.29-1.51-2.56-3.03-3.84-4.53-1.24-1.45-2.49-2.89-3.73-4.34-1.26-1.47-2.47-2.96-3.75-4.41C2.08,23.52,1.16,22.23,0,21.13v-.15c.59-1.63,1.21-3.24,1.79-4.88C3.46,11.33,5.12,6.57,6.79,1.8c.03-.1.06-.19.21-.22,4.94,14.97,9.89,29.95,14.83,44.93"/><path class="cls-1" d="m26.58,14.99c-.09.85-.1,1.71-.21,2.56-.23,1.64-.21,3.31-.34,4.96-.17,2.16-.41,4.31-.58,6.48-.06.76-.12,1.53-.15,2.29-.09,2.28-.37,4.53-.5,6.8-.11,1.99-.34,3.99-.58,6.03-.44-1.32-.89-2.64-1.34-3.96-1.83-5.49-3.66-10.98-5.51-16.46-.21-.61-.08-.96.34-1.42,1.51-1.67,2.94-3.41,4.42-5.11,1.09-1.26,2.16-2.52,3.3-3.74.41-.44.69-1.04,1.31-1.38.19,1.01-.06,1.99-.15,2.94"/><path class="cls-1" d="m25.53,8.82c-1.89,2.15-3.75,4.31-5.6,6.47-1.04,1.21-2.04,2.42-3.07,3.64-.17.21-.34.54-.6.51-.29-.03-.27-.41-.35-.65-1.79-5.48-3.59-10.96-5.39-16.43-.23-.72-.46-1.44-.74-2.35,1.16.6,2.18,1.11,3.18,1.64,1.89,1.01,3.77,2.04,5.66,3.05,2.26,1.21,4.53,2.41,6.81,3.6.28.15.36.24.11.54"/>
                <br></svg>
                </div>
                <div class="col-5 text-center px-0"><p>Inclusion & Belonging</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END White div with shadow-md begins -->
    
    <div class="card border-0 shadow-md mb-6">
      <div class="card-body">
        <div class="mx-4">
          <h4 class="fw-extrabold border-bottom pb-3 ps-5 ">How You Compare</h4>
        </div>
        <br>
        <div class="text-center mx-4">
          <center> <canvas id="comparisonChart" style="max-width: 1000px; max-height: 700px;"> </canvas> </center>
        </div>
        <div class="spacer25"></div>
            <div class="new-row" style="flex-wrap: wrap;">
                <p class="legend-sq p-margin-clear-30 blue1-bg pe-4">{{ this.$store.state.comparativeSurveyTitles.title1 }}</p>
                <p class="legend-sq p-margin-clear-30 blue3-bg pe-4">{{ this.$store.state.comparativeSurveyTitles.title2 }}</p>
                <p class="legend-sq p-margin-clear-30 blue2-bg pe-4">All Other Orgs</p>
            </div>
            <div class="new-row" style="flex-wrap: wrap;">
                <p class="legend-sq p-margin-clear-30 red1-bg pe-4">{{ this.$store.state.comparativeSurveyTitles.title1 }}</p>
                <p class="legend-sq p-margin-clear-30 red3-bg pe-4">{{ this.$store.state.comparativeSurveyTitles.title2 }}</p>
                <p class="legend-sq p-margin-clear-30 red2-bg pe-4">All Other Orgs</p>
            </div>
            <div class="new-row" style="flex-wrap: wrap;">
                <p class="legend-sq p-margin-clear-30 yellow1-bg pe-4">{{ this.$store.state.comparativeSurveyTitles.title1 }}</p>
                <p class="legend-sq p-margin-clear-30 yellow3-bg pe-4">{{ this.$store.state.comparativeSurveyTitles.title2 }}</p>
                <p class="legend-sq p-margin-clear-30 yellow2-bg pe-4">All Other Orgs</p>
            </div>
      </div>
    </div>
    

    <!-- Survey 1 -->
    <div class="card border-0 shadow-md mb-6">
      <div class="card-body">
        <p class="fw-bolder fs-2 m-3" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title1 }}
        </p>
        <div class="mx-8">
            <div class="row edgility-offwt-bg p-4 m-4 align-items-center">
              <div class="col-3 mx-auto text-center">
                <h3>Clarity & Transparency</h3>
                <p class="display-edg fw-extrabold edgility-blue" style="font-size: 5em"> {{ pillars_average_and_description1.averages.clarity }} </p>
              </div>
              <div class="col-7 mx-auto text-left">
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-blue">What does this say about {{ survey_data1.organization }}?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    {{ pillars_average_and_description1.descriptions.clarity.description }}
                  </p>
                </div>
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-blue">How should {{ survey_data1.organization }} move forward?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    <span class="fw-extrabold">
                      {{ pillars_average_and_description1.descriptions.clarity.recommendation_title }}
                    </span>
                    {{ pillars_average_and_description1.descriptions.clarity.recommendation }}
                  </p>
                </div>
              </div>
            </div>
    
            <div class="row edgility-offwt-bg p-4 m-4 align-items-center">
              <div class="col-3 mx-auto text-center">
                <h3>Fairness & Consistency</h3>
                <p class="display-edg fw-extrabold edgility-red" style="font-size: 5em"> {{ pillars_average_and_description1.averages.fairness }} </p>
              </div>
              <div class="col-7 mx-auto text-left">
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-red">What does this say about {{ survey_data1.organization }}?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    {{ pillars_average_and_description1.descriptions.fairness.description }}
                  </p>
                </div>
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-red">How should {{ survey_data1.organization }} move forward?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    <span class="fw-extrabold">
                      {{ pillars_average_and_description1.descriptions.fairness.recommendation_title }}
                    </span>
                    {{ pillars_average_and_description1.descriptions.fairness.recommendation }}
                  </p>
                </div>
              </div>
            </div>
    
            <div class="row edgility-offwt-bg p-4 m-4 align-items-center">
              <div class="col-3 mx-auto text-center">
                <h3>Inclusion & Belonging</h3>
                <p class="display-edg fw-extrabold edgility-yellow" style="font-size: 5em"> {{ pillars_average_and_description1.averages.inclusion }} </p>
              </div>
              <div class="col-7 mx-auto text-left">
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-yellow">What does this say about {{ survey_data1.organization }}?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    {{ pillars_average_and_description1.descriptions.inclusion.description }}
                  </p>
                </div>
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-yellow">How should {{ survey_data1.organization }} move forward?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    <span class="fw-extrabold">
                      {{ pillars_average_and_description1.descriptions.inclusion.recommendation_title }}
                    </span>
                    {{ pillars_average_and_description1.descriptions.inclusion.recommendation }}
                  </p>
                </div>
              </div>
            </div>
        </div>
        <br>
      </div>
    </div>

    <!-- Survey 2 -->
    <div class="card border-0 shadow-md mb-6">
      <div class="card-body">
        <p class="fw-bolder fs-2 m-3" style="text-align: center;">
            {{ this.$store.state.comparativeSurveyTitles.title2 }}
        </p>
        <div class="mx-8">
            <div class="row edgility-offwt-bg p-4 m-4 align-items-center">
              <div class="col-3 mx-auto text-center">
                <h3>Clarity & Transparency</h3>
                <p class="display-edg fw-extrabold edgility-blue" style="font-size: 5em"> {{ pillars_average_and_description2.averages.clarity }} </p>
              </div>
              <div class="col-7 mx-auto text-left">
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-blue">What does this say about {{ survey_data2.organization }}?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    {{ pillars_average_and_description2.descriptions.clarity.description }}
                  </p>
                </div>
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-blue">How should {{ survey_data2.organization }} move forward?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    <span class="fw-extrabold">
                      {{ pillars_average_and_description2.descriptions.clarity.recommendation_title }}
                    </span>
                    {{ pillars_average_and_description2.descriptions.clarity.recommendation }}
                  </p>
                </div>
              </div>
            </div>
    
            <div class="row edgility-offwt-bg p-4 m-4 align-items-center">
              <div class="col-3 mx-auto text-center">
                <h3>Fairness & Consistency</h3>
                <p class="display-edg fw-extrabold edgility-red" style="font-size: 5em"> {{ pillars_average_and_description2.averages.fairness }} </p>
              </div>
              <div class="col-7 mx-auto text-left">
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-red">What does this say about {{ survey_data2.organization }}?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    {{ pillars_average_and_description2.descriptions.fairness.description }}
                  </p>
                </div>
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-red">How should {{ survey_data2.organization }} move forward?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    <span class="fw-extrabold">
                      {{ pillars_average_and_description2.descriptions.fairness.recommendation_title }}
                    </span>
                    {{ pillars_average_and_description2.descriptions.fairness.recommendation }}
                  </p>
                </div>
              </div>
            </div>
    
            <div class="row edgility-offwt-bg p-4 m-4 align-items-center">
              <div class="col-3 mx-auto text-center">
                <h3>Inclusion & Belonging</h3>
                <p class="display-edg fw-extrabold edgility-yellow" style="font-size: 5em"> {{ pillars_average_and_description2.averages.inclusion }} </p>
              </div>
              <div class="col-7 mx-auto text-left">
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-yellow">What does this say about {{ survey_data2.organization }}?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    {{ pillars_average_and_description2.descriptions.inclusion.description }}
                  </p>
                </div>
    
                <div class="row">
                  <p class="fs-4 fw-extrabold edgility-yellow">How should {{ survey_data2.organization }} move forward?</p>
                  <p class="fs-5 edgility-gray lh-base edgility-black">
                    <span class="fw-extrabold">
                      {{ pillars_average_and_description2.descriptions.inclusion.recommendation_title }}
                    </span>
                    {{ pillars_average_and_description2.descriptions.inclusion.recommendation }}
                  </p>
                </div>
              </div>
            </div>
        </div>
        <br>
      </div>
    </div>
    
    </template>
    
    <script>
        import { comparisonConfigCreator } from '@/assets/js/pillarsChart'
        export default{
          props: ['section1', 'section2'],
          name: "PillarsEquity",
          data(){
            return{
              pillars_average_and_description1: this.section1.pillars_average_and_description,
              pillars_average_and_description2: this.section2.pillars_average_and_description,
              survey_data1: this.$store.state.surveyData1,
              survey_data2: this.$store.state.surveyData2,
              pillars_average_list1: this.section1.pillars_average_list,
              pillars_average_list2: this.section2.pillars_average_list
            }
          },
    
          mounted(){
            console.log(this.$store.state.surveyData1)
            console.log(this.$store.state.surveyData2)
              comparisonConfigCreator({data: this.pillars_average_list1}, 'comparisonChart', this.pillars_average_list2)
          }
        }
    </script>
    
    