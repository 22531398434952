import { labelHoverAbove, labelHoverBelow, floatingTest, fixLabelByTrimming, fixLabelByTrimmingAndNewLines } from "./common";

export const doughnutConfigCreator = (obj) => {

    const labelSize = 14;
    const titleSize = 25;

    // obj.labels = obj.labels.map((label) => {
    //     if (label.toUpperCase() === "YES") {
    //         return "Manager";
    //     } else if (label.toUpperCase() === "NO") {
    //         return "Non-Manager";
    //     } if (label.toUpperCase() === "N_LESS_THAN_5") {
    //         return "N<5";
    //     } else return label;
    // });

    const data = {
        labels: obj.labels,
        datasets: [
            {
                label: obj.title,
                data: obj.data,
                // data: [16,84],
                backgroundColor: [
                    "#1863df",
                    "#a01850",
                    "#ea9b11",
                    "#fff8ef",
                    "#ffeacf",
                    "#ea4b4b",
                    "#b8e1e9",
                    "#ffcfcf",
                    "#1882a0",
                    "#A1D2CE",
                    "#48A9A6",
                    "#a25a77",
                    "#999999",
                    "#575A4B",
                ],
            },
        ],
    };

    const options = {
        family: "Montserrat",
        font: {
            family: "Montserrat",
        },
        animation: false,
        responsive: true,
        radius: "80%",
        cutout: "60%",
        spacing: 0,
        borderWidth: 0,
        maintainAspectRatio: true,
        aspectRatio: 1,

        plugins: {
            datalabels: {
                color: "black", // text color
                display: "true", // the label is hidden if it overlap with another label
                padding: "0px",
                font: {
                    family: "Montserrat",
                    style: 'normal',
                    size: labelSize
                },
                formatter: function (value, context) {
                    let label = context.chart.data.labels[context.dataIndex];

                    if (label?.includes("n_less_than_5")) {
                        return "All Groups\n N<5";
                    }
                    if (label?.includes("Prefer not to self-identify")) {
                        return "Prefer\n not\n to\n self-identify";
                    }
                    if (label?.includes("All Groups N<5")) {
                        return "All Groups\n N<5";
                    }
                    label = fixLabelByTrimmingAndNewLines(label);
                    return label;
                },
                anchor: obj.anchor,
                // align: "end",
                // offset: -10,

                // clamp: true,
                // clip: true,

                labels: {
                    title: {
                        font: {
                            family: "Montserrat",
                            size: labelSize,
                            style: 'normal',
                        },
                    },
                },
            },

            title: {
                display: true,
                text: obj.title,
                color: "black",
                font: {
                    family: "Montserrat",
                    style: 'normal',
                    weight: '400',
                    size: titleSize,
                },
            },
            legend: {
                display: false,
            },
        },
    };

    const config = {
        type: "doughnut",
        data,
        options,
    };

    return config;
};
